import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Color from "color";

import {
  Panel,
  TextField,
  Container,
  Button,
  Text,
  IconButton,
  Icon,
  tokens,
} from "squid-ui-kit";
import configApi from "../../config/SquidAuth";

const AttachUserModal = (props) => {
  // User data
  const petrobrasKeyRef = useRef();
  const [petrobrasKey, setPetrobrasKey] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userInPortal, setUserInPortal] = useState(false);
  // Window
  const [showSavingLog, setShowSavingLog] = useState(false);
  // Errors and logs
  const [errorPetrobrasKey, setErrorPetrobrasKey] = useState(undefined);
  const [errorName, setErrorName] = useState(undefined);
  const [errorEmail, setErrorEmail] = useState(undefined);
  const [logSaving, setLogSaving] = useState(undefined);
  const [lastSaveSucceeded, setLastSaveSucceeded] = useState(undefined);

  const resetErrorsAndLogs = () => {
    setErrorName(undefined);
    setErrorEmail(undefined);
    setErrorPetrobrasKey(undefined);
    setLogSaving(undefined);
    setLastSaveSucceeded(undefined);
    setShowSavingLog(false);
  };

  const resetUserData = () => {
    setName("");
    setEmail("");
    setPetrobrasKey("");
    setUserInPortal(false);
  };

  const onKeyChange = (e) => setPetrobrasKey(e.value);
  const onUserChange = (e) => setName(e.value);
  const onEmailChange = (e) => setEmail(e.value);

  const onSave = async (e) => {
    // User info
    const userKey = petrobrasKey.toUpperCase();

    const success = () => {
      setShowSavingLog(true);
      setLogSaving(
        <Container
          bodyType="neutral"
          direction="vertical"
          horizontalAlign="center"
        >
          <Text>USUÁRIO ADICIONADO COM SUCESSO!</Text>
          <Text>NOME: {name}</Text>
          <Text>CHAVE: {petrobrasKey.toUpperCase()}</Text>
        </Container>
      );
      setLastSaveSucceeded(true);
      resetUserData();
    };

    const fail = (err) => {
      let logSaving;
      logSaving = <Text>{err.response.data.message.toUpperCase()}</Text>;
      setShowSavingLog(true);
      setLogSaving(logSaving);
      setLastSaveSucceeded(false);
      resetUserData();
    };

    if (userInPortal) {
      props.onSave(userKey, success, fail);
    }
  };

  const findUser = useCallback((userKey) => {
    const resetOnError = () => {
      setUserInPortal(false);
      setName("");
      setEmail("");
      setShowSavingLog(true);
      setLastSaveSucceeded(false);
    };
    const resetOnPrompt = () => {
      setName("");
      setEmail("");
      setUserInPortal(false);
    };
    const userFound = (data) => {
      if (data.portalUserData) {
        setUserInPortal(true);
        setShowSavingLog(false);
        setName(data.portalUserData.name);
        setEmail(data.portalUserData.email);
      } else {
        resetOnError();
        if (data.caUserData) {
          setName(data.caUserData.name);
          setEmail(data.caUserData.email);
          setLogSaving(
            <Text>USUÁRIO ({userKey.toUpperCase()}) NÃO ESTÁ NO PORTAL.</Text>
          );
        } else {
          setLogSaving(
            <Text>
              NENHUM USUÁRIO CORRESPONDE À CHAVE {userKey.toUpperCase()}.
            </Text>
          );
        }
      }
    };
    const errorCb = (data) => {
      resetOnError();
      setLogSaving(<Text>{data.message.toUpperCase()}</Text>);
    };
    if (userKey && userKey !== "") {
      if (userKey.length < 4) {
        resetOnPrompt();
        resetErrorsAndLogs();
      } else {
        configApi.findUserInfoByPetrobrasKey(
          userKey.toUpperCase(),
          userFound,
          errorCb
        );
      }
    } else {
      resetOnPrompt();
    }
  }, []);

  const onKeySearch = (e) => {
    findUser(petrobrasKey);
  };

  useEffect(() => {
    findUser(petrobrasKey);
  }, [petrobrasKey, userInPortal, findUser]);

  const footer = (
    <Container
      width="auto"
      bodyType="neutral"
      padding="0px"
      direction="horizontal"
      horizontalAlign="right"
      verticalAlign="center"
    >
      <Button
        label={"ADICIONAR"}
        type="cta"
        size="small"
        disabled={!userInPortal}
        onClick={(e) => {
          onSave(e);
          petrobrasKeyRef.current.focus();
        }}
      />
    </Container>
  );

  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        backgroundColor: Color(tokens.ColorBlack).alpha(0.3),
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        zIndex: 20,
      }}
    >
      <Panel
        label={props.title}
        direction="vertical"
        verticalAlign="top"
        horizontalAlign="left"
        bodyType="primary"
        width="500px"
        height="100%"
        footer={footer}
        closeButton={true}
        padding="10px"
        space="10px"
        onClose={props.onClose}
      >
        <TextField
          ref={petrobrasKeyRef}
          name="portal-settings-attach-modal"
          width="100%"
          placeholder="Login (Chave Petrobras)"
          value={petrobrasKey}
          error={errorPetrobrasKey}
          searchButton={true}
          readOnly={props.mode === "create" ? false : true}
          onChange={onKeyChange}
          onSearch={onKeySearch}
          onEnter={onKeySearch}
        />
        <TextField
          width="100%"
          placeholder="Nome"
          value={name}
          error={errorName}
          readOnly={props.mode === "create" ? true : false}
          onChange={onUserChange}
          disabled={props.mode === "create" ? true : false}
        />
        <TextField
          width="100%"
          placeholder="Email"
          readOnly={props.mode === "create" ? true : false}
          value={email}
          error={errorEmail}
          onChange={onEmailChange}
          inputType="email"
          disabled={props.mode === "create" ? true : false}
        />

        {showSavingLog && (
          <Container
            bodyType={lastSaveSucceeded ? "success" : "danger"}
            width="100%"
            direction="horizontal"
            padding="5px"
            space="10px"
            horizontalAlign="center"
            verticalAlign="center"
          >
            <Container
              horizontalAlign="center"
              verticalAlign="center"
              width="40px"
              direction="vertical"
              bodyType="neutral"
            >
              <Icon
                icon={lastSaveSucceeded ? "checkmark" : "alert"}
                size="large"
              />
            </Container>
            <Container
              horizontalAlign="center"
              verticalAlign="center"
              direction="vertical"
              flex="1"
              bodyType="neutral"
            >
              {logSaving}
            </Container>
            <Container
              horizontalAlign="center"
              verticalAlign="center"
              direction="vertical"
              width="40px"
              bodyType="neutral"
            >
              <IconButton
                icon="close"
                size="large"
                onClick={() => {
                  setShowSavingLog(false);
                }}
              />
            </Container>
          </Container>
        )}
      </Panel>
    </div>
  );
};

AttachUserModal.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.oneOf(["create", "edit"]),
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

AttachUserModal.defaultProps = {
  title: "Adicionar novo Usuário",
  mode: "create",
};

export default AttachUserModal;
