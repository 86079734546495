import React from "react";
import PropTypes from "prop-types";

import { global, Alert } from "squid-ui-kit";

import ApplicationPresentation from "./Application.presentation";
import Home from "../Home";
import Config from "../Config";
import AppFrame from "../AppFrame";
import CoreBackendApi from "../../config/CoreBackendApi";
import LocalApi from "../../config/LocalApi";
import { UnauthorizedAlert } from "../../components";
import squidApi from "../../config/SquidAuth";

class ApplicationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      apps: [],
      basicDataApps: undefined,
      tabs: [],
      tab: "",
      frames: null,
      showHelp: false,
      children: [],
      closingAlert: { show: false, message: "", func: () => {} },
      authorizationModal: { show: false, applicationId: "",appManagers: [], appName: "" },
      tabUpdateAlert: { show: false, newTab: {} },
    };

    this.onTabChange = (index) => {
      if (this.state.tab !== index) {
        let children = [];
        let frames = this.state.frames;
        Object.keys(this.state.tabs).forEach((i) => {
          if (this.state.tabs[i].index === index) {
            if (!frames[index]) {
              frames[index] = this.getFrame(this.state.tabs[i]);
            }
          }
        });
        Object.keys(this.state.tabs).forEach((i) => {
          children.push(
            this.getFrameWrapper(
              frames[this.state.tabs[i].index],
              this.state.tabs[i].index,
              this.state.tabs[i].index === index ? "block" : "none"
            )
          );
        });
        this.setState({
          tab: index,
          children: children,
        });
      }
    };

    this.onTabClose = (index, confirm = true) => {
      let tabs = this.state.tabs;
      let label;
      let toRemove;
      Object.keys(tabs).forEach((i) => {
        if (tabs[i].index === index) {
          toRemove = i;
          label = tabs[i].label;
        }
      });

      const closeFunc = () => {
        let children = [];
        let tab = this.state.tab;
        if (toRemove) {
          tabs.splice(toRemove, 1);
          tab = "home";
          Object.keys(tabs).forEach((i) => {
            children.push(
              this.getFrameWrapper(
                this.state.frames[tabs[i].index],
                tabs[i].index,
                tabs[i].index === "home" ? "block" : "none"
              )
            );
          });
        }

        this.setState({
          tabs: tabs,
          tab: tab,
          children: children,
        });
      };

      if (confirm) {
        this.setState({
          closingAlert: {
            show: true,
            message: `Quer realmente fechar a aplicação ${label}?`,
            func: () => closeFunc(),
          },
        });
      } else {
        closeFunc();
      }
    };

    this.onHelp = () => {
      this.setState({
        showHelp: true,
      });
    };
    this.onCloseHelp = () => {
      this.setState({
        showHelp: false,
      });
    };
    this.onConfig = () => {
      this.onOpenApp("config");
    };

    this.onLogout = () => {
      this.setState({
        closingAlert: {
          show: true,
          message: "Quer realmente sair?",
          func: this.props.onLogout,
        },
      });
    };

    this.isAdmin = () => {
      const user = LocalApi.getUser();
      if (!user || !user.groups) {
        return false;
      }
      return user.groups.findIndex((group) => group.name === "Admin") !== -1;
    };

    this.onUpdateApps = () => {
      // this.updateApps();
      window.location.reload();
    };

    this.handleAuthorizationModal = (isOpen, applicationId, appManagers, appName) => {
      this.setState({
        authorizationModal: {
          show: isOpen,
          applicationId: applicationId,
          appManagers: appManagers,
          appName: appName,
        },
      });
    };
  
  }

  componentDidMount() {
    this.updateApps();
  }

  updateApps() {
    if (
      LocalApi.getToken() === null ||
      LocalApi.getToken() === undefined ||
      LocalApi.getToken() === ""
    ) {
      return;
    }
    CoreBackendApi.validateToken(LocalApi.getToken())
      .then((valid) => {
        if (!valid) {
          this.props.onExpiration();
          return;
        }
        return Promise.all([
          CoreBackendApi.listApps(),
          CoreBackendApi.listBasicData(),
        ]);
      })
      .then(([appsSquid, basicDataApps]) => {
        if (
          (window.location.origin.indexOf("localhost") > -1 &&
            this.props.localApps) ||
          (window.location.origin.indexOf("-dev.") > -1 &&
            this.props.localApps) ||
          (window.location.origin.indexOf("dev.") > -1 && this.props.localApps)
        ) {
          Object.keys(this.props.localApps).forEach((index) => {
            let novo = true;
            Object.keys(appsSquid).forEach((i) => {
              if (this.props.localApps[index].name === appsSquid[i].label) {
                novo = false;
                appsSquid[i].src = this.props.localApps[index].url;
                if (this.props.localApps[index].params) {
                  appsSquid[i].params = this.props.localApps[index].params;
                }
              }
            });
            if (novo) {
              appsSquid.push({
                compact: false,
                icon: "/application-icon.png",
                index: global.guid(),
                label: this.props.localApps[index].name,
                launchMode: {
                  code: "INSIDE_PORTAL",
                  __typename: "LaunchMode",
                },
                params: this.props.localApps[index].params,
                src: this.props.localApps[index].url,
              });
            }
          });
        }
        Object.keys(appsSquid).forEach((i) => {
          if (!appsSquid[i].icon || appsSquid[i].icon === "") {
            appsSquid[i].icon = "/application-icon.png";
          }
        });

        appsSquid[0].inOrder = true;
        appsSquid[1].inOrder = true;
        let orderedApps = [appsSquid[0], appsSquid[1]];
        let appOrder = [
          "Metocean",
          "Busca em Estoque",
          "Flexboard",
          "FRAES",
          "WebFlex",
          "EndFlex",
          "speedAR",
          "twistAR",
          "ConfFlex",
          "Permeação",
          "FadigaSN",
          "Flexcor",
          "Suporte de Risers",
          "AnflexOpt",
          "IntegriDutos",
          "IntegriSpan",
          "PipeAPI",
          "Petrocorr",
          "RelatBarWeb",
          "PREDIS",
        ];
        Object.keys(appOrder).forEach((index) => {
          Object.keys(appsSquid).forEach((i) => {
            if (
              appOrder[index].toLowerCase() === appsSquid[i].label.toLowerCase()
            ) {
              appsSquid[i].inOrder = true;
              orderedApps.push(appsSquid[i]);
            }
          });
        });
        Object.keys(appsSquid).forEach((index) => {
          if (!appsSquid[index].inOrder) {
            orderedApps.push(appsSquid[index]);
          }
        });

        let frames;
        if (orderedApps && orderedApps.length > 0) {
          this.setState(
            {
              apps: orderedApps,
              basicDataApps:
                basicDataApps.length > 0 ? basicDataApps : undefined,
            },
            () => {
              frames = {};
              frames[this.state.apps[0].index] = this.getFrame(
                this.state.apps[0]
              );

              this.setState({
                tabs: [this.state.apps[0]],
                tab: this.state.apps[0].index,
                frames: frames,
                children: this.getFrameWrapper(
                  frames[this.state.apps[0].index],
                  this.state.apps[0].index,
                  "block"
                ),
              });
            }
          );
        }
      })
      .catch((error) => {
        console.log("+++++++++++ ERROR CALLBACK +++++++++++", error);
        this.props.onExpiration();
      });
  }

  onOpenIcon(index, appState) {
    let tabs = this.state.tabs;
    let novo = true;
    let externalUrl = "";
    Object.keys(tabs).forEach((i) => {
      if (tabs[i].index === index) {
        novo = false;
      }
    });

    if (novo === true) {
      Object.keys(appState).forEach((i) => {
        if (appState[i].index === index) {
          if (
            appState[i].launchMode &&
            appState[i].launchMode.code &&
            appState[i].launchMode.code === "OUTSIDE_PORTAL"
          ) {
            externalUrl = appState[i].src;
          } else {
            tabs.push(appState[i]);
          }
        }
      });
    }

    if (externalUrl !== "") {
      window.open(externalUrl, "_blank");
    } else {
      CoreBackendApi.validateToken(LocalApi.getToken())
        .then((valid) => {
          if (!valid) {
            this.props.onExpiration();
            return;
          }
          this.setState({ tabs: tabs }, () => {
            this.onTabChange(index);
          });
        })
        .catch((error) => {
          console.log("+++++++++++ ERROR CALLBACK +++++++++++", error);
          this.props.onExpiration();
        });
    }
  }

  onOpenApp(index) {
    this.onOpenIcon(index, this.state.apps);
  }

  onOpenBasicData(index) {
    this.onOpenIcon(index, this.state.basicDataApps);
  }

  onTabUpdate(newTab) {
    const indexUuid = newTab.index;
    this.setState(
      (prevState) => {
        let isNew = true;
        if (prevState.tabs.find((tab) => tab.index === indexUuid)) {
          isNew = false;
        }
        let newTabs = [];
        if (isNew) {
          newTabs = [...prevState.tabs, newTab];
        } else {
          newTabs = prevState.tabs.map((tab) => {
            if (tab.index === indexUuid) {
              return newTab;
            }
            return tab;
          });
        }

        let frames = prevState.frames;
        frames[indexUuid] = this.getFrame(newTab);
        return {
          tabs: newTabs,
          frames: frames,
        };
      },
      () => this.onTabChange(indexUuid)
    );
  }

  async onOpenPostmate(newTab) {
    if (newTab?.slug) {
      await squidApi.getApplicationBySlug(newTab?.slug, (data) => {
        newTab = {
          index: data?.id,
          src: newTab.src,
          label: data?.name,
          icon: data?.icon,
          launchMode: {
            code: "INSIDE_PORTAL",
            __typename: "LaunchMode",
          },
          compact: false,
          params: "",
        };
      });
    }
    const indexUuid = newTab?.index;
    if (!indexUuid) {
      return;
    }
    let tabs = this.state.tabs;
    let isNew = true;
    let externalUrl =
      newTab?.launchMode?.code === "OUTSIDE_PORTAL" ? newTab.src : "";
    Object.keys(tabs).forEach((i) => {
      if (tabs[i].index === indexUuid) {
        isNew = false;
      }
    });

    if (externalUrl !== "") {
      window.open(externalUrl, "_blank");
    } else {
      CoreBackendApi.validateToken(LocalApi.getToken())
        .then((valid) => {
          if (!valid) {
            this.props.onExpiration();
            return;
          }
          if (!isNew) {
            this.setState({ tabUpdateAlert: { show: true, newTab: newTab } });
          } else {
            this.onTabUpdate(newTab);
          }
        })
        .catch((error) => {
          console.log("+++++++++++ ERROR CALLBACK +++++++++++", error);
          this.props.onExpiration();
        });
    }
  }

  getFrame(frame) {
    if (frame.index === "home") {
      return (
        <Home
          tokens={this.props.tokens}
          apps={this.state.apps}
          basicDataApps={this.state.basicDataApps}
          onOpenApp={this.onOpenApp.bind(this)}
          onOpenBasicData={this.onOpenBasicData.bind(this)}
          onLogout={this.onLogout}
          handleAuthorizationModal={this.handleAuthorizationModal}
        />
      );
    } else if (frame.index === "config") {
      return (
        <Config
          tokens={this.props.tokens}
          isAdmin={this.isAdmin()}
          onUpdateApps={this.onUpdateApps}
        />
      );
    }
    return (
      <AppFrame
        tokens={this.props.tokens}
        token={this.props.token}
        src={frame.src}
        srcParams={frame.params}
        openTab={this.onOpenPostmate.bind(this)}
        active={true}
        onExpiration={this.props.onExpiration}
      />
    );
  }

  getFrameWrapper(frame, key, display) {
    return (
      <div
        key={key}
        style={{
          display: display,
          position: "absolute",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        }}
      >
        {frame}
      </div>
    );
  }

  render() {
    return (
      <ApplicationPresentation
        tokens={this.props.tokens}
        tabs={this.state.tabs}
        tab={this.state.tab}
        onTabChange={this.onTabChange}
        onTabClose={this.onTabClose}
        onHelp={this.onHelp}
        onConfig={this.onConfig}
        onLogout={this.onLogout}
        showHelp={this.state.showHelp}
        onCloseHelp={this.onCloseHelp}
        isAdmin={this.isAdmin()}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            filter:
              this.state.closingAlert.show ||
              this.state.authorizationModal.show ||
              this.state.tabUpdateAlert.show
                ? "blur(5px)"
                : "blur(0px)",
          }}
        >
          {this.state.children}
        </div>
        {this.state.closingAlert.show && (
          <Alert
            confirm={true}
            width="fit-content"
            padding="25px"
            title="ALERTA"
            cancelLabel="Cancelar"
            confirmLabel="Sim"
            hasBorder={true}
            message={this.state.closingAlert.message}
            onClose={() => {
              this.setState({ closingAlert: { show: false } });
            }}
            onConfirm={() => {
              this.state.closingAlert.func();
              this.setState({ closingAlert: { show: false } });
            }}
            onCancel={() => {
              this.setState({ closingAlert: { show: false } });
            }}
          />
        )}

        {this.state.authorizationModal.show && (
          <UnauthorizedAlert
            title="Alerta"
            applicationId={this.state.authorizationModal.applicationId}
            onCancel={() => this.handleAuthorizationModal(false, "", [], "")}
            authorizationList={this.state.authorizationModal.appManagers}
            applicationName={this.state.authorizationModal.appName}
          />
        )}

        {this.state.tabUpdateAlert.show && (
          <Alert
            confirm={true}
            width="fit-content"
            padding="25px"
            title={`Abrindo aplicação ${this.state.tabUpdateAlert.newTab.label}`}
            cancelLabel="Cancelar"
            confirmLabel="Sim"
            hasBorder={true}
            message={`Todas as alterações não salvas na aplicação ${this.state.tabUpdateAlert.newTab.label} serão descartadas. Deseja continuar?`}
            onClose={() => {
              this.setState({ tabUpdateAlert: { show: false, newTab: {} } });
            }}
            onConfirm={() => {
              this.onTabUpdate(this.state.tabUpdateAlert.newTab);
              this.setState({ tabUpdateAlert: { show: false, newTab: {} } });
            }}
            onCancel={() => {
              this.setState({ tabUpdateAlert: { show: false, newTab: {} } });
            }}
          />
        )}
      </ApplicationPresentation>
    );
  }
}

ApplicationContainer.propTypes = {
  onLogout: PropTypes.func,
  onExpiration: PropTypes.func,
};

ApplicationContainer.defaultProps = {};

export default ApplicationContainer;
