import React from 'react';
import PropTypes from 'prop-types';

import TabPresentation from "./Tab.presentation";

class TabContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <TabPresentation
                tokens={this.props.tokens}
                index={this.props.index}
                label={this.props.label}
                icon={this.props.icon}
                compact={this.props.compact}
                active={this.props.active}
                onClick={this.props.onClick}
                onClose={this.props.onClose}
            />

        );
    }
}

TabContainer.propTypes = {
    index: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.any,
    compact: PropTypes.bool,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
}

TabContainer.defaultProps = {
    index: '',
    label: 'Tab',
    icon: null,
    compact: false,
    active: false,
}

export default TabContainer;
