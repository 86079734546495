import {
  Container,
  Panel,
} from "squid-ui-kit";
import { ManagersTable, ApplicationDetails } from '../../components'

const SelectedApplication = ({
  applicationInformation,
  selectedApplication,
  onShowAddManagerModal,
  managersList,
  loadingApplicationInformation,
  onSelectedManager,
  isAdmin,
}) => {
  return (
    <>
      <ApplicationDetails applicationInformation={applicationInformation} />
      <Container
        width="100%"
        flex="1"
        bodyType="content"
        padding="5px 0px"
      >
        <Panel
        width="100%"
        flex="1"
        bodyType="neutral"
        label={`GESTORES DA APLICAÇÃO ${ applicationInformation?.name || 'SELECIONADA'} `}
        addButton={!!selectedApplication && isAdmin}
        onAdd={onShowAddManagerModal}
      >
        <Container width="100%" height="100%" bodyType="primary">
          <ManagersTable
            managersList={managersList}
            loading={loadingApplicationInformation}
            deleteCallback={onSelectedManager}
            isAdmin={isAdmin}
          />
        </Container>
      </Panel>
      </Container>
      
    </>
  );
}

export default SelectedApplication;
