import React, { useState } from 'react';
import defaultAvatar from '../../assets/default-avatar.png';

const ImageComponent = ({ src, width, height }) => {
  const [imageLoaded, setImageLoaded] = useState(true);

  const handleImageError = () => {
    setImageLoaded(false);
  };

  if(imageLoaded){
    return (
        <img
            src={src}
            alt="" 
            style={{
                width,
                height,
            }}
            onError={handleImageError} 
        />
      );
  }
  return (
        <img
            src={defaultAvatar}
            alt="" 
            style={{
                width,
                height,
            }}
            onError={handleImageError} 
        />
  );
};

export default ImageComponent;
