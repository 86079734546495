// parse date to human format
export const parseDateToHuman = (date) => {
  if (!date) return null;

  const lastEditedDate = new Date(date);
  const now = new Date();
  const timeDifference = now - lastEditedDate;
  const userTimezoneOffset = now.getTimezoneOffset();
  const timeDifferenceInUserTimezone =
    timeDifference + userTimezoneOffset * 60 * 1000;

  const daysDifference = Math.floor(
    timeDifferenceInUserTimezone / (1000 * 60 * 60 * 24)
  );

  if (daysDifference > 7) {
    const formattedDate = `${lastEditedDate.getDate()}/${
      lastEditedDate.getMonth() + 1
    }/${lastEditedDate.getFullYear()}`;
    return formattedDate;
  }

  const hoursDifference = Math.floor(
    (timeDifferenceInUserTimezone / (1000 * 60 * 60)) % 24
  );
  const minutesDifference = Math.floor(
    (timeDifferenceInUserTimezone / (1000 * 60)) % 60
  );

  if (daysDifference <= 0) {
    if (hoursDifference <= 0) {
      return minutesDifference <= 0
        ? "agora mesmo"
        : `${minutesDifference} ${
            minutesDifference > 1 ? "minutos" : "minuto"
          } atrás`;
    }
    return `${hoursDifference} ${hoursDifference > 1 ? "horas" : "hora"} atrás`;
  }

  return `${daysDifference} ${daysDifference > 1 ? "dias" : "dia"} atrás`;
};

// Convert datetime string to locale string ("2024-06-18T19:22:09.009802" -> "18/06/2024, 16:22:09")
export const localeString = (dateString) => {
  if (!dateString) return "-";
  const date = new Date(dateString + "Z");
  return date.toLocaleString("pt-BR");
};
