import {
  Container,
  Panel,
} from "squid-ui-kit";

import { ApplicationDetails } from '../../components'

const SelectedApplication = ({
  applicationInformation,
  selectedApplication,
  onShowAddModal,
  loadingApplicationInformation,
  isAdmin,
  label,
  disableAddButton,
  children,
  enableFullHeight
}) => {
  const enableAddButton = disableAddButton ? false : !!selectedApplication

  return (
    <Container
      bodyType="neutral"
      direction="vertical"
      width="100%"
      height={ enableFullHeight ? '100%' : 'auto'}
    >
      <ApplicationDetails applicationInformation={applicationInformation} />
      <Panel
        width="100%"
        minHeight="166px"
        flex='1'
        height='auto'
        bodyType="neutral"
        label={label}
        addButton={enableAddButton}
        onAdd={onShowAddModal}
        padding='5px 0px'
        >
          <Container width="100%" height='100%' bodyType="neutral">
            {children}
          </Container>
        </Panel>
    </Container>
  );
}

export default SelectedApplication;
