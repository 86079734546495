/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import PropTypes from "prop-types";
import { Container, Spacer, Panel } from "squid-ui-kit";
import Users from "../Users";
import SearchUserForm from "../Users/components/SearchUserForm";
import AccessRequests from "./components/AccessRequests";

const PortalUsersPresentation = ({
  isAdmin,
  tokens,
  showWindowFormUser,
  groups,
  users,
  searchTerm,
  setSearchTerm,
  onAddUser,
  onDeleteUser,
  onProfileChange,
  onCloseWindowFormUser,
  onUsersListUpdated,
}) => {
  let windowFormUser;
  if (showWindowFormUser === true) {
    windowFormUser = (
      <SearchUserForm
        tokens={tokens}
        title="Adicionar usuários"
        searchTerm={searchTerm}
        onClose={onCloseWindowFormUser}
        onUsersListUpdated={onUsersListUpdated}
      />
    );
  }

  let usersComponent;
  let accessRequests;
  if (isAdmin) {
    if (users && users.length > 0) {
      usersComponent = (
        <Users
          tokens={tokens}
          groups={groups}
          users={users}
          setSearchTerm={setSearchTerm}
          onAdd={onAddUser}
          onDelete={onDeleteUser}
          onProfileChange={onProfileChange}
        />
      );
    } else {
      usersComponent = (
        <Panel
          label="Usuários"
          width="100%"
          height="100%"
          flex="1"
          direction="vertical"
          verticalAlign="top"
          bodyType="primary"
        />
      );
    }
    accessRequests = <AccessRequests onUpdate={onUsersListUpdated} />;
  }

  return (
    <Container
      width="100%"
      height="100%"
      direction="horizontal"
      verticalAlign="top"
      bodyType="primary"
      margin="0px"
      padding="10px"
    >
      {usersComponent}
      <Spacer direction="horizontal" size="10px" />
      <Container bodyType="neutral" height="100%">
        {accessRequests}
      </Container>
      {windowFormUser}
    </Container>
  );
};

PortalUsersPresentation.propTypes = {
  isAdmin: PropTypes.bool,
  tokens: PropTypes.object.isRequired,
  showWindowFormUser: PropTypes.bool,
  groups: PropTypes.array,
  users: PropTypes.array,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  onProfileChange: PropTypes.func.isRequired,
  onCloseWindowFormUser: PropTypes.func.isRequired,
  onUsersListUpdated: PropTypes.func.isRequired,
};

PortalUsersPresentation.defaultProps = {
  isAdmin: false,
  showWindowFormUser: false,
  groups: [],
  users: [],
};

export default PortalUsersPresentation;
