import React from "react";
import {
  Container,
  Panel,
  Spacer,
  TextArea,
  TextField,
  ListItem,
  Dropdown,
  Divider,
  Button,
  Tooltip,
} from "squid-ui-kit";
import { ExclusionAlert } from "../../components";
import "./EmailPage.styles.css";

const EmailPagePresentation = ({
  apps,
  loadingApps,
  warningMessage,
  onAcceptWarning,
  users,
  loadingUsers,
  recipients,
  setRecipients,
  searchQuery,
  setSearchQuery,
  selectedApp,
  setSelectedApp,
  getAppRoles,
  appRoles,
  loadingAppRoles,
  selectedAppRole,
  setSelectedAppRole,
  getUsersByRole,
  getAllUsers,
  emailMessage,
  setEmailMessage,
  emailSubject,
  setEmailSubject,
  handleSendEmail,
  loadingEmail,
  showEmailModal,
  setShowEmailModal,
  resetStates,
}) => {
  const parsedUsers = users
    ?.filter(
      (user) =>
        user.petrobrasKey.toLowerCase().includes(searchQuery?.toLowerCase()) |
        user.name.toLowerCase().includes(searchQuery?.toLowerCase())
    )
    ?.filter(
      (user) =>
        !recipients.some(
          (recipient) => recipient.petrobrasKey === user.petrobrasKey
        )
    );
  return (
    <Container
      width="100%"
      height="100%"
      bodyType="default"
      direction="horizontal"
      progress={loadingEmail ? -1 : undefined}
      progressLabel="Enviando email..."
    >
      <Panel
        width="100%"
        height="100%"
        label="Comunicação por email"
        bodyType="primary"
      >
        <Container
          bodyType="neutral"
          horizontalAlign="center"
          height="100%"
          direction="horizontal"
          width="100%"
        >
          <Container
            bodyType="neutral"
            horizontalAlign="left"
            height="100%"
            width="500px"
            scroll="vertical"
            padding="16px"
          >
            <TextField
              label="Buscar usuário"
              width="100%"
              placeholder="Digite a chave ou nome do usuário..."
              searchButton
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.value);
              }}
            />
            <Spacer size="16px" direction="vertical" />
            <Container width="100%" bodyType="none" direction="horizontal">
              <Dropdown
                onChange={(e) => {
                  const appToSelect = apps?.find((app) => app.key === e.value);
                  setSelectedApp(appToSelect);
                  setSelectedAppRole(undefined);
                  getAppRoles(appToSelect);
                }}
                selectedKey={selectedApp?.key}
                placeholder="Selecione um App"
                flex="1"
                label="Do App"
                disabled={loadingApps}
              >
                {apps?.map((app) => (
                  <ListItem key={app.key} title={app.name} />
                ))}
              </Dropdown>
              <Spacer size="16px" direction="horizontal" />
              <Dropdown
                onChange={(e) => {
                  const roleToSelect = appRoles?.find(
                    (role) => role.id === e.value
                  );
                  setSelectedAppRole(roleToSelect);
                  getUsersByRole(roleToSelect);
                }}
                selectedKey={selectedAppRole?.id}
                placeholder="Selecione uma Função"
                flex="1"
                label="Com a Função"
                disabled={!selectedApp || loadingAppRoles}
              >
                {appRoles?.map((role) => (
                  <ListItem key={role.id} title={role.name} />
                ))}
              </Dropdown>
            </Container>

            <Spacer size="16px" direction="vertical" />
            <Divider />
            <Spacer size="16px" direction="vertical" />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                height: "12px",
              }}
            >
              <div
                style={{
                  color: "rgba(255, 255, 255, 0.6)",
                  fontFamily: "Roboto",
                  fontSize: "0.6875rem",
                  fontWeight: "500",
                  textAlign: "left",
                  boxSizing: "border-box",
                }}
              >
                LISTA DE USUÁRIOS
              </div>
              <Button
                type="link"
                label="Limpar filtros"
                size="small"
                onClick={() => {
                  setSearchQuery("");
                  setSelectedApp(undefined);
                  setSelectedAppRole(undefined);
                  getAllUsers();
                }}
              />
            </div>

            <Spacer size="6px" direction="vertical" />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #5D6E78",
                flex: "1",
                overflow: "auto",
              }}
            >
              {parsedUsers.length > 0 ? (
                parsedUsers?.map((user) => (
                  <ListItem
                    key={user.petrobrasKey}
                    subtitle={user.petrobrasKey}
                    title={user.name}
                    onClick={() => setRecipients([...recipients, user])}
                  />
                ))
              ) : (
                <Container
                  width="100%"
                  height="100%"
                  horizontalAlign="center"
                  verticalAlign="center"
                  bodyType="none"
                >
                  <div
                    style={{
                      color: "rgba(255, 255, 255, 0.6)",
                      fontFamily: "Roboto",
                      fontSize: "0.6875rem",
                      fontWeight: "500",
                      textAlign: "left",
                      boxSizing: "border-box",
                    }}
                  >
                    {loadingUsers
                      ? "Carregando usuários..."
                      : "Nenhum usuário correspondente às buscas"}
                  </div>
                </Container>
              )}
            </div>
            <Spacer size="16px" direction="vertical" />
            <Container
              width="100%"
              bodyType="none"
              direction="horizontal"
              horizontalAlign="right"
            >
              <Button
                type="primary"
                label="Adicionar Lista"
                onClick={() => setRecipients([...recipients, ...parsedUsers])}
              />
            </Container>
          </Container>
          <Container
            bodyType="content"
            horizontalAlign="left"
            height="100%"
            flex="1"
            scroll="vertical"
            padding="16px"
          >
            <TextField
              label="Assunto"
              invertedColors
              width="100%"
              placeholder="Digite o assunto do email aqui..."
              value={emailSubject}
              onChange={(e) => setEmailSubject(e.value)}
            />
            <Spacer size="16px" direction="vertical" />
            <div
              style={{
                color: "rgba(25, 52, 67, 0.6)",
                fontFamily: "Roboto",
                fontSize: "0.6875rem",
                fontWeight: "500",
                textAlign: "left",
                boxSizing: "border-box",
              }}
            >
              DESTINATÁRIOS
            </div>
            <Spacer size="4px" direction="vertical" />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "left",
                alignItems: "flex-start",
                maxHeight: "200px",
                minHeight: "78px",
                overflow: "auto",
                gap: "8px",
              }}
            >
              {recipients?.length > 0 ? (
                recipients?.map((user) => (
                  <Tooltip
                    title="Remover destinatário"
                    placement="top"
                    key={user.petrobrasKey}
                  >
                    <div
                      className="recipient"
                      onClick={() =>
                        setRecipients(recipients.filter((u) => u !== user))
                      }
                    >
                      {user.avatar && (
                        <div
                          style={{
                            width: "36px",
                            height: "36px",
                            borderRadius: "50%",
                            margin: "8px",
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                            }}
                            src={user.avatar}
                            alt="Avatar"
                          />
                        </div>
                      )}
                      <Container
                        height="100%"
                        direction="vertical"
                        bodyType="neutral"
                        padding="8px"
                      >
                        <div
                          style={{
                            flex: "2 1 0%",
                            color: "rgba(25, 52, 67, 0.9)",
                            fontFamily: "Roboto",
                            fontSize: "0.8125rem",
                            fontWeight: "600",
                            textAlign: "left",
                            boxSizing: "border-box",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {user.name}
                        </div>
                        <Container
                          width="100%"
                          direction="horizontal"
                          bodyType="neutral"
                          horizontalAlign="left"
                        >
                          <div
                            style={{
                              flex: "1 1 0%",
                              color: "rgba(25, 52, 67, 0.5)",
                              fontFamily: "Roboto",
                              fontSize: "0.8125rem",
                              fontWeight: "400",
                              textAlign: "right",
                              boxSizing: "border-box",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {user.petrobrasKey}
                          </div>
                        </Container>
                      </Container>
                    </div>
                  </Tooltip>
                ))
              ) : (
                <Container
                  width="100%"
                  height="100%"
                  horizontalAlign="center"
                  verticalAlign="center"
                  bodyType="none"
                >
                  <div
                    style={{
                      color: "rgba(25, 52, 67, 0.6)",
                      fontFamily: "Roboto",
                      fontSize: "0.6875rem",
                      fontWeight: "500",
                      textAlign: "left",
                      boxSizing: "border-box",
                    }}
                  >
                    Nenhum destinatário adicionado.
                  </div>
                  <div
                    style={{
                      color: "rgba(25, 52, 67, 0.6)",
                      fontFamily: "Roboto",
                      fontSize: "0.6875rem",
                      fontWeight: "500",
                      textAlign: "left",
                      boxSizing: "border-box",
                    }}
                  >
                    Para adicionar, clique nos usuários desejados na LISTA DE
                    USUÁRIOS ao lado.
                  </div>
                </Container>
              )}
            </div>
            <Spacer size="16px" direction="vertical" />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: "1",
                width: "100%",
                borderBottom: "1px solid #A2ADB3",
              }}
            >
              <TextArea
                label="Mensagem"
                invertedColors
                width="100%"
                height="100%"
                placeholder="Digite sua mensagem aqui..."
                resize="none"
                value={emailMessage}
                onChange={(e) => setEmailMessage(e.value)}
              />
            </div>

            <Spacer size="16px" direction="vertical" />
            <Container
              width="100%"
              bodyType="none"
              direction="horizontal"
              horizontalAlign="right"
            >
              <Button
                type="primary"
                label="Remover Todos"
                onClick={() => setRecipients([])}
              />
              <Spacer size="16px" direction="horizontal" />
              <Button
                type="secondary"
                label="Enviar Email"
                onClick={handleSendEmail}
              />
            </Container>
          </Container>
        </Container>
      </Panel>

      {warningMessage && (
        <ExclusionAlert
          exclusionText={warningMessage}
          title="Alerta"
          onCancel={() => onAcceptWarning()}
          onlyConfirmation
        />
      )}
      {showEmailModal && (
        <ExclusionAlert
          exclusionText="Email enviado com sucesso!"
          title="Sucesso"
          onCancel={() => setShowEmailModal(false)}
          onlyConfirmation
        />
      )}
    </Container>
  );
};

export default EmailPagePresentation;
