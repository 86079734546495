import React from "react";
import PropTypes from "prop-types";
import { Container, tokens } from "squid-ui-kit";

class Tab extends React.Component {
  render() {
    return (
      <Container
        height={this.props.height}
        width={this.props.width}
        flex={this.props.flex}
        bodyType={this.props.selected ? "neutral" : "primary"}
      >
        <div
          style={{
            flex: "1",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: tokens.SecondaryPadding,
            cursor: "pointer",
            boxSizing: "border-box",
          }}
          onClick={this.props.onClick}
        >
          {this.props.children}
        </div>
        <div
          style={{
            width: "100%",
            height: "2px",
            backgroundColor: tokens.ColorWhite,
            opacity: this.props.selected ? "0.85" : "0.10",
            position: "absolute",
            bottom: "0",
          }}
        />
      </Container>
    );
  }
}

Tab.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  flex: PropTypes.string,
};

Tab.defaultProps = {
  children: null,
  selected: false,
  height: "100%",
  width: "fit-content",
};

export default Tab;
