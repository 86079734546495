import React from "react";
import NotificationsPresentation from "./NotificationsTab.presentation";
import PropTypes from "prop-types";
import subwebCoreApi from "../../../../../config/SubwebCoreApi";
import { parseDateToHuman } from "../../../../../utils/date";
class NotificationsTabContainer extends React.Component {
  constructor(props) {
    super(props);

    // parse date to human format
    this.parseDate = (date) => {
      return parseDateToHuman(date);
    };

    this.initialPageSize = 4;

    this.state = {
      pageSizeBarValue: this.initialPageSize.toString(),
      searchBarValue: "",
      notificationToBeDeletedId: null,
      loadingNotifications: {},
      content: [],
      loading: false,
      numberOfPages: null,
      numberOfItems: null,
      filterProps: {
        pageSize: this.initialPageSize,
        page: 1,
        searchText: null,
        caseSensitive: false,
        searchMode: "SUBSTRING",
        appOwner: null,
        order: "CHRONOLOGICAL",
      },
    };

    this.getNotifications = (filters) => {
      const setLoading = (loading) => {
        this.setState({ loading: loading });
      };

      const onSuccess = (data) => {
        this.setState({
          content: data.content,
          filterProps: { ...filters, page: data.page },
          numberOfPages: data.numberOfPages,
          numberOfItems: data.numberOfItems,
        });
      };

      subwebCoreApi.filterNotifications(filters, onSuccess, setLoading);
    };

    this.handleTypeSearch = (e) => {
      this.setState({ searchBarValue: e.value });
    };

    this.handleSearch = () => {
      const searchText = this.state.searchBarValue;
      const filterProps = { ...this.state.filterProps, searchText, page: 1 };
      this.getNotifications(filterProps);
    };

    this.handleRefresh = () => {
      const filterProps = {
        ...this.state.filterProps,
        page: 1,
        searchText: null,
      };
      this.setState({ searchBarValue: "" });
      this.getNotifications(filterProps);
    };

    this.handleNextPage = () => {
      if (this.state.filterProps.page === this.state.numberOfPages) return;
      const filterProps = {
        ...this.state.filterProps,
        page: this.state.filterProps.page + 1,
      };

      this.getNotifications(filterProps);
    };

    this.handlePreviousPage = () => {
      if (this.state.filterProps.page <= 1) return;
      const filterProps = {
        ...this.state.filterProps,
        page: this.state.filterProps.page - 1,
      };
      this.getNotifications(filterProps);
    };

    this.handleFirstPage = () => {
      if (this.state.filterProps.page <= 1) return;
      const filterProps = {
        ...this.state.filterProps,
        page: 1,
      };
      this.getNotifications(filterProps);
    };

    this.handleLastPage = () => {
      const filterProps = {
        ...this.state.filterProps,
        page: this.state.numberOfPages,
      };
      this.getNotifications(filterProps);
    };

    this.updateSingleCardData = (cardData) => {
      const content = this.state.content.map((notification) => {
        if (notification.notificationId === cardData.notificationId)
          return cardData;
        return notification;
      });

      this.setState({ content: [...content] });
    };

    this.handleMarkAsRead = ({ notificationId }) => {
      const setLoading = (loading) => {
        const loadingNotifications = { ...this.state.loadingNotifications };
        if (loading) loadingNotifications[notificationId] = true;
        else delete loadingNotifications[notificationId];

        this.setState({
          loadingNotifications: loadingNotifications,
        });
      };

      const onSuccess = (data) => {
        this.props.getNotificationsCounts();
        this.updateSingleCardData(data);
      };

      subwebCoreApi.markNotificationAsRead(
        notificationId,
        onSuccess,
        setLoading
      );
    };

    this.handleMarkAsUnread = ({ notificationId }) => {
      const setLoading = (loading) => {
        const loadingNotifications = { ...this.state.loadingNotifications };
        if (loading) loadingNotifications[notificationId] = true;
        else delete loadingNotifications[notificationId];

        this.setState({
          loadingNotifications: loadingNotifications,
        });
      };

      const onSuccess = (data) => {
        this.props.getNotificationsCounts();
        this.updateSingleCardData(data);
      };

      subwebCoreApi.markNotificationAsUnread(
        notificationId,
        onSuccess,
        setLoading
      );
    };

    this.handleClickDelete = ({ notificationId }) => {
      this.setState({ notificationToBeDeletedId: notificationId });
    };

    this.handleCancelDelete = () => {
      this.setState({ notificationToBeDeletedId: null });
    };

    this.handleConfirmDelete = ({ notificationId }) => {
      const setLoading = (loading) => {
        this.setState({ loading: loading });
      };

      const onSuccess = () => {
        this.getNotifications(this.state.filterProps);
        this.setState({ notificationToBeDeletedId: null });
        this.props.getNotificationsCounts();
      };

      subwebCoreApi.deleteNotification(notificationId, onSuccess, setLoading);
    };

    this.handleTypePageSize = (e) => {
      this.setState({ pageSizeBarValue: e.value });
    };

    this.handleChangePageSize = () => {
      const pageSize =
        parseInt(this.state.pageSizeBarValue) || this.filterProps.pageSize;
      const filterProps = {
        ...this.state.filterProps,
        pageSize,
        page: 1,
      };
      this.getNotifications(filterProps);
    };
  }
  componentDidMount() {
    const initialData = this.state.filterProps;
    this.getNotifications(initialData);
  }

  render() {
    return (
      <NotificationsPresentation
        content={this.state.content}
        parseDate={this.parseDate}
        getNotifications={this.getNotifications}
        searchBarValue={this.state.searchBarValue}
        loading={this.state.loading}
        handleSearch={this.handleSearch}
        handleTypeSearch={this.handleTypeSearch}
        handleMarkAsRead={this.handleMarkAsRead}
        handleMarkAsUnread={this.handleMarkAsUnread}
        handleRefresh={this.handleRefresh}
        handleNextPage={this.handleNextPage}
        handlePreviousPage={this.handlePreviousPage}
        numberOfPages={this.state.numberOfPages}
        notificationToBeDeletedId={this.state.notificationToBeDeletedId}
        handleClickDelete={this.handleClickDelete}
        handleCancelDelete={this.handleCancelDelete}
        handleConfirmDelete={this.handleConfirmDelete}
        filterProps={this.state.filterProps}
        appSlugToName={this.props.appSlugToName}
        handleFirstPage={this.handleFirstPage}
        handleLastPage={this.handleLastPage}
        handleChangePageSize={this.handleChangePageSize}
        pageSizeBarValue={this.state.pageSizeBarValue}
        handleTypePageSize={this.handleTypePageSize}
        loadingNotifications={this.state.loadingNotifications}
        readNotificationColor={this.props.readNotificationColor}
        unreadNotificationColor={this.props.unreadNotificationColor}
      />
    );
  }
}
NotificationsTabContainer.propTypes = {
  appSlugToName: PropTypes.object,
  readNotificationColor: PropTypes.string,
  unreadNotificationColor: PropTypes.string,
  getNotificationsCounts: PropTypes.func,
};
NotificationsTabContainer.defaultProps = {};

export default NotificationsTabContainer;
