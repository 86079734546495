import { createContext, useState, useContext } from 'react';
import LocalApi from "../config/LocalApi";

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
    const [ready, setReady] = useState(false);
    const setToken = (tk) => LocalApi.setToken(tk);
    const getToken = () => {
        if(LocalApi.getToken() === null || LocalApi.getToken()==='null'){
            return null;
        }
        return LocalApi.getToken();
    }
    const getLocalApps = () => LocalApi.getLocalApps();

    return (
        <AppContext.Provider value={{
            getToken,
            setToken,
            ready,
            setReady,
            getLocalApps,
        }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    return useContext(AppContext);
};