import React from "react";
import PropTypes from "prop-types";
import NotificationCard from "../NotificationCard";

import {
  Container,
  tokens,
  TextField,
  Spacer,
  Icon,
  Text,
  Alert,
} from "squid-ui-kit";

class NotificationsTabPresentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const isFirstPage = this.props.filterProps.page <= 1;
    const isLastPage = this.props.filterProps.page >= this.props.numberOfPages;
    return (
      <>
        {this.props.notificationToBeDeletedId && (
          <Alert
            title={"Excluir notificação"}
            width="25rem"
            message={
              <Container
                bodyType="neutral"
                padding={tokens.PrimaryPadding}
                direction="horizontal"
                verticalAlign="center"
                horizontalAlign="center"
              >
                <Icon icon="alert" />
                <Spacer size={tokens.SecondaryPadding} direction="horizontal" />
                <Container bodyType="neutral">
                  Tem certeza que deseja excluir essa notificação?
                  <Spacer size={tokens.SecondaryPadding} direction="vertical" />
                  <Text>Esta ação não pode ser desfeita.</Text>
                </Container>
              </Container>
            }
            closable
            confirm
            onConfirm={() =>
              this.props.handleConfirmDelete({
                notificationId: this.props.notificationToBeDeletedId,
              })
            }
            onCancel={this.props.handleCancelDelete}
            onClose={this.props.handleCancelDelete}
            confirmLabel={"Excluir"}
            cancelLabel={"Cancelar"}
            buttonSize="small"
          />
        )}
        <Container
          width="100%"
          flex="1"
          bodyType="neutral"
          progress={this.props.loading ? -1 : null}
          progressLabel="Carregando notificações..."
        >
          <Spacer size={tokens.PrimaryPadding} direction="vertical" />
          <Container
            width="100%"
            padding={`0 ${tokens.PrimaryPadding}`}
            bodyType="neutral"
          >
            <TextField
              width="100%"
              placeholder="Buscar"
              searchButton
              icon={this.props.filterProps.searchText ? "close" : null}
              iconIsButton
              onIconClick={this.props.handleRefresh}
              onEnter={this.props.handleSearch}
              onSearch={this.props.handleSearch}
              value={this.props.searchBarValue}
              onChange={this.props.handleTypeSearch}
            />
          </Container>
          <Spacer size={tokens.PrimaryPadding} direction="vertical" />
          <div
            style={{
              width: "100%",
              flex: "1",
              boxSizing: "border-box",
              gap: tokens.SecondaryPadding,
              overflowX: "hidden",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              minHeight: "0",
              minWidth: "0",
              padding: `0 ${tokens.PrimaryPadding}`,
            }}
          >
            {this.props.content.length === 0 ? (
              <Container
                flex="1"
                verticalAlign="center"
                bodyType="neutral"
                padding={tokens.PrimaryPadding}
                horizontalAlign="center"
                width="100%"
              >
                {!this.props.loading && (
                  <>
                    <Icon
                      icon={
                        this.props.filterProps.searchText ? "preview" : "close"
                      }
                      size="large"
                    />
                    <Text textAlign="center" size="small" width="80%">
                      Você não possui notificações{" "}
                      {this.props.filterProps.searchText
                        ? "correspondentes aos parâmetros fornecidos"
                        : ""}
                    </Text>
                  </>
                )}
              </Container>
            ) : (
              this.props.content.map((notification) => {
                return (
                  <NotificationCard
                    loading={
                      this.props.loadingNotifications[
                        notification.notificationId
                      ]
                    }
                    key={notification.notificationId}
                    notificationId={notification.notificationId}
                    readColor={this.props.readNotificationColor}
                    unreadColor={this.props.unreadNotificationColor}
                    isRead={notification.read}
                    width={"100%"}
                    clampMessageAfterLines={5}
                    onDelete={this.props.handleClickDelete}
                    onMarkAsRead={this.props.handleMarkAsRead}
                    onMarkAsUnread={this.props.handleMarkAsUnread}
                    date={this.props.parseDate(notification.createdAt)}
                    title={
                      this.props.appSlugToName?.[notification.appOwner] ??
                      notification.appOwner
                    }
                    subject={notification.subject}
                    message={
                      notification.plaintext ||
                      "Não foi possível ler o conteúdo da mensagem"
                    }
                    showMessageTooltip
                  />
                );
              })
            )}
            <Spacer size={tokens.PrimaryPadding} direction="vertical" />
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: tokens.ColorWhite,
              opacity: "0.05",
            }}
          />
          <Container
            width="100%"
            padding={`${tokens.PrimaryPadding} ${tokens.SecondaryPadding}`}
            bodyType="primary"
            horizontalAlign="center"
            verticalAlign="center"
          >
            <Container
              width="100%"
              horizontalAlign="center"
              verticalAlign="center"
              direction="horizontal"
              bodyType="neutral"
            >
              <TextField
                width="120px"
                size="small"
                textAlign="center"
                subtitle="itens/página"
                onEnter={this.props.handleChangePageSize}
                value={this.props.pageSizeBarValue}
                onChange={this.props.handleTypePageSize}
              />
              <Spacer size={tokens.SecondaryPadding} direction="horizontal" />
              <div
                style={{
                  position: "relative",
                  paddingLeft: "5px",
                  boxSizing: "border-box",
                  opacity: isFirstPage ? "0.3" : "1",
                  cursor: isFirstPage ? "default" : "pointer",
                }}
                onClick={this.props.handleFirstPage}
              >
                <Icon icon="chevron-left" size="small" />
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                  }}
                >
                  <Icon icon="chevron-left" size="small" />
                </div>
              </div>

              <div
                style={{
                  opacity: isFirstPage ? "0.3" : "1",
                  cursor: isFirstPage ? "default" : "pointer",
                }}
                onClick={this.props.handlePreviousPage}
              >
                <Icon icon="chevron-left" size="small" />
              </div>
              <Spacer size={tokens.SecondaryPadding} direction="horizontal" />
              <Text size="small">
                página <b>{this.props.filterProps.page}</b> de{" "}
                <b>{this.props.numberOfPages ?? "-"}</b>
              </Text>
              <Spacer size={tokens.SecondaryPadding} direction="horizontal" />
              <div
                style={{
                  opacity: isLastPage ? "0.3" : "1",
                  cursor: isLastPage ? "default" : "pointer",
                }}
                onClick={this.props.handleNextPage}
              >
                <Icon icon="chevron-right" size="small" />
              </div>
              <div
                style={{
                  position: "relative",
                  paddingRight: "5px",
                  boxSizing: "border-box",
                  opacity: isLastPage ? "0.3" : "1",
                  cursor: isLastPage ? "default" : "pointer",
                }}
                onClick={this.props.handleLastPage}
              >
                <Icon icon="chevron-right" size="small" />
                <div
                  style={{
                    position: "absolute",
                    transform: "translateX(30%)",
                    top: "0",
                    left: "0",
                  }}
                >
                  <Icon icon="chevron-right" size="small" />
                </div>
              </div>
            </Container>
          </Container>
        </Container>
      </>
    );
  }
}

NotificationsTabPresentation.propTypes = {
  content: PropTypes.array,
  parseDate: PropTypes.func,
  getNotifications: PropTypes.func,
  searchBarValue: PropTypes.string,
  loading: PropTypes.bool,
  handleSearch: PropTypes.func,
  handleTypeSearch: PropTypes.func,
  handleMarkAsRead: PropTypes.func,
  handleRefresh: PropTypes.func,
  handleNextPage: PropTypes.func,
  handlePreviousPage: PropTypes.func,
  numberOfPages: PropTypes.number,
  notificationToBeDeletedId: PropTypes.string,
  handleClickDelete: PropTypes.func,
  handleCancelDelete: PropTypes.func,
  handleConfirmDelete: PropTypes.func,
  handleFirstPage: PropTypes.func,
  handleLastPage: PropTypes.func,
  filterProps: PropTypes.object,
  appSlugToName: PropTypes.object,
  handleChangePageSize: PropTypes.func,
  pageSizeBarValue: PropTypes.string,
  handleTypePageSiz: PropTypes.func,
  handleMarkAsUnread: PropTypes.func,
  loadingNotifications: PropTypes.object,
  readNotificationColor: PropTypes.string,
  unreadNotificationColor: PropTypes.string,
};

NotificationsTabPresentation.defaultProps = {};

export default NotificationsTabPresentation;
