import React, { useState } from "react";
import {
  Button,
  Container,
  Divider,
  Panel,
  Text,
  TextField,
  tokens,
} from "squid-ui-kit";

function AccessRequest({
  loading,
  errorMessage,
  successMessage,
  setErrorMessage,
  setSuccessMessage,
  onClose,
  onRequest,
}) {
  const [petrobrasKey, setPetrobrasKey] = useState(undefined);

  const onChange = (e) => {
    setPetrobrasKey(e.value);
    setErrorMessage(undefined);
    setSuccessMessage(undefined);
  };

  let content;
  if (successMessage) {
    content = <Text>{successMessage}</Text>;
  } else {
    content = (
      <Button
        disabled={loading}
        label="Solicitar"
        onClick={() => onRequest(petrobrasKey)}
      />
    );
  }

  return (
    <Panel
      label="Solicitar acesso ao portal"
      width="300px"
      direction="vertical"
      verticalAlign="center"
      horizontalAlign="center"
      margin="0px"
      bodyType="primary"
      padding={tokens.PrimaryPadding}
      shadow={true}
      closeButton={true}
      onClose={onClose}
    >
      <Container
        bodyType="neutral"
        horizontalAlign="center"
        width="100%"
        space="10px"
      >
        <Text>
          Para solicitar acesso ao portal é necessário informar uma chave
          Petrobras válida.
        </Text>
        <TextField
          placeholder="Chave Petrobras (ex: XPTO)"
          onChange={onChange}
          onEnter={() => onRequest(petrobrasKey)}
          error={errorMessage}
          width="95%"
        />
        <Divider direction="horizontal" />
        <Container bodyType="neutral" horizontalAlign="center" width="100%">
          {content}
        </Container>
      </Container>
    </Panel>
  );
}

export default AccessRequest;
