import React, { useState } from "react";
import PropTypes from "prop-types";
import CoreBackendApi from "../../../config/CoreBackendApi";
import WindowFormApplicationComponent from "./WindowFormApplication.component";


const WindowFormApplicationContainer = ({
    tokens,
    application,
    onApplicationUpdated,
    onClose,
}) =>{

    const [apiError, setApiError] = useState(undefined);

    const onCreate = (app) => {
        CoreBackendApi.createApplication(app)
            .then(()=>{
                onApplicationUpdated();
                setApiError(undefined);
            })
            .catch((err)=>{
                setApiError(err.message);
                console.error(err.message);
            });
    };
    const onUpdate = (app) => {
        CoreBackendApi.updateApplication(app)
            .then(()=>{
                onApplicationUpdated();
                setApiError(undefined);
            })
            .catch((err)=>{
                setApiError(err.message);
                console.error(err.message);
            });
    };
    const onRemove = (app) =>{
        CoreBackendApi.deleteApplication(app)
            .then(()=>{
                onApplicationUpdated();
                setApiError(undefined);
            })
            .catch((err)=>{
                setApiError(err.message);
                console.error(err.message);
            });
    }

    return (
        <WindowFormApplicationComponent
            tokens={tokens}
            application={application}
            onClose={onClose}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onRemove={onRemove}
            apiError={apiError}
            setApiError={setApiError}
        />
    );
}


WindowFormApplicationContainer.propTypes = {
  tokens: PropTypes.object.isRequired,
  application: PropTypes.object,
  onApplicationUpdated: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

WindowFormApplicationContainer.defaultProps = {
};

export default WindowFormApplicationContainer;
