const msgMap = new Map();

msgMap.set('The user already exists.', 'O usuário já existe.');
msgMap.set('Access request created', 'Solicitação de acesso criada');

export const translateMessageFromEnglish = (msg) =>{
    if(msg){
        if(msgMap.has(msg)){
            return msgMap.get(msg);
        }
        return msg;
    }
    return undefined;
}