import React, { useState } from "react";
import { TextArea, Spacer, Container, Icon, Text, tokens } from "squid-ui-kit";

const Version = ({ version }) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <>
      <Container
        width="100%"
        bodyType="content"
        padding="3px"
        direction="horizontal"
        verticalAlign="center"
        onClick={() => setCollapsed(!collapsed)}
      >
        <Icon icon="info" color={tokens.ColorPrimary} />
        <Text invertedColors>{version.name}</Text>
      </Container>
      {!collapsed && (
        <TextArea
          width="100%"
          height="200px"
          invertedColors
          inputType="text"
          readOnly
          textOnly
          resize="none"
          value={version.changelogRaw}
          b
        />
      )}
    </>
  );
};

const ApplicationVersions = ({ changelog }) => {
  if (changelog === undefined || changelog.versions === undefined) {
    return (
      <Container
        verticalAlign="center"
        horizontalAlign="center"
        width="100%"
        height="132px"
        bodyType="content"
        direction="horizontal"
      >
        <Icon icon="info" color={tokens.ColorPrimary} />
        <Text invertedColors>Nenhuma informação de versão encontrada.</Text>
      </Container>
    );
  }
  const { versions } = changelog;

  return (
    <Container width="100%" height="100%" bodyType="neutral" space="1px" scroll="vertical">
      {versions.map((version) => (
        <Version version={version} />
      ))}
      <Spacer size="45px" direction="vertical" />
    </Container>
  );
};

export default ApplicationVersions;
