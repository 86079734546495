import "./LoadingDots.style.css"; // Import the CSS file
import React from "react";

class LoadingDots extends React.Component {
  render() {
    const { dotColor, dotSize, dotSpace, dotJump, wrapperStyle } = this.props;
    const dotStyle = {
      backgroundColor: dotColor,
      width: dotSize,
      height: dotSize,
      margin: dotSpace,
      "--dot-jump": dotJump,
    };
    return (
      <div
        className="loading-container"
        style={{
          boxSizing: "border-box",
          ...wrapperStyle,
        }}
      >
        <div className="dot" style={dotStyle}></div>
        <div className="dot" style={dotStyle}></div>
        <div className="dot" style={dotStyle}></div>
      </div>
    );
  }
}

export default LoadingDots;
