import React from "react";
import PropTypes from "prop-types";
import Color from "color";

import {
  Panel,
  TextField,
  TextArea,
  Container,
  Button,
  Text,
  IconButton,
  Icon,
  tokens
} from "squid-ui-kit";


class CreateRoleModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      description: '',

      // window
      showSavingLog: false,

      // errors & logs
      errorName: undefined,
      errorDescription: undefined,
    };

    this.resetErrorsAndLogs = () => {
      this.setState({
          errorName: undefined,
          errorDescription: undefined,
          logSaving: undefined,
          lastSaveSucceeded: undefined,
          showSavingLog: false,
        });
    };

    this.resetUserData = () => {
      this.setState((state) => {
        return {
          name: "",
          description: "",
       };
      });
    };

    this.onUserChange = (e) => {
      const value = e.value.replace('/','')
      this.setState({
        name: value,
      });
    };

    this.onUserChangeBlur = (e) => {
      const value = this.state.name.replace('/','')
      this.setState({
        name: value,
      });
    };

    this.onDescriptionChange = (e) => {
      this.setState({
        description: e.value,
      });
    };

    this.onSave = async (e) => {
      const success = () => {
        this.setState((state) => {
          return {
            showSavingLog: true,
            logSaving: (
              <Container bodyType="neutral" direction="vertical" horizontalAlign="center">
                <Text>
                  PAPEL ADICIONADO COM SUCESSO!
                </Text>
                <Text>
                  NOME: {state.name}
                </Text>
              </Container>
            ),
            lastSaveSucceeded: true,
          };
        },
        this.resetUserData);
      };

      const fail = (err) => {
        let logSaving;
        switch(err.message.toUpperCase()) {
          case "USER ALREADY EXISTS.":
          case "USUÁRIO JÁ EXISTE.":
            logSaving = (
              <Container bodyType="neutral" direction="vertical" horizontalAlign="center">
                <Text>
                  JÁ EXISTE UM PAPEL COM ESTE MESMO NOME!
                </Text>
                <Text>
                  NOME: {this.state.name}
                </Text>
              </Container>
            )
            break;
          default:
            logSaving = (
              <Text>
                {err.message.toUpperCase()}
              </Text>
            )
            break;
        }

        this.setState({
          showSavingLog: true,
          logSaving,
          lastSaveSucceeded: false,
        },
        this.resetUserData);
      };

        if (
          this.props.onSave &&
          this.state.name &&
          this.state.name !== "" &&
          this.state.description &&
          this.state.description !== ""
        ) {
          this.props.onSave(this.state.name, this.state.description, success, fail);
        } else {
          let logMessage;
          if (!this.state.description || this.state.description === "") {
            logMessage = "É NECESSÁRIO INFORMAR A DESCRIÇÃO.";
          }

          if (!this.state.name || this.state.name === "") {
            logMessage = "É NECESSÁRIO INFORMAR O NOME.";
          }
  
          this.setState({
            showSavingLog: true,
            logSaving: (
              <Text>
                {logMessage}
              </Text>
            ),
            lastSaveSucceeded: false,
          });
        }
    };
  }

  render() {
    let footer = (
      <Container
        width="auto"
        bodyType="neutral"
        padding="0px"
        direction="horizontal"
        horizontalAlign="right"
        verticalAlign="center"
      >
        <Button
          label={"ADICIONAR"}
          size='small'
          type="cta"
          onClick={(e) => {
            this.onSave(e);
          }}
        />
      </Container>
    );

    return (
      <div
        style={{
          display: "flex",
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          backgroundColor: Color(tokens.ColorBlack).alpha(0.3),
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          zIndex: 20,
        }}
      >
        <Panel
          label={"ADICIONAR PAPEL"}
          direction="vertical"
          verticalAlign="top"
          horizontalAlign="left"
          bodyType="primary"
          width="500px"
          height="100%"
          footer={footer}
          closeButton={true}
          padding="10px"
          space="10px"
          onClose={this.props.onClose}
        >
          <TextField
            width="100%"
            label="NOME"
            placeholder="NOME"
            name="portal-settings-create-modal-name"
            value={this.state.name}
            error={this.state.errorName}
            onChange={this.onUserChange}
            onBlur={this.onUserChangeBlur}
          />
            <TextArea
              width="100%"
              label="DESCRIÇÃO"
              placeholder="DESCRIÇÃO"
              name="portal-settings-create-modal-description"
              height='90px'
              value={this.state.description}
              error={this.state.errorDescription}
              onChange={this.onDescriptionChange}
            />

          {this.state.showSavingLog && (
              <Container 
                bodyType={this.state.lastSaveSucceeded ? "success" : "danger"} 
                width='100%' 
                direction="horizontal" 
                padding='5px' 
                space='10px' 
                horizontalAlign="center" 
                verticalAlign="center"
              >
                <Container 
                  horizontalAlign="center" 
                  verticalAlign="center"
                  width='50px'
                  direction="vertical"
                  bodyType="neutral"
                > 
                  <Icon icon={ this.state.lastSaveSucceeded ? "checkmark" : "alert" } size="large" />
                </Container>
                <Container 
                  horizontalAlign="center" 
                  verticalAlign="center"
                  direction="vertical"
                  flex='1'
                  bodyType="neutral"
                > 
                  {this.state.logSaving}
                </Container>
                <Container 
                  horizontalAlign="center" 
                  verticalAlign="center"
                  direction="vertical"
                  width='50px'
                  bodyType="neutral"
                > 
                  <IconButton icon="close" size="large" onClick={ () => { this.setState({showSavingLog: false}); } } />
                </Container>
            </Container>
          )}
        </Panel>
      </div>
    );
  }
}

CreateRoleModal.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default CreateRoleModal;
