import React, { useState } from "react";

import closedLockerIcon from "../../../../assets/iconlocked.svg"
import openLockerIcon from "../../../../assets/iconunlocked.svg"

import {
  Container,
  Text,
  Button,
  Footer
} from "squid-ui-kit";

import { 
  ExclusionAlert
} from '../../../../components'

const AccessInformationCard = 
  ({ 
    isApplicationLocked,
    isAdmin = false,
    appAuthorizations,
    onChangeAuthorizationAccess
  }) => {
  const [openExclusionModal, setOpenExclusionModal] = useState(false);

  const exclusionAlertText = 
    isApplicationLocked ? 
    'Tem certeza que você deseja liberar o acesso a essa Aplicação?'
    : 'Tem certeza que você deseja restringir o acesso a essa Aplicação?'

  const authorizationDescriptionLabel = 
    isApplicationLocked ? 
    'Essa aplicação só pode ser acessada por usuários autorizados do Portal SUBWEB.'
    : 'Essa aplicação pode ser acessada por qualquer usuário autenticado do Portal SUBWEB.'

  const buttonLabel =
    isApplicationLocked ? 
    'LIBERAR ACESSO'
    : 'RESTRINGIR ACESSO'

  const buttonIcon = isApplicationLocked ? closedLockerIcon : openLockerIcon

  return (
      <Footer
        width='100%'
        bodyType="active"
        height="51px"
      >
        <Container
          width="100%"
          bodyType="neutral"
          direction="vertical"
        >
          <Container
            direction="horizontal"
            bodyType="neutral"
            verticalAlign  ="center"
            horizontalAlign="left"
            width="100%"
            height="100%"
            padding="0px 10px 0px 10px"
            gap="5px"
          >
            <img
              style={{
                width: "24px",
                height: "24px",
                }}
              src={buttonIcon}
              alt="roles icon"
            />

            <Container
              height='24px'
              bodyType="neutral"
              verticalAlign  ="center"
              horizontalAlign="left"
            >
              <Text 
                invertedColors
                bold
                textAlign="center"
                verticalAlign="center"
                size="default"
              >
                {authorizationDescriptionLabel}
              </Text>
            </Container>
            

            {isAdmin && isApplicationLocked !== undefined &&
              (
                <Container
                  flex="1"
                  bodyType="neutral"
                  verticalAlign  ="center"
                  horizontalAlign="right"
                  padding="0px 10px 0px 10px"
                >
                  <Button
                    name="change-app-auth-default"
                    size="small"
                    width="fit-content"
                    type={isApplicationLocked ? 'primary' : 'secondary'}
                    label={buttonLabel}
                    gap='10px'
                    onClick={()=> setOpenExclusionModal(true)}
                  />
                </Container>
              )
            }
            
          </Container>

          {openExclusionModal && (
            <ExclusionAlert 
              exclusionText={exclusionAlertText}
              title='Alerta'
              onCancel={() => setOpenExclusionModal(false)}
              onConfirm={() => {
                onChangeAuthorizationAccess(!isApplicationLocked)
                setOpenExclusionModal(false)
              }}
            />
          )}
        </Container>
      </Footer>
  )     
};

export default AccessInformationCard;
