import React, { useState } from "react";

import TokenDetails from "./tokenDetails.component";

import ConfigApi from "../../../../config/SquidAuth";

function TokenDetailsContainer({ applicationInformation, applicationID }) {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [showSucessModal, setShowSucessModal] = useState(false);
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const confirmRenewToken = async () => {
    try {
      setLoading(true);

      await ConfigApi.renewToken(applicationID, setToken);

      setShowSucessModal(true);
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const copyTokenToClipboard = async () => {
    try {
      setLoading(true);

      await navigator.clipboard.writeText(token);

      setShowSucessModal(true);
      setShowCopyMessage(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
      setLoading(false);
      /* Rejected - text failed to copy to the clipboard */
    } finally {
      setLoading(false);
    }
  };

  return (
    <TokenDetails
      applicationInformation={applicationInformation}
      token={token}
      confirmRenewToken={confirmRenewToken}
      copyTokenToClipboard={copyTokenToClipboard}
      loading={loading}
      showSucessModal={showSucessModal}
      closeSucessModal={(value) => {
        setShowSucessModal(value);
        setShowCopyMessage(false);
      }}
      showCopyMessage={showCopyMessage}
    />
  );
}

export default TokenDetailsContainer;
