import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import './pagination.style.css';

import {
  IconButton,
  Button,
  Container,
} from "squid-ui-kit";;


const Pagination = ({
  width,
  height,
  totalOfPages,
  page,
  pageSize,
  onChange,
  invertedColors,
  range,
  margin,
  rightRender,
  leftRender,
}) => 
  {
    return (
      <Container
        width={width}
        height={height}
        direction="horizontal"
        bodyType={invertedColors ? 'content' : 'neutral'}
        horizontalAlign="center"
        verticalAlign="center"
      >
        <Container
          bodyType="neutral"
          flex="1"
          padding="0px 5px 0px 5px"
        >
          {leftRender?.() || null}
        </Container>
        <ReactPaginate
          id="react-paginate"
          pageCount={totalOfPages}
          pageRangeDisplayed={range}
          marginPagesDisplayed={margin}
          forcePage={page || -1}
          onPageChange={({ selected: value }) => onChange(value, pageSize)}
          containerClassName="pagination"
          previousLabel={(
            <IconButton
              size="small"
              invertedColors={invertedColors}
              icon="chevron-left"
            />
          )}
          nextLabel={(
            <IconButton
              size="small"
              invertedColors={invertedColors}
              icon="chevron-right"
            />
          )}
          breakClassName="break-classname"
          breakLabel={(
            <Button
              size="small"
              width="24px"
              type="link"
              label="..."
            />
          )}
          pageLabelBuilder={(currPage) => (
            <Button
              size="small"
              width="24px"
              type={page + 1 === currPage ? 'primary' : 'secondary'}
              label={currPage}
            />
          )}
        />
        <Container
          bodyType="neutral"
          flex="1"
          height="100%"
          direction="horizontal"
          verticalAlign="center"
          padding="0px 5px 0px 5px"
        >
          <Container
            bodyType="neutral"
            flex="1"
            horizontalAlign="right"
            height="100%"
          >
            {rightRender?.() || null}
          </Container>
        </Container>
      </Container>
      )
  };

Pagination.propTypes = {
  totalOfPages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  invertedColors: PropTypes.bool,
  range: PropTypes.number,
  margin: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  rightRender: PropTypes.func,
  leftRender: PropTypes.func,
};

Pagination.defaultProps = {
  width: '100%',
  height: 'fit-content',
  invertedColors: false,
  range: 3,
  margin: 2,
  rightRender: undefined,
  leftRender: undefined,
};

export default Pagination;
