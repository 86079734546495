import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { 
  Alert, 
  Container,
  Panel, 
  Text,
  Spacer,
  Icon,
  IconButton,
  TextField,
} from "squid-ui-kit";
import PaginatedTable from "../../components/PaginatedTable";

const UsersPresentation = ({
  tokens,
  warning,
  users,
  columns,
  itemsPerPage,
  setItemsPerPage,
  getCurrentPage,
  searchValue,
  onAdd,
  onSearch,
  onSearchClear,
  onSearchChange,
  onConfirmDelete,
  onCancelDelete,
}) => {
 
  const [isSearchActive, setIsSearchActive] = useState(false);
  const searchValueRef = useRef();

  let alert;
  if (warning && warning !== "") {
    alert = (
      <Alert
        hasBorder={true}
        title="Atenção"
        message={warning}
        confirm={true}
        closable={false}
        confirmLabel="Sim"
        cancelLabel="Cancelar"
        onConfirm={onConfirmDelete}
        onCancel={onCancelDelete}
        bodyType="content"
        padding={tokens.PrimaryPadding}
      />
    );
  }

  let table;
  if (users.length > 0) {
    table = (
      <PaginatedTable
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        dataSource={users}
        columns={columns}
        getCurrentPage={getCurrentPage}
        isSearchActive={isSearchActive}
      />
    );
  } else {
    table = (
      <Container height="100%" width="100%" space="10px" verticalAlign="center" horizontalAlign="center">
        <Text size="large">
          Nenhum usuário encontrado.           
        </Text>
        <Text size="large">
          Por favor, refaça a busca ou adicione um novo usuário ao portal.
        </Text>
        <Container
          padding="5px"
          direction="horizontal"
          verticalAlign="center"
          bodyType="neutral"
          onClick={()=> onAdd()}
          borderType="active"
        >
          <Icon icon="add" />
          <Text size="large">
            Novo usuário
          </Text>
        </Container>
        
      </Container>
    );
  }

  if (columns && columns.length > 0) {
    return (
      <Container
        height="100%"
        flex="1"
        // height="100%"
        direction="vertical"
        verticalAlign="top"
        bodyType="neutral"
        margin="0px"
        padding="0px"
      >
        <Container height="25px" bodyType="active" direction="horizontal" width="100%" verticalAlign="center" padding="10px">
          <Text bold> USUÁRIOS </Text>
          <Spacer/>
          <Container bodyType="content" width="fit-content" direction="horizontal">
            {isSearchActive && 
              <IconButton
                icon="close"
                invertedColors
                onClick={(e) => {
                  setIsSearchActive(false);
                  searchValueRef.current.focus();
                  onSearchClear(e);
                  }
                }
              />
            }
            <TextField
              ref={searchValueRef}
              placeholder="Nome, e-mail ou chave"
              value={searchValue}
              onChange={onSearchChange}
              onEnter={(e) => {
                let activate = true;
                if (e.value === "") {
                  activate = false;
                }
                setIsSearchActive(activate);
                onSearch(e);
              }
              }
              onSearch={(e) => {
                  let activate = true;
                  if (e.value === "") {
                    activate = false;
                  }
                  setIsSearchActive(activate);
                  onSearch(e);
                }
              }
              invertedColors
              searchButton
            />
          </Container>
          <Container bodyType="neutral" direction="horizontal" verticalAlign="center" onClick={onAdd}>
            <Icon icon="add"/>
            <Text bold> NOVO </Text>
          </Container>
        </Container>
        {table}
        {alert}
      </Container>
    );
  } else {
    return (
      <div>
        <Panel
          label="Usuários"
          height="100%"
          flex="1"
          direction="vertical"
          verticalAlign="top"
          bodyType="primary"
        ></Panel>
        {alert}
      </div>
    );
  }

}

UsersPresentation.propTypes = {
  tokens: PropTypes.object.isRequired,
  warning: PropTypes.string,
  users: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  onConfirmDelete: PropTypes.func,
  onCancelDelete: PropTypes.func,
  onAdd: PropTypes.func,
  onSearch: PropTypes.func,
  onSearchClear: PropTypes.func,
  onSearchChange: PropTypes.func,
  searchValue: PropTypes.string,
  getCurrentPage: PropTypes.func,
  itemsPerPage: PropTypes.number,
  setItemsPerPage: PropTypes.func,
};

UsersPresentation.defaultProps = {
  warning: "",
  users: [],
  columns: [],
};

export default UsersPresentation;
