import React from "react";
import PropTypes from "prop-types";
import { Text, Spacer, Icon, Container, tokens, Tooltip } from "squid-ui-kit";
import "./NotificationCard.styles.css";
import OpenMailIcon from "../../../../../assets/OpenMailIcon";
import ClosedMailIcon from "../../../../../assets/ClosedMailIcon";

class NotificationCard extends React.Component {
  constructor(props) {
    super(props);

    this.getFontColor = () => {
      if (this.props.invertedColors) {
        if (this.props.isRead) {
          return tokens.ColorPrimaryDark;
        } else {
          return tokens.ColorPrimary;
        }
      }
      if (this.props.isRead) return "rgba(255, 255, 255, 0.7)";

      return "rgba(255, 255, 255, 0.95)";
    };

    this.getBgColor = () => {
      if (this.props.invertedColors) {
        if (this.props.isRead) {
          return "rgb(225, 225, 225)";
        } else {
          return "rgb(250, 250, 250)";
        }
      }

      if (this.props.isRead) return "rgba(255, 255, 255, 0.025)";

      return "rgba(255, 255, 255, 0.1)";
    };
  }

  render() {
    return (
      <Container
        width={this.props.width}
        height={this.props.height}
        flex={this.props.flex}
        bodyType="neutral"
        progress={this.props.loading ? -1 : undefined}
      >
        <div
          className="notification-card-container"
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            backgroundColor: this.getBgColor(),
            border: this.props.invertedColors
              ? ""
              : `1px solid rgba(255, 255, 255, 0.05)`,
            minHeight: this.props.minHeight,
            position: "relative",
          }}
        >
          <div
            style={{
              minWidth: "4px",
              width: "4px",
              height: "100%",
              backgroundColor: this.props.isRead
                ? this.props.readColor
                : this.props.unreadColor,
            }}
          />
          <Spacer size={tokens.SecondaryPadding} direction="horizontal" />
          <div
            style={{
              flex: "1",
              height: "100%",
              boxSizing: "border-box",
              padding: tokens.SecondaryPadding,
              paddingLeft: tokens.PrimaryPadding,
              display: "flex",
              flexDirection: "column",
              minWidth: "0px",
              minHeight: "0px",
            }}
          >
            <Container
              direction="horizontal"
              verticalAlign="center"
              width="100%"
              bodyType="neutral"
            >
              <Container
                direction="horizontal"
                verticalAlign="center"
                flex="1"
                bodyType="neutral"
                padding={`${tokens.SecondaryPadding} 0 0 0`}
              >
                <div
                  style={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "8px",
                    backgroundColor: this.props.isRead
                      ? this.props.readColor
                      : this.props.unreadColor,
                  }}
                />
                <Spacer size={tokens.SecondaryPadding} direction="horizontal" />
                <div
                  title={this.props.title}
                  style={{
                    flex: "1",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    fontSize: tokens.FontBodyPrimarySize,
                    fontFamily: tokens.FontTableBodyDefaultFamily,
                    fontWeight: this.props.isRead
                      ? tokens.FontTitlePrimaryWeight
                      : tokens.FontBodyActionPrimaryWeight,
                    lineHeight: tokens.FontControlLargeSize,
                    color: this.getFontColor(),
                  }}
                >
                  {this.props.title}
                </div>
              </Container>
              <Spacer size={tokens.SecondaryPadding} direction="horizontal" />

              {this.props.onMarkAsUnread && this.props.isRead && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    if (this.props.onMarkAsUnread)
                      return this.props.onMarkAsUnread({
                        ...e,
                        notificationId: this.props.notificationId,
                      });
                  }}
                >
                  <Tooltip title="Marcar como não lida">
                    <ClosedMailIcon
                      width="16px"
                      height="16px"
                      color={
                        this.props.invertedColors
                          ? tokens.ColorPrimary
                          : "rgba(255,255,255,.7)"
                      }
                    />
                  </Tooltip>
                </div>
              )}
              {this.props.onMarkAsRead && !this.props.isRead && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    if (this.props.onMarkAsRead)
                      return this.props.onMarkAsRead({
                        ...e,
                        notificationId: this.props.notificationId,
                      });
                  }}
                >
                  <Tooltip title="Marcar como lida">
                    <OpenMailIcon
                      width="16px"
                      height="16px"
                      color={
                        this.props.invertedColors
                          ? tokens.ColorPrimary
                          : "rgba(255,255,255,.7)"
                      }
                    />
                  </Tooltip>
                </div>
              )}
              <Spacer size="2px" />
              {this.props.onDelete && (
                <Container
                  bodyType="neutral"
                  onClick={(e) => {
                    if (this.props.onDelete)
                      return this.props.onDelete({
                        ...e,
                        notificationId: this.props.notificationId,
                      });
                  }}
                >
                  <Icon
                    icon="trash"
                    onClick={this.props.onDelete}
                    color={
                      this.props.invertedColors
                        ? tokens.ColorPrimary
                        : undefined
                    }
                  />
                </Container>
              )}
            </Container>
            <Container
              width="100%"
              padding={`${tokens.PrimaryPadding} ${tokens.PrimaryPadding} ${tokens.PrimaryPadding} 0`}
              flex="1"
              bodyType="neutral"
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: tokens.FontBodyPrimarySize,
                  fontFamily: tokens.FontTableBodyDefaultFamily,
                  fontWeight: this.props.isRead
                    ? tokens.FontBodyActionSecondaryWeight
                    : tokens.FontTitlePrimaryWeight,
                  lineHeight: tokens.FontControlLargeSize,
                  color: this.getFontColor(),
                  minWidth: "0",
                  width: "100%",
                }}
              >
                {this.props.subject}
              </div>
              <Spacer size={tokens.PrimaryPadding} direction="vertical" />
              <Container width="100%" bodyType="neutral" flex="1">
                <div
                  className={`text-notification-container ${
                    !this.props.invertedColors
                      ? "text-notification-container-dark"
                      : ""
                  }`}
                  style={{
                    fontSize: tokens.FontTableBodySize,
                    fontFamily: tokens.FontTableBodyDefaultFamily,
                    fontWeight: tokens.FontControlSmallWeight,
                    lineHeight: tokens.FontControlLargeSize,
                    wordBreak: "break-word",
                    "--clamp-message-after-lines":
                      this.props.clampMessageAfterLines,
                    color: this.getFontColor(),
                  }}
                >
                  {this.props.showMessageTooltip ? (
                    <Tooltip
                      titleBodyType="danger"
                      title={
                        <div
                          style={{
                            maxWidth: "500px",
                            padding: tokens.SecondaryPadding,
                          }}
                        >
                          {this.props.message}
                        </div>
                      }
                      placement="rightTop"
                    >
                      <span>{this.props.html ?? this.props.message}</span>
                    </Tooltip>
                  ) : (
                    <span>{this.props.html ?? this.props.message}</span>
                  )}
                </div>
              </Container>
            </Container>
            <Container
              direction="horizontal"
              verticalAlign="center"
              width="100%"
              bodyType="neutral"
            >
              {this.props.date && (
                <Text size="small" invertedColors={this.props.invertedColors}>
                  {this.props.date}
                </Text>
              )}
              <Spacer flex="1" direction="horizontal" />
            </Container>
          </div>
        </div>
      </Container>
    );
  }
}

NotificationCard.propTypes = {
  isRead: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  minHeight: PropTypes.string,
  flex: PropTypes.string,
  onDelete: PropTypes.func,
  onMarkAsRead: PropTypes.func,
  onMarkAsUnread: PropTypes.func,
  title: PropTypes.string,
  subject: PropTypes.string,
  message: PropTypes.node,
  date: PropTypes.string,
  clampMessageAfterLines: PropTypes.number,
  readColor: PropTypes.string,
  unreadColor: PropTypes.string,
  notificationId: PropTypes.string,
  invertedColors: PropTypes.bool,
  showMessageTooltip: PropTypes.bool,
  loading: PropTypes.bool,
};

NotificationCard.defaultProps = {
  readColor: tokens.ColorHighlight,
  unreadColor: tokens.ColorSecondary,
  invertedColors: true,
};

export default NotificationCard;
