import React from "react";
import PropTypes from "prop-types";

import LogoLccv from "../../assets/logo-lccv.svg";
import LogoPetrobras from "../../assets/logo-petrobras.svg";

import {
  // Alert,
  Container,
  Panel,
  Spacer,
  TextField,
  Button,
  tokens,
  Text,
  Icon,
  IconButton
} from "squid-ui-kit";
import { SignInButton } from "../../components/SignInButton/SignInButton";
import AccessRequest from "./components/AccessRequest";

class LoginPresentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showHelp: false,
      showAccessRequest: false,
      user: "",
      password: "",
    };

    this.onUserChange = (e) => {
      this.setState({
        user: e.value,
      });
    };
    this.onPasswordChange = (e) => {
      this.setState({
        password: e.value,
      });
    };
    this.onLogin = (e) => {
      if (this.props.onLogin) {
        this.props.onLogin({
          user: this.state.user,
          password: this.state.password,
        });
      }
    };
    // Login mode
    this.onLoginMode = (e) => {
      this.setState({
        showHelp: false,
        showAccessRequest: false,
      });
    };
    // Help mode
    this.onHelpMode = (e) => {
      this.setState({
        showHelp: true,
        showAccessRequest: false,
      });
    };
    // Access request mode
    this.onAccessRequestMode = (e) => {
      this.setState({
        showHelp: false,
        showAccessRequest: true,
      });
    };
  }

  render() {
    // SSO related
    let optionalSsoComponent= <></>;
    if (window.ENABLE_SSO && window.ENABLE_SSO.indexOf("true") > -1) {
      optionalSsoComponent = (<>
          <SignInButton />
          <Spacer size="10px" />
        </>);
    } 

    let content;
    
    // Login form
    if (this.state.showHelp === false && this.state.showAccessRequest === false) {
      content = (
        <Panel
          label="Acesso"
          width="400px"
          height="auto"
          direction="vertical"
          verticalAlign="top"
          bodyType="primary"
          padding="10px"
          shadow={true}
          footer={
            <Container
              width="auto"
              bodyType="neutral"
              padding="0px"
              direction="horizontal"
              horizontalAlign="right"
              verticalAlign="center"
            >
              <IconButton icon="help" label="Preciso de ajuda" size="large" onClick={this.onHelpMode}/>
              <Button
                label="Solicitar acesso"
                type="link"
                onClick={this.onAccessRequestMode}
              />
              <Spacer flex="1" />
              {optionalSsoComponent}
              <Button 
                label="ENTRAR" 
                type="primary" 
                onClick={() => {
                this.props.onClear()
                this.props.onCloseExpirationAlert()
                this.onLogin()
                }} 
              />
            </Container>
          }
        >          
          <TextField
            ref={this.inputRef}
            width="100%"
            label="Usuário"
            name="portal-user-inp"
            placeholder="letras e números"
            invertedColors={false}
            value={this.state.user}
            onChange={this.onUserChange}
            onEnter={() => {
              this.onLogin()
            }}
            onFocus={() => {
              this.props.onClear()
              this.props.onCloseExpirationAlert()
            }}
          />
          <Spacer direction="vertical" size="18px" />
          <TextField
            width="100%"
            label="Senha"
            name="portal-pass-inp"
            placeholder="*****"
            inputType="password"
            invertedColors={false}
            value={this.state.password}
            onChange={this.onPasswordChange}
            onEnter={() => {
              this.onLogin()
            }}
            onFocus={() => {
              this.props.onClear()
              this.props.onCloseExpirationAlert()
            }}
          />

          {((this.props.showExpirationAlert && this.props.showExpirationAlert === true) || 
            (this.props.error && this.props.error !== "")) && (
            <>
              <Spacer direction="vertical" size="10px" />
              <Container 
                bodyType={"danger"} 
                width='100%' 
                direction="horizontal" 
                padding='5px' 
                horizontalAlign="left" 
                verticalAlign="center"
              >
                  <Container 
                    horizontalAlign="center" 
                    verticalAlign="center"
                    width='40px'
                    direction="vertical"
                    bodyType="neutral"
                  > 
                    <Icon icon={ "alert" } size="large" />
                  </Container>

                  <Container 
                    horizontalAlign="center" 
                    verticalAlign="center"
                    direction="vertical"
                    bodyType="neutral"
                    width="330px"
                  > 
                    <Container 
                      bodyType="neutral" 
                      direction="vertical"  
                      verticalAlign="center"
                      horizontalAlign="center" 
                      width="100%"
                      >
                      <Text
                        verticalAlign="center"
                        textAlign="center"
                        size="small"
                        >
                          {this.props.showExpirationAlert && this.props.showExpirationAlert === true && 
                            'Sua sessão expirou. Faça login novamente.'
                          }
                          {this.props.error && this.props.error !== "" && 
                            'Erro ao tentar se autenticar. Tente novamente'
                          }
                      </Text>
                    </Container>
                  </Container>
              </Container>
            </>
          )}
        </Panel>
      );
    }

    // Help form
    if (this.state.showHelp === true) {
      content = (
        <Panel
          label="Suporte ao sistema"
          width="500px"
          height="auto"
          direction="vertical"
          verticalAlign="top"
          horizontalAlign="left"
          bodyType="content"
          margin="0px"
          padding={tokens.PrimaryPadding}
          shadow={true}
          closeButton={true}
          onClose={() => this.onLoginMode()}
        >
          <div
            style={{
              fontFamily: this.props.tokens.FontControlDefaultFamily,
              fontWeight: this.props.tokens.FontBodySecondaryWeight,
              fontSize: this.props.tokens.FontControlDefaultSize,
              lineHeight: this.props.tokens.LineHeightBodyAction,
            }}
          >
            <p>Olá, tudo bem?</p>
            <br />
            <p>
              Este é o canal de suporte. Para dúvidas ou problemas, favor enviar um e-mail para o nosso endereço:
            </p>
            <p style={{ fontWeight: 900 }}>
              cc-suporte-navalsubweb@petrobras.com.br
            </p>
            <br />
            <p>Através desse canal, poderemos agilizar o atendimento de sua solicitação.</p>
            <br />
            <p>Agradecemos pela compreensão.</p>
          </div>
        </Panel>
      );
    }

    if(this.state.showAccessRequest === true){
      content = <AccessRequest onClose={() => this.onLoginMode()}/>
    }


    return (
      <div
        className="App"
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          // backgroundColor: this.props.tokens.ColorPrimary,
        }}
        // onClick={this.props.onCloseExpirationAlert}
      >
        <Container
          width="100%"
          height="100%"
          direction="horizontal"
          verticalAlign="top"
          bodyType="neutral"
          margin="0px"
          padding="0px"
        >
          <Container
            flex="3"
            height="100%"
            direction="vertical"
            horizontalAlign="left"
            verticalAlign="center"
            bodyType="neutral"
            margin="0px 0px 0px 0px"
            // margin="0px"
            padding={tokens.PrimaryPadding}
          >
            {/* <Spacer
              direction="vertical"
              size={
                window.SQUID_PORTAL_ID === "portal_navalweb" ? "22%" : "32%"
              }
            /> */}
            <Spacer direction="vertical" size="25%" />
            <Container
              // width={
              //   window.SQUID_PORTAL_ID === "portal_navalweb" ? "500px" : "100%"
              // }
              width="500px"
              height="auto"
              direction="vertical"
              verticalAlign="center"
              horizontalAlign="center"
              bodyType="neutral"
              // margin="0px"
              margin="0px 0px 0px 80px"
              padding="0px"
            >
              <Container
                width="auto"
                height="auto"
                direction="horizontal"
                verticalAlign="center"
                horizontalAlign="center"
                bodyType="neutral"
                margin="0px"
                padding="0px"
              >
                <img
                  alt=""
                  style={{
                    width: "auto",
                    height: "auto",
                    // minHeight: "85px",
                    // filter: `drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3))`,
                  }}
                  src={`${window.SQUID_PORTAL_ID}.svg`}
                />
              </Container>
              <Spacer direction="vertical" size="50px" />
              {content}
            </Container>
            {/* <Spacer direction="vertical" size="20px" /> */}
            <Spacer direction="vertical" flex="1" />
            {/* <Spacer direction="vertical" flex="4" /> */}
            {/* <Container
              flex="4"
              height="300px"
              direction="horizontal"
              verticalAlign="center"
              bodyType="neutral"
              margin="0px"
              padding="0px"
            >
              <div
                style={{
                  backgroundColor: "white",
                  filter: `drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3))`,
                }}
              >
                {help}
              </div>
            </Container> */}
            <Container
              // width={
              //   window.SQUID_PORTAL_ID === "portal_navalweb" ? "500px" : "100%"
              // }
              width="500px"
              height="52px"
              direction="horizontal"
              verticalAlign="center"
              horizontalAlign="center"
              bodyType="neutral"
              // margin="0px"
              margin="0px 0px 0px 80px"
              padding="0px"
            >
              <img
                alt=""
                style={{
                  filter: `drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3))`,
                }}
                src={LogoPetrobras}
              />
              <Spacer size="20px" />
              <img
                alt=""
                style={{
                  filter: `drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3))`,
                }}
                src={LogoLccv}
              />
            </Container>
            {/* <Spacer direction="vertical" flex="4" /> */}
            <Spacer direction="vertical" size="20px" />
          </Container>
          {/* <Spacer size="100px" /> */}
          {/* <Divider direction="vertical" /> */}
          {/* <Container
            flex="4"
            height="100%"
            direction="horizontal"
            verticalAlign="center"
            bodyType="neutral"
            margin="0px"
            padding="0px"
          >
            <div
              style={{
                backgroundColor: "white",
                filter: `drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3))`,
              }}
            >
              {help}
            </div>
          </Container> */}
        </Container>
        {/*{authAlert}*/}
        {/*{expirationAlert}*/}
      </div>
    );
  }
}

LoginPresentation.propTypes = {
  error: PropTypes.string,
  apps: PropTypes.arrayOf(PropTypes.object),
  onClickApp: PropTypes.func,
  onClear: PropTypes.func,
  onLogin: PropTypes.func,
  showExpirationAlert: PropTypes.bool,
  onCloseExpirationAlert: PropTypes.func,
};

LoginPresentation.defaultProps = {
  error: "",
  apps: [],
  showExpirationAlert: false,
};

export default LoginPresentation;
