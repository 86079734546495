import React from "react";
import PropTypes from "prop-types";
import HomePresentation from "./Home.presentation";
import LocalApi from "../../config/LocalApi";
import defaultAvatar from "../../assets/default-avatar.png";

class HomeContainer extends React.Component {
  constructor(props) {
    super(props);

    let user = LocalApi.getUser();

    this.state = {
      name: user.name,
      avatar: user.avatar || defaultAvatar,
    };

    this.onOpenApp = (index) => {
      if (this.props.onOpenApp) {
        this.props.onOpenApp(index);
      }
    };

    this.onOpenBasicData = (index) => {
      if (this.props.onOpenBasicData) {
        this.props.onOpenBasicData(index);
      }
    };
  }

  render() {
    return (
      <HomePresentation
        tokens={this.props.tokens}
        apps={this.props.apps}
        basicDataApps={this.props.basicDataApps}
        name={this.state.name}
        avatar={this.state.avatar}
        onClickApp={this.onOpenApp}
        onClickBasicData={this.onOpenBasicData}
        onLogout={this.props.onLogout}
        handleAuthorizationModal={this.props.handleAuthorizationModal}
      />
    );
  }
}

HomeContainer.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.object),
  basicDataApps: PropTypes.arrayOf(PropTypes.object),
  onOpenApp: PropTypes.func,
  onLogout: PropTypes.func,
  handleAuthorizationModal: PropTypes.func,
};

HomeContainer.defaultProps = {
  apps: [],
  basicDataApps: undefined,
};

export default HomeContainer;
