import React from "react";
import PropTypes from "prop-types";

import TabBarPresentation from "./TabBar.presentation";

class TabBarContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onChange = (index) => {
      if (this.props.onChange) {
        this.props.onChange(index);
      }
    };
    this.onTabClose = (index) => {
      if (this.props.onTabClose) {
        this.props.onTabClose(index);
      }
    };
  }

  render() {
    return (
      <TabBarPresentation
        tokens={this.props.tokens}
        tabs={this.props.tabs}
        tab={this.props.tab}
        onChange={this.onChange}
        onTabClose={this.onTabClose}
      />
    );
  }
}

TabBarContainer.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  tab: PropTypes.string,
  onChange: PropTypes.func,
  onTabClose: PropTypes.func,
};

TabBarContainer.defaultProps = {
  tabs: [],
  tab: "",
};

export default TabBarContainer;
