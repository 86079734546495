import React from "react";
import PropTypes from "prop-types";
import Color from "color";
import uniqid from "uniqid";

import { ExclusionAlert } from '../../components'


import { Container, Spacer, tokens, Tooltip } from "squid-ui-kit";


const AppButtonPresentation = (props) => {



  return (
    <div
      style={{
        width: "80px",
        height: "auto",
        borderRadius: props.tokens.RadiusPrimary,
        // backgroundColor: Color(props.tokens.ColorWhite).alpha(0.9),
        cursor: "pointer",
      }}
    >
      <Container
        width="100%"
        height="100%"
        direction="vertical"
        horizontalAlign="center"
        verticalAlign="top"
        bodyType="neutral"
        margin="0px"
        name={`app-button-${props.index}`}
        // padding="5px"
        onClick={props.onClick}
        disabled={props.loading}
      >
        <div
          style={{
            width: "80px",
            height: "80px",
            borderRadius: props.tokens.RadiusPrimary,
            // backgroundColor: props.tokens.ColorPrimary,
            cursor: "pointer",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: props.tokens.RadiusPrimary,
              // borderRadius: "20px",
              backgroundImage:
                "radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.8))",
            }}
          >
            <img
              alt=""
              key={uniqid.time()}
              style={{
                width: "80px",
                height: "80px",
              }}
              src={props.icon}
            />
          </div>
        </div>
        <Spacer direction="vertical" size={tokens.PrimaryPadding} />
        <Container
          flex="1"
          width="100%"
          direction="horizontal"
          horizontalAlign="center"
          verticalAlign="center"
          bodyType="neutral"
          margin="0px"
          padding="3px"
        >
          <span
            style={{
              textAlign: "center",
              fontFamily: props.tokens.FontBodyPrimaryFamily,
              fontSize: props.tokens.FontBodyPrimarySize,
              fontWeight: props.tokens.FontBodyPrimaryWeight,
              color: Color(props.tokens.ColorWhite).alpha(0.9),
              cursor: "pointer",
              textOverflow: 'ellipsis',
              width: '80px',
              overflow: 'hidden'
            }}
          >
            <Tooltip
              placement="bottom"
              title={props.label}
            >
              {props.label}
            </Tooltip>
          </span>
        </Container>
      </Container>

      {props.isErrorModalOpen && (
        <ExclusionAlert
          exclusionText={
            'Um erro ocorreu ao requisitar as credenciais para o aplicativo.'
          }
          title='Alerta'
          onCancel={props.handleErrorModal}
          onlyConfirmation
        />
      )}
    </div>
  );

}

AppButtonPresentation.propTypes = {
  tokens: PropTypes.object,
  index: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.any,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  handleAuthorizationModal: PropTypes.func,
  handleErrorModal: PropTypes.func,
  loading: PropTypes.bool,
  isErrorModalOpen: PropTypes.bool,
};

AppButtonPresentation.defaultProps = {
  index: "",
  label: "AppButton",
  icon: null,
  active: false,
};

export default AppButtonPresentation;
