import React from "react";

const URLRenderer = ({ url }) => {
  return (
    <iframe
      src={url}
      title="Documentação da aplicação"
      style={{ width: "100%", height: "100%", border: "none" }}
    />
  );
};

export default URLRenderer;
