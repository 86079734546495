import React from "react";
import PropTypes from "prop-types";
import NotificationCard from "../NotificationCard";

import { Container, Spacer, Text, Icon, tokens, TextField } from "squid-ui-kit";

class UpdatesTabPresentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const isFirstPage = this.props.filterProps.page <= 1;
    const isLastPage = this.props.filterProps.page >= this.props.numberOfPages;

    return (
      <Container width="100%" flex="1" bodyType="neutral">
        <Spacer size={tokens.PrimaryPadding} direction="vertical" />
        <Container
          width="100%"
          padding={`0 ${tokens.PrimaryPadding}`}
          bodyType="neutral"
        >
          <TextField
            width="100%"
            placeholder="Buscar"
            searchButton
            icon={this.props.filterProps.searchText ? "close" : null}
            iconIsButton
            onIconClick={this.props.handleRefresh}
            onEnter={this.props.handleSearch}
            onSearch={this.props.handleSearch}
            value={this.props.searchBarValue}
            onChange={this.props.handleTypeSearch}
          />
        </Container>
        <Spacer size={tokens.PrimaryPadding} direction="vertical" />
        <div
          style={{
            width: "100%",
            flex: "1",
            boxSizing: "border-box",
            gap: tokens.SecondaryPadding,
            overflowX: "hidden",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            minHeight: "0",
            minWidth: "0",
            padding: `0 ${tokens.PrimaryPadding}`,
          }}
        >
          {this.props.content.length === 0 ? (
            <Container
              flex="1"
              verticalAlign="center"
              bodyType="neutral"
              padding={tokens.PrimaryPadding}
              horizontalAlign="center"
              width="100%"
            >
              <Icon
                icon={this.props.filterProps.searchText ? "preview" : "close"}
                size="large"
              />
              <Text textAlign="center" size="small" width="80%">
                Não existem atualizações{" "}
                {this.props.filterProps.searchText
                  ? "correspondentes aos parâmetros fornecidos"
                  : ""}
              </Text>
            </Container>
          ) : (
            this.props.content.map((notification) => {
              return (
                <NotificationCard
                  key={notification.notificationId}
                  notificationId={notification.notificationId}
                  isRead={notification.read}
                  width={"100%"}
                  date={this.props.parseDate(notification.createdAt)}
                  title={notification.appOwner}
                  subject={notification.subject}
                  message={
                    notification.plaintext ||
                    "Não foi possível ler o conteúdo da mensagem"
                  }
                  html={notification.html}
                />
              );
            })
          )}
          <Spacer size={tokens.PrimaryPadding} direction="vertical" />
        </div>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: tokens.ColorWhite,
            opacity: "0.05",
          }}
          onClick={this.props.handlePreviousPage}
        />
        <Container
          width="100%"
          padding={`${tokens.PrimaryPadding} ${tokens.SecondaryPadding}`}
          bodyType="primary"
          horizontalAlign="center"
        >
          <Container
            width="100%"
            horizontalAlign="center"
            verticalAlign="center"
            direction="horizontal"
            bodyType="neutral"
          >
            <TextField
              width="120px"
              size="small"
              textAlign="center"
              subtitle="itens/página"
              onEnter={this.props.handleChangePageSize}
              value={this.props.pageSizeBarValue}
              onChange={this.props.handleTypePageSize}
            />
            <Spacer size={tokens.SecondaryPadding} direction="horizontal" />
            <div
              style={{
                position: "relative",
                paddingLeft: "5px",
                boxSizing: "border-box",
                opacity: isFirstPage ? "0.3" : "1",
                cursor: isFirstPage ? "default" : "pointer",
              }}
              onClick={this.props.handleFirstPage}
            >
              <Icon icon="chevron-left" size="small" />
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
              >
                <Icon icon="chevron-left" size="small" />
              </div>
            </div>

            <div
              style={{
                opacity: isFirstPage ? "0.3" : "1",
                cursor: isFirstPage ? "default" : "pointer",
              }}
              onClick={this.props.handlePreviousPage}
            >
              <Icon icon="chevron-left" size="small" />
            </div>
            <Spacer size={tokens.SecondaryPadding} direction="horizontal" />
            <Text size="small">
              página <b>{this.props.filterProps.page}</b> de{" "}
              <b>{this.props.numberOfPages ?? "-"}</b>
            </Text>
            <Spacer size={tokens.SecondaryPadding} direction="horizontal" />
            <div
              style={{
                opacity: isLastPage ? "0.3" : "1",
                cursor: isLastPage ? "default" : "pointer",
              }}
              onClick={this.props.handleNextPage}
            >
              <Icon icon="chevron-right" size="small" />
            </div>
            <div
              style={{
                position: "relative",
                paddingRight: "5px",
                boxSizing: "border-box",
                opacity: isLastPage ? "0.3" : "1",
                cursor: isLastPage ? "default" : "pointer",
              }}
              onClick={this.props.handleLastPage}
            >
              <Icon icon="chevron-right" size="small" />
              <div
                style={{
                  position: "absolute",
                  transform: "translateX(30%)",
                  top: "0",
                  left: "0",
                }}
              >
                <Icon icon="chevron-right" size="small" />
              </div>
            </div>
          </Container>
        </Container>
      </Container>
    );
  }
}

UpdatesTabPresentation.propTypes = {
  content: PropTypes.array,
  parseDate: PropTypes.func,
  searchBarValue: PropTypes.string,
  handleSearch: PropTypes.func,
  handleTypeSearch: PropTypes.func,
  handleRefresh: PropTypes.func,
  handleNextPage: PropTypes.func,
  handlePreviousPage: PropTypes.func,
  numberOfPages: PropTypes.number,
  filterProps: PropTypes.object,
  handleFirstPage: PropTypes.func,
  handleLastPage: PropTypes.func,
  handleChangePageSize: PropTypes.func,
  pageSizeBarValue: PropTypes.string,
  handleTypePageSize: PropTypes.func,
};

UpdatesTabPresentation.defaultProps = {};

export default UpdatesTabPresentation;
