import React, { useEffect, useState, useCallback } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { Provider, tokens as tks } from "squid-ui-kit";
import { loginRequest } from "./config/AuthConfig";
import Login from "./modules/Login";
import Application from "./modules/Application";
import CoreBackendApi from "./config/CoreBackendApi";
import LocalApi from "./config/LocalApi";
import ConfigApi from "./config/SquidAuth";
import { useAppContext } from "./contexts/app";
import "./App.css";

const App = () => {
  const { getToken, setToken, getLocalApps, ready, setReady } = useAppContext();
  const [logged, setLogged] = useState(false);
  const [tokens, setTokens] = useState(tks);
  const [showExpirationAlert, setShowExpirationAlert] = useState(false);
  const [bgImage, setBgImage] = useState("");
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);

  // Login and logout events
  const onLogout = () => {
    LocalApi.clear();
    setLogged(false);
  };
  const onLogged = useCallback(
    (token) => {
      setLogged(true);
      setToken(token);
    },
    [setToken]
  );

  // Microsoft authentication
  const requestAccessToken = useCallback(async () => {
    if (isAuthenticated && instance && accounts) {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      setAccessToken(response.accessToken);
    }
  }, [isAuthenticated, instance, accounts, setAccessToken]);

  useEffect(() => {
    requestAccessToken();
  }, [isAuthenticated, instance, accounts, requestAccessToken]);

  useEffect(() => {
    if (accessToken) {
      ConfigApi.loginMicrosoftSso(
        accessToken,
        window.SQUID_PORTAL_ID,
        (data) => {
          CoreBackendApi.fetchMe(data.token).then((me) => {
            LocalApi.setUser(JSON.stringify(me));
            onLogged(data.token);
          });
        }
      );
    }
  }, [accessToken, onLogged]);

  useEffect(() => {
    const timeoutId = setInterval(() => {
      requestAccessToken();
    }, 10 * 60 * 1000);
    return () => clearInterval(timeoutId);
  }, [requestAccessToken]);

  // Theme tokens
  useEffect(() => {
    const timeoutId = setInterval(() => {
      if (window.tokens) {
        clearInterval(timeoutId);
        setTokens(window.tokens);
        setBgImage(`url('${window.tokens.BrandName.toLowerCase()}-bg.png')`);
        setReady(true);
      }
    }, 100);
    return () => clearInterval(timeoutId);
  });

  // Session expired
  useEffect(() => {
    if (LocalApi.getPortalSessionExpired() === "true") {
      LocalApi.clearPortalSessionExpired();
      setShowExpirationAlert(true);
    }
  }, [setShowExpirationAlert]);

  const token = getToken();
  let content;
  if (ready === true) {
    if (logged === true || token) {
      content = (
        <Application
          localApps={getLocalApps()}
          token={token}
          tokens={tokens}
          onLogout={() => {
            onLogout();
            setShowExpirationAlert(false);
          }}
          onExpiration={() => {
            onLogout();
            setShowExpirationAlert(true);
          }}
        />
      );
    } else {
      content = (
        <Login
          tokens={tokens}
          onLogged={onLogged}
          showExpirationAlert={showExpirationAlert}
          onCloseExpirationAlert={() => setShowExpirationAlert(false)}
        />
      );
    }
  }

  return (
    <div
      className="App"
      style={{
        position: "absolute",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        backgroundImage: bgImage,
        backgroundSize: "cover",
      }}
    >
      <Provider theme={tokens}>{content}</Provider>
    </div>
  );
};

export default App;
