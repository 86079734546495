import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import Color from "color";
import debounce from "lodash/debounce";

import { Panel, Text, TextField, Container } from "squid-ui-kit";
import CoreBackendApi from "../../../config/CoreBackendApi";
import configApi from "../../../config/SquidAuth";
import UserInfoCard from "./UserInfoCard";

const SearchUserForm = ({
  title,
  tokens,
  searchTerm,
  onClose,
  onUsersListUpdated,
}) => {
  const queryRef = useRef();
  const [query, setQuery] = useState(searchTerm);
  const [suggestionsData, setSuggestionsData] = useState(undefined);
  const [memberGroupId, setMemberGroupId] = useState(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(
    debounce((q) => {
      configApi.searchUserByQuery(q, setSuggestionsData);
    }, 500),
    []
  );

  // Query update
  const onKeyChange = (e) => {
    const q = e.value;
    if (q && q.length > 2) {
      debouncedHandleSearch(q);
    } else {
      setSuggestionsData(undefined);
    }
    setQuery(q);
  };

  // Add user callback
  const addUser = (petrobrasKey, successCb) => {
    const fail = (data) => console.log(data);
    const userCreatedFn = (data) => {
      // Add user to the member group
      const userId = data.users.create.id;
      CoreBackendApi.joinGroupUser(userId, memberGroupId)
        .then(successCb)
        .then(onUsersListUpdated);
    };
    const userFound = (user) => {
      // Add user
      const { caUserData } = user;
      CoreBackendApi.addUser(
        {
          petrobrasKey,
          name: caUserData.name,
          email: caUserData.email,
        },
        fail
      ).then(userCreatedFn);
    };
    if (petrobrasKey) {
      configApi.findUserInfoByPetrobrasKey(petrobrasKey, userFound);
    }
  };

  // User suggestions
  const mountSugestions = (data) => {
    const maxItens = 10;
    if (data?.allSuggestions && data.allSuggestions.length > 0) {
      const suggestions = data.allSuggestions
        .slice(0, maxItens)
        .map((petrobrasKey) => (
          <UserInfoCard
            key={`search-user-key-${petrobrasKey}`}
            petrobrasKey={petrobrasKey}
            addFn={addUser}
          />
        ));
      return (
        <Container width="100%" bodyType="content">
          {suggestions}
        </Container>
      );
    }
    if (query) {
      return (
        <Container
          width="100%"
          height="90%"
          bodyType="neutral"
          verticalAlign="center"
          horizontalAlign="center"
        >
          <Text size="large">
            Nenhum usuário encontrado para o termo de busca informado.
          </Text>
        </Container>
      );
    }
    return <></>;
  };

  // Member group id
  useEffect(() => {
    CoreBackendApi.listGroups().then((groups) => {
      const memberGroup = groups.find((el) => el.name === "Member");
      if (memberGroup) {
        setMemberGroupId(memberGroup.id);
      }
    });
    if (searchTerm && searchTerm !== "") {
      debouncedHandleSearch(searchTerm);
    }
  }, [debouncedHandleSearch, searchTerm]);

  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        backgroundColor: Color(tokens.ColorBlack).alpha(0.3),
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        zIndex: 20,
      }}
    >
      <Panel
        label={title}
        direction="vertical"
        verticalAlign="top"
        horizontalAlign="left"
        bodyType="primary"
        width="500px"
        height="100%"
        closeButton={true}
        padding="10px"
        space="10px"
        onClose={onClose}
      >
        <TextField
          ref={queryRef}
          width="100%"
          placeholder="Login (chave Petrobras) ou nome"
          value={query}
          searchButton={true}
          readOnly={false}
          onChange={onKeyChange}
          onSearch={onKeyChange}
          onEnter={onKeyChange}
        />
        {mountSugestions(suggestionsData)}
      </Panel>
    </div>
  );
};

SearchUserForm.propTypes = {
  title: PropTypes.string,
  tokens: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onUsersListUpdated: PropTypes.func.isRequired,
};

SearchUserForm.defaultProps = {
  title: "Buscar usuários",
};

export default SearchUserForm;
