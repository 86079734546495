import React from "react";
import PropTypes from "prop-types";

import ConfigPresentation from "./Config.presentation";

import ApplicationManagement from "../ApplicationManagement";
import ApplicationRolesContainer from "../ApplicationRoles";
import PortalUsers from "../PortalUsers";
import PortalApps from "../PortalApps";

import applicationImage from "../../assets/gerentesdeaplicacao.svg";
import rolesImage from "../../assets/gestaodeaplicacao.svg";
import userConfigImage from "../../assets/user-config.svg";
import questionImage from "../../assets/application.svg";
import openBook from "../../assets/open-book.png";
import { Tooltip } from "squid-ui-kit";
import ApiDocumentationCentral from "../ApiDocumentationCentral";
import EmailPage from "../EmailPage";

const mountCategories = (props) => {
  const applications = {
    key: "applications",
    name: "",
    icon: (
      <Tooltip title="Gestores de aplicações e autorizações" placement="right">
        <img
          style={{
            width: "18px",
            height: "18px",
            filter: "invert(1)",
          }}
          src={applicationImage}
          alt="managers icon"
        />
      </Tooltip>
    ),
    component: <ApplicationManagement isAdmin={props.isAdmin} />,
  };
  const roles = {
    key: "roles",
    name: "",
    icon: (
      <Tooltip
        title="Aplicações que gerencio e perfis de usuário"
        placement="right"
      >
        <img
          style={{
            width: "18px",
            height: "18px",
            filter: "invert(1)",
          }}
          src={rolesImage}
          alt="roles icon"
        />
      </Tooltip>
    ),
    component: <ApplicationRolesContainer isAdmin={props.isAdmin} />,
  };
  const users = {
    key: "users",
    name: "",
    icon: (
      <Tooltip title="Usuários do portal" placement="right">
        <img
          style={{
            width: "18px",
            height: "18px",
          }}
          src={userConfigImage}
          alt="users icon"
        />
      </Tooltip>
    ),
    component: <PortalUsers isAdmin={props.isAdmin} tokens={props.tokens} />,
  };
  const apps = {
    key: "apps",
    name: "",
    icon: (
      <Tooltip title="Aplicações do portal" placement="right">
        <img
          style={{
            width: "20px",
            height: "20px",
            filter: "invert(1)",
          }}
          src={questionImage}
          alt="apps icon"
        />
      </Tooltip>
    ),
    component: (
      <PortalApps
        isAdmin={props.isAdmin}
        tokens={props.tokens}
        onUpdateApps={() => window.location.reload()}
      />
    ),
  };

  const apiDocumentation = {
    key: "apiDocumentation",
    name: "",
    icon: (
      <Tooltip title="Central de documentação de APIs" placement="right">
        <img
          style={{
            width: "20px",
            height: "20px",
            filter: "invert(1)",
          }}
          src={openBook}
          alt="api documentation"
        />
      </Tooltip>
    ),
    component: <ApiDocumentationCentral />,
  };

  const email = {
    key: "email",
    name: "",
    icon: (
      <Tooltip title="Comunicação por email" placement="right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="18"
          viewBox="0 0 18 18"
          width="18"
          fill="white"
        >
          <defs></defs>
          <title>S Email 18 N</title>
          <rect id="Canvas" fill="#ff13dc" opacity="0" width="18" height="18" />
          <path d="M9,10.094l9-6.7615v-.75A.5735.5735,0,0,0,17.4375,2H.5625A.57351.57351,0,0,0,0,2.5835V3.318Z" />
          <polygon points="12.389 8.981 18 13.184 18 6.667 18 4.771 12.389 8.981" />
          <path d="M11.433,9.7,9.645,11.047a1.086,1.086,0,0,1-1.29,0L6.541,9.6875,0,14.534v.883A.573.573,0,0,0,.5625,16h16.875A.573.573,0,0,0,18,15.417v-.795Z" />
          <polygon points="5.582 8.969 0 4.756 0 6.667 0 13.097 5.582 8.969" />
        </svg>
      </Tooltip>
    ),
    component: <EmailPage />,
  };

  let categories = [applications, roles, apps];
  if (props.isAdmin) {
    categories.push(users);
    categories.push(email);
  }
  if (window.NSW_ENV === "local" || window.NSW_ENV === "dev") {
    categories.push(apiDocumentation);
  }
  return categories;
};

class ConfigContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: mountCategories(props),
      selectedCategory: mountCategories(props)[0],
      applicationsList: {
        applications: <ApplicationManagement isAdmin={this.props.isAdmin} />,
      },
    };

    this.onChangeCategory = (selected) => {
      const category = this.state.categories.find(
        (cat) => cat.key === selected.selectedKey
      );
      this.setState({
        selectedCategory: category,
      });
    };
  }

  render() {
    return (
      <ConfigPresentation
        isAdmin={this.props.isAdmin}
        categories={this.state.categories}
        selectedCategory={this.state.selectedCategory}
        onChangeCategory={this.onChangeCategory}
      />
    );
  }
}

ConfigContainer.propTypes = {
  isAdmin: PropTypes.bool,
  onUpdateApps: PropTypes.func,
};

ConfigContainer.defaultProps = {
  isAdmin: false,
};

export default ConfigContainer;
