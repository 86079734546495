import React from "react";
import PropTypes from "prop-types";
import notificationFillIcon from "../../../assets/notification-fill-icon.svg";
import Tabs from "./components/Tabs";
import NotificationsTab from "./components/NotificationsTab";
import UpdatesTab from "./components/UpdatesTab";
import LoadingDots from "../../../components/LoadingDots";

import { Container, Title, tokens, Spacer } from "squid-ui-kit";

class NotificationsPresentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const readNotificationColor = "rgba(0,0,0,0.15)";
    const unreadNotificationColor = "#EB4748";

    return (
      <Container width="100%" flex="1" borderType="primary">
        <Tabs
          width="100%"
          flex="1"
          onSelectTab={this.props.handleClickTab}
          selectedTab={this.props.selectedTab}
          renderTabsSimultaneously
          tabs={[
            {
              key: this.props.tabs.UPDATES,
              label: (
                <Title bold level={5}>
                  ATUALIZAÇÕES
                </Title>
              ),
              render: <UpdatesTab />,
            },
            {
              key: this.props.tabs.NOTIFICATIONS,
              label: (
                <>
                  <Title bold level={5}>
                    NOTIFICAÇÕES
                  </Title>
                  <Spacer size="2px" />
                  <div
                    style={{
                      width: "fit-content",
                      height: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                      transition: "all 0.2s",
                    }}
                  >
                    <img
                      src={notificationFillIcon}
                      alt={"notification-icon"}
                      style={{ opacity: "0.7" }}
                    />
                    {(this.props.loadingCount ||
                      this.props.unreadCount > 0) && (
                      <div
                        style={{
                          position: "absolute",
                          borderRadius: "100px",
                          backgroundColor: unreadNotificationColor,
                          boxSizing: "border-box",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "1px 4px 1px 4px",
                          top: "0px",
                          left: "9px",
                          boxShadow: "1px 1px 2px rgba(0,0,0,.2)",
                        }}
                      >
                        {this.props.loadingCount ? (
                          <LoadingDots
                            dotJump="2px"
                            wrapperStyle={{
                              height: tokens.FontTitlePrimarySize,
                            }}
                            dotColor={tokens.ColorWhite}
                            dotSize="2px"
                            dotSpace="1px"
                          />
                        ) : (
                          <Title size="small" level={6}>
                            {this.props.unreadCount > 99
                              ? "99+"
                              : this.props.unreadCount}
                          </Title>
                        )}
                      </div>
                    )}
                  </div>
                </>
              ),
              render: (
                <NotificationsTab
                  appSlugToName={this.props.appSlugToName}
                  readNotificationColor={readNotificationColor}
                  unreadNotificationColor={unreadNotificationColor}
                  getNotificationsCounts={this.props.getNotificationsCounts}
                />
              ),
            },
          ]}
        />
      </Container>
    );
  }
}

NotificationsPresentation.propTypes = {
  selectedTab: PropTypes.string,
  appSlugToName: PropTypes.object,
  unreadCount: PropTypes.number,
  getNotificationsCounts: PropTypes.func,
  loadingCount: PropTypes.bool,
};

export default NotificationsPresentation;
