import React, { useState, useEffect } from "react";

import RolesUsersTable from "./rolesUsersTable.component";
import ConfigApi from "../../../../config/SquidAuth";

function RolesUsersTableContainer({
  usersByRoleList,

  loading,

  applicationID,
  onSelectRow,

  selectedRoleRow,
  onUpdateQuery,

  updateUserByRolesList,

  isAdmin,
}) {
  const [loadingList, setLoadingList] = useState(undefined);
  const [loadingAttach, setLoadingAttach] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [rowToDelete, setRowToDelete] = useState(undefined);
  const [showAddManagerModal, setShowAddManagerModal] = useState(false);

  const [page, setPage] = useState(0);

  let recordsList = [];
  if (usersByRoleList) {
    recordsList = usersByRoleList;
  }

  const pageSize = 20;
  let preTotalPages = recordsList.length / pageSize;
  let totalOfPages =
    preTotalPages.toString().split(".")[1] === undefined
      ? preTotalPages
      : Math.trunc(preTotalPages) + 1;
  let numRecords = recordsList.length;

  recordsList = recordsList.slice(pageSize * page, pageSize * page + pageSize);

  const deleteRow = (petrKey) => {
    if (rowToDelete) {
      setRowToDelete(undefined);
    } else {
      setRowToDelete(petrKey);
    }
  };

  const confirmDeletion = async () => {
    try {
      setLoadingList(true);

      const payload = {
        roleId: selectedRoleRow?.id,
        userLogin: rowToDelete,
      };

      await ConfigApi.revokeUserRole(applicationID, payload, setError);

      await updateUserByRolesList();
    } catch (err) {
      setLoadingList(false);
    } finally {
      setRowToDelete(undefined);
      setLoadingList(false);
    }
  };

  const handleError = () => {
    setError(undefined);
  };

  const onShowAddManagerModal = () => {
    if (selectedRoleRow) {
      setShowAddManagerModal(!showAddManagerModal);
    }
  };

  const onAttachUser = async (userKey, sucess, fail) => {
    try {
      setLoadingAttach(true);

      const payload = {
        roleId: selectedRoleRow?.id,
        userLogin: userKey,
      };

      await ConfigApi.grantUserRole(applicationID, payload, sucess, fail);

      await updateUserByRolesList();
    } catch (err) {
      setLoadingAttach(false);
    } finally {
      setLoadingAttach(false);
    }
  };

  useEffect(() => {
    if (totalOfPages === 0) {
      setPage(0);
    }
    if (page >= totalOfPages) {
      setPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    recordsList = recordsList.slice(
      pageSize * page,
      pageSize * page + pageSize
    );
  }, [page, totalOfPages]);

  return (
    <RolesUsersTable
      usersByRoleList={recordsList}
      loading={loading || loadingList}
      loadingAttach={loadingAttach}
      rowToDelete={rowToDelete}
      deleteCallback={deleteRow}
      confirmDeletion={confirmDeletion}
      onSelectRow={onSelectRow}
      isAdmin={isAdmin}
      selectedRoleRow={selectedRoleRow}
      onUpdateQuery={onUpdateQuery}
      showAddManagerModal={showAddManagerModal}
      onShowAddManagerModal={onShowAddManagerModal}
      onAttachUser={onAttachUser}
      error={error}
      handleError={handleError}
      page={page}
      pageSize={pageSize}
      setPage={setPage}
      totalOfPages={totalOfPages}
      numRecords={numRecords}
    />
  );
}

export default RolesUsersTableContainer;
