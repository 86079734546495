import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Color from "color";
import {
  Alert,
  Button,
  Container,
  Dropdown,
  Icon,
  IconButton,
  InfoItem,
  ListItem,
  Panel,
  Spacer,
  TextArea,
  TextField,
  Tooltip,
} from "squid-ui-kit";
import Divider from "squid-ui-kit/dist/Divider";

import AppButton from "../../AppButton";

const booleanFromString = (value, defaultValue) =>{
  if(value === "true"){
    return true;
  }
  if(value === "false"){
    return false;
  }
  return defaultValue;
}

const WindowFormApplicationComponent = ({
  tokens,
  application,
  onClose,
  onCreate,
  onUpdate,
  onRemove,
  apiError,
  setApiError,
}) => {

  const inputIconRef = useRef();
  const id = application?.id;
  const [name, setName] = useState(application?.name ?? "");
  const [description, setDescription] = useState(application?.description ?? "");
  const [url, setUrl] = useState(application?.url ?? "");
  const [slug, setSlug] = useState(application?.slug ?? "");
  const [typeId, setTypeId] = useState(application?.type.id ?? "2");
  const [icon, setIcon] = useState(application?.icon);
  const [launchModeCode, setLaunchModeCode] = useState(application?.launchMode.code ?? "INSIDE_PORTAL");
  const [visible, setVisible] = useState(application?.visible ?? true);
  const [confirmRemove, setConfirmRemove] = useState(null);

  const [urlPlaceholder, setUrlPlaceHolder] = useState("Exemplo: /app/xpto/");
  const [slugPlaceholder, setSlugPlaceHolder] = useState("Exemplo: sbwb-app-xpto");

  const [errorIcon, setErrorIcon] = useState(undefined);
  const [errorName, setErrorName] = useState(undefined);
  const [errorDescription, setErrorDescription] = useState(undefined);
  const [errorTypeId, setErrorTypeId] = useState(undefined);
  const [errorUrl, setErrorUrl] = useState(undefined);
  const [errorLaunchModeCode, setErrorLaunchModeCode] = useState(undefined);

  const supportedIconFormats = ".png, .jpg, .jpeg";

  const hasApp = () => application;
  const onDescriptionsChange = (e) => setDescription(e.value);
  const onUrlChange = (e) => setUrl(e.value);
  const onIconAdd = (e) => inputIconRef.current.click();
  const onLaunchModeCodeChange = (e) => setLaunchModeCode(e.value);
  const onVisibleChange = (e) => setVisible(booleanFromString(e.value, true));
  const onRemoveCb = () => setConfirmRemove(true);  
  const cleanApiErrors = () => setApiError(undefined);
  const onSlugChange = (e) => {
    setSlug(e.value);
    cleanApiErrors();
  }
  
  // Regex para substituir whitespace (" ") de forma global
  const regex = /\s/g; 
  const updateUrlPlaceholder = (tId, nm) => {
    setUrlPlaceHolder(`Exemplo: /${
      tId === "1"
      ? "basicdata"
      : "app"
    }/${nm.toLowerCase().replace(regex, "-")}/`)
  };
  const updateSlugPlaceholder = (tId, nm) => {
    setSlugPlaceHolder(`Exemplo: sbwb-${
      tId === "1"
      ? "basicdata"
      : "app"
    }-${nm.toLowerCase().replace(regex, "-")}`)
  };
  
  const onNameChange = (e) => {
    setName(e.value);
    let nm = 'xpto';
    if(e.value && e.value.length>0){
      nm=e.value
    }
    updateUrlPlaceholder(typeId, nm);
    updateSlugPlaceholder(typeId, nm);
  }

  const onTypeIdChange = (e) =>{
    setTypeId(e.value);
    updateUrlPlaceholder(e.value, name);
    updateSlugPlaceholder(e.value, name);
  }

  const onIconRemove = () => {
    inputIconRef.current.value = "";
    setIcon(null);
  }

  const onIconChange = (e) => {
    const file = e.target?.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      setIcon(content);
    }
    reader.readAsDataURL(file);
  }

  const validate = () => {
    let hasError = false;
    if (!icon) {
      setErrorIcon("É necessário escolher um ícone");
      hasError = true;
    }
    if (!name || name === "") {
      setErrorName("É necessário informar o nome");
      hasError = true;
    }
    if (!description || description === "") {
      setErrorDescription("É necessário informar a descrição");
      hasError = true;
    }
    if (!typeId || typeId === "") {
      setErrorTypeId("É necessário selecionar um tipo de aplicação");
      hasError = true;
    }
    if (!url || url === "") {
      setErrorUrl("É necessário informar a URL local");
      hasError = true;
    }
    if (!launchModeCode || launchModeCode === "") {
      setErrorLaunchModeCode("É necessário selecionar a forma de lançamento no portal");
      hasError = true;
    }   
    return !hasError;
  }

  const onCreateCb = (e) => {
    if (!validate()) {
      return;
    }
    onCreate({
      icon,
      name,
      description,
      typeId,
      url,
      launchModeCode,
      visible,
      slug,
    })
  };

  const onUpdateCb = () => {
    if (!validate()) {
      return;
    }
    onUpdate({
      id,
      icon,
      name,
      description,
      typeId,
      url,
      launchModeCode,
      visible,
      slug,
    });
  }

  const confirmRemoveAlert = (
    <Alert
      hasBorder
      bodyType="primary"
      padding={"20px"}
      message={
        (
          <Container
            width="100%"
            height="100%"
            direction="vertical"
            verticalAlign="top"
            horizontalAlign="left"
            bodyType="neutral"
          >
            {`Deseja realmente remover ${application?.name || ""}?`}
          </Container>
        )
      }
      confirm
      cancelLabel="Cancelar"
      confirmLabel="Sim"
      onCancel={() => setConfirmRemove(null)}
      onClose={() => setConfirmRemove(null)}
      onConfirm={() => {
        onRemove(application);
      }}
      title="Remover Aplicação"
    />
  );

  let footer = (
    <Container
      width="auto"
      bodyType="neutral"
      padding="0px"
      direction="horizontal"
      horizontalAlign="right"
      verticalAlign="center"
    >
      { hasApp() ? <Button label="REMOVER" type="tertiary" onClick={onRemoveCb} /> : undefined }
      <Spacer flex="1" />
      { apiError ? <Tooltip placement="top" title={apiError}><Icon size="large" icon="alert" /></Tooltip>: undefined}
      { confirmRemove ? confirmRemoveAlert : undefined}
      <Spacer size="20px" />
      <Button
        label={ !hasApp() ? "CRIAR" : "SALVAR"}
        type="cta"
        onClick={ !hasApp() ? onCreateCb : onUpdateCb }
      />
    </Container>
  );

  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        backgroundColor: Color(tokens.ColorBlack).alpha(0.3),
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        zIndex: 20,
      }}
    >
      <Panel
        label={`${
          !hasApp() ? "Adicionar nova" : "Editar"
        } aplicação`}
        direction="vertical"
        verticalAlign="top"
        horizontalAlign="left"
        bodyType="primary"
        width="500px"
        height="100%"
        footer={footer}
        closeButton={true}
        padding="10px"
        space="10px"
        onClose={onClose}
      >
        <input
          id="inputIcon"
          ref={inputIconRef}
          type="file"
          style={{
            display: "none",
          }}
          onChange={onIconChange}
          accept={supportedIconFormats}
        />
        <Panel
          label="Ícone"
          width="100%"
          padding="20px"
          verticalAlign="center"
          horizontalAlign="center"
          direction="horizontal"
        >
          <Container
            bodyType="neutral"
            verticalAlign="center"
            horizontalAlign="center"
            width="100%"
            flex="1"
          >
            { !icon
              ? (<Container
                // bodyType="neutral"
                verticalAlign="center"
                horizontalAlign="center"
                width="150px"
                height="150px"
              >
                  <IconButton
                    name="addIconButton"
                    onClick={onIconAdd}
                  />
                </Container>
                )
              : (<>
                  <img
                    alt=""
                    src={icon}
                    style={{
                      width: "150px",
                      height: undefined,
                      aspectRatio: 1,
                    }}
                  />
                  <IconButton
                    icon="trash"
                    name="removeIconButton"
                    onClick={onIconRemove}
                  />
                </>)
            }
            { errorIcon
              ? (<Tooltip placement="bottom" title={errorIcon}>
                  <Icon icon="info" color="red" />
                </Tooltip>)
              : undefined
            }
          </Container>
          <Divider direction="vertical" />
          <Container
            padding="15px"
            bodyType="neutral"
          >
            <InfoItem
              label="Formatos suportados"
              value={supportedIconFormats}
            />
            <Spacer direction="vertical" size="10px" />
            <InfoItem
              label="Proporção ideal"
              value="1:1"
            />
            <Spacer direction="vertical" size="10px" />
            <InfoItem
              label="Tamanho mínimo"
              value="80x80 (px)"
            />
          </Container>
        </Panel>
        <Divider />
        <TextField
          width="100%"
          label="Nome"
          value={name}
          error={errorName}
          onChange={onNameChange}
        />
        <TextArea
          width="100%"
          label="Descrição"
          value={description}
          error={errorDescription}
          onChange={onDescriptionsChange}
        />
        <Dropdown
          width="100%"
          label="Tipo"
          selectedKey={typeId.toString()}
          value={typeId.toString()}
          error={errorTypeId}
          onChange={onTypeIdChange}
        >
          <ListItem
            key="2"
            title="Aplicação"
          />
          <ListItem
            key="1"
            title="Dado básico"
          />
        </Dropdown>
        <TextField
          width="100%"
          label="URL"
          placeholder={urlPlaceholder}
          value={url}
          error={errorUrl}
          onChange={onUrlChange}
        />
        <Container bodyType="neutral" width="100%" direction="horizontal">
          <Dropdown
            flex="1"
            label="Modo de lançamento"
            selectedKey={launchModeCode}
            value={launchModeCode}
            error={errorLaunchModeCode}
            onChange={onLaunchModeCodeChange}
          >
            <ListItem
              key="INSIDE_PORTAL"
              title="Dentro do portal"
            />
            <ListItem
              key="OUTSIDE_PORTAL"
              title="Fora do portal"
            />
          </Dropdown>
          <Spacer size="5px"/>
          <Dropdown
            name="app-visibility-dpd"
            flex="1"
            label="Visibilidade"
            selectedKey={visible ? "true": "false"}
            value={visible ? "true": "false"}
            onChange={onVisibleChange}
          >
            <ListItem
              key="true"
              title="Visível"
            />
            <ListItem
              key="false"
              title="Não visível"
            />
          </Dropdown>
        </Container>
        <TextField
          width="100%"
          label="Chave/Slug"
          placeholder={slugPlaceholder}
          value={slug}
          onChange={onSlugChange}
        />
        <Panel
          label="Preview"
          width="100%"
          padding="20px"
          verticalAlign="center"
          horizontalAlign="center"
        >
          <AppButton
            tokens={tokens}
            label={name}
            icon={icon}
          />
        </Panel>
      </Panel>
    </div>
  );
}


WindowFormApplicationComponent.propTypes = {
  tokens: PropTypes.object,
  application: PropTypes.object,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  apiError: PropTypes.string,
  setApiError: PropTypes.func,
};

WindowFormApplicationComponent.defaultProps = {
};

export default WindowFormApplicationComponent;
