import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./index.css";
import App from "./App";
import { AppContextProvider } from "./contexts/app";
import reportWebVitals from "./reportWebVitals";
import NotFound from "./modules/NotFound";

const SsoWrapper = React.lazy(()=> import('./components/SsoWrapper/SsoWrapper'));
const container = document.getElementById("root");
const root = createRoot(container);

let Wrapper = ({children}) => <>{children}</>
if (window.ENABLE_SSO && window.ENABLE_SSO.indexOf("true") > -1) {
  Wrapper = SsoWrapper;
}

root.render(
  <React.StrictMode>
    <AppContextProvider>
      <Wrapper>
        <Router>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/about" element={<div>About</div>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Wrapper>
    </AppContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
