import React from "react";
import PropTypes from "prop-types";

import TopBarPresentation from "./TopBar.presentation";
import LocalApi from "../../config/LocalApi";
import defaultAvatar from "../../assets/default-avatar.png";

class TopBarContainer extends React.Component {
  constructor(props) {
    super(props);

    let user = LocalApi.getUser();

    this.state = {
      avatar: user && user.avatar ? user.avatar : defaultAvatar,
    };
  }

  render() {
    return (
      <TopBarPresentation
        tokens={this.props.tokens}
        tabs={this.props.tabs}
        tab={this.props.tab}
        avatar={this.state.avatar}
        onTabChange={this.props.onTabChange}
        onTabClose={this.props.onTabClose}
        onHelp={this.props.onHelp}
        onConfig={this.props.onConfig}
        onLogout={this.props.onLogout}
        isAdmin={this.props.isAdmin}
      />
    );
  }
}

TopBarContainer.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  tab: PropTypes.string,
  onTabChange: PropTypes.func,
  onTabClose: PropTypes.func,
  onHelp: PropTypes.func,
  onConfig: PropTypes.func,
  onLogout: PropTypes.func,
};

TopBarContainer.defaultProps = {
  tabs: [],
  tab: "",
};

export default TopBarContainer;
