import React, { useState } from "react";

import AccessRequest from "./AccessRequest.component";
import subwebCoreApi from "../../../../config/SubwebCoreApi";
import { translateMessageFromEnglish } from "../../../../utils/translation";

function AccessRequestContainer({ onClose }) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(undefined);

  const onRequest = (userKey) => {
    if (userKey) {
      setLoading(true);
      subwebCoreApi.requestPortalAccess(
        userKey.toUpperCase(),
        (data) => {
          setErrorMessage(undefined);
          setSuccessMessage(translateMessageFromEnglish(data?.message))
        },
        (err) => {
          setErrorMessage(translateMessageFromEnglish(err?.response?.data?.message));
          setSuccessMessage(undefined);
        },
        () => setLoading(false)
      );
    }
  };

  return (
    <AccessRequest
      loading={loading}
      errorMessage={errorMessage}
      successMessage={successMessage}
      setErrorMessage={setErrorMessage}
      setSuccessMessage={setSuccessMessage}
      onClose={onClose}
      onRequest={onRequest}
    />
  );
}

export default AccessRequestContainer;
