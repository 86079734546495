export default function OpenMailIcon({
  color = "white",
  width = "14px",
  height = "14px",
  opacity = "1",
}) {
  return (
    <div style={{ width, height, color, opacity }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polyline
          fill="none"
          points="4 9 12 14 20 9"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <polyline
          fill="none"
          points="3 8.5 12 3.5 21 8.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M21,8.5v10a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V8.5"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
}
