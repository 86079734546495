import React from 'react';
import {
  Container,
  IconButton,
  Table,
  TableCell,
  Panel,
  TextField,
  Icon,
  Text,
  tokens,
} from "squid-ui-kit";

import { ExclusionAlert, AttachUserModal } from '../../../../components'
import { Pagination } from '../../components'

const cellRenderer = (item) => (
  <TableCell invertedColors verticalAlign="center" horizontalAlign="center" fontBodySize="small">
    {item}
  </TableCell>
);

const AuthorizationTable = ({ 
    authorizedUsers,
    loading, 
    loadingAttach,
    deleteCallback,
    confirmDeletion, 
    isAdmin, 
    commonUserAuthorizationRole, 
    onUpdateQuery,
    rowToDelete,
    showAddManagerModal,
    onShowAddManagerModal,
    onAttachUser,
    error,
    handleError,

    page,
    pageSize,
    setPage,
    totalOfPages,
    numRecords,
  }) => {

  const tableColumns = [
    {
      label: 'USUÁRIO',
      dataIndex: 'name',
      selected: false,
      flex: '1',
      cellRenderer,
    },
    {
      label: 'EMAIL',
      dataIndex: 'email',
      flex: '2',
      selected: false,
      cellRenderer,
    },
    {
      label: 'CHAVE',
      dataIndex: 'petrobrasKey',
      flex: '1',
      selected: false,
      cellRenderer,
    },
    {
      label: 'AÇÕES',
      dataIndex: 'petrobrasKey',
      selected: false,
      width: '100px',
      cellRenderer: (item) =>
        <TableCell invertedColors verticalAlign="center" horizontalAlign="center">
          <IconButton
            size="small"
            invertedColors
            icon="delete"
            onClick={() => deleteCallback(item)}
          />
        </TableCell>
    }
  ]

  return (
    <>
      <Panel
        width="100%"
        flex='1'
        bodyType="neutral"
        label='USUÁRIOS HABILITADOS'
        padding='5px 0px'
        addButton={!!commonUserAuthorizationRole}
        onAdd={onShowAddManagerModal}
      >
        <Container width="100%" bodyType="neutral" verticalAlign="center" padding="0px 0px 5px">
          <TextField
            width="100%"
            placeholder="Busque aqui por usuário ..."
            invertedColors
            searchButton
            size="small"
            onChange={onUpdateQuery}
            disabled={loading || commonUserAuthorizationRole === undefined}
          />
        </Container>

        {!authorizedUsers.length && !loading &&
          <Container verticalAlign="center" horizontalAlign="center" width="100%" flex='1' bodyType="neutral" direction="horizontal">
            <Icon icon="info" color={tokens.ColorPrimary}/>
            <Text invertedColors>Sem usuários autorizados..</Text>
          </Container>
        }

        {!!authorizedUsers.length && !loading &&
          <Container width="100%" flex='1' bodyType="primary">
            <Table
              invertedColors
              width="100%"
              height="100%"
              size="small"
              cellShowBorderBottom
              columns={isAdmin ? tableColumns : tableColumns.slice(0, tableColumns.length - 1)}
              dataSource={authorizedUsers}
            />
          </Container>
        }

        {loading && 
        <Container
          width="100%"
          flex='1'
          bodyType="primary"
          progressLabel="Carregando..."
          progress={-1}
        />
      }
      </ Panel>

      {!loading && (
            <Pagination
              width="100%"
              height="36px"
              totalOfPages={totalOfPages}
              page={page}
              pageSize={pageSize}
              onChange={setPage}
              invertedColors
              leftRender={() => (
                <Container direction="horizontal" bodyType="neutral" verticalAlign="center">
                  <Text invertedColors>
                    Registros:
                    {numRecords}
                  </Text>
                </Container>
              )}
            />
      )}

      {rowToDelete && (
          <ExclusionAlert 
            exclusionText=
              {`Tem certeza que você deseja remover este Usuário?`}
            title='Alerta'
            onCancel={() => deleteCallback(undefined)}
            onConfirm={() => confirmDeletion()}
          />
      )}

      {error && (
        <ExclusionAlert 
          exclusionText=
            {error?.response?.data?.message || error?.message || 'Um erro inesperado ocorreu, entre em contato com o administrador'}
          title='Alerta'
          onCancel={() => handleError()}
          onlyConfirmation
        />
      )}

      {showAddManagerModal && (
        <AttachUserModal
          onClose={onShowAddManagerModal}
          onSave={onAttachUser}
          title='Adicionar novo Usuário'
          loading={loadingAttach}
        />
      )}
    </>
  )
};

export default AuthorizationTable;
