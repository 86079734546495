import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Container,
  Spacer,
  TableCell,
  Text,
} from "squid-ui-kit";

import configApi from "../../../config/SquidAuth";
import { Avatar } from "../../../components";

const NewUserInfoCard = ({ petrobrasKey }) => {
  const [user, setUser] = useState(undefined);
  const [expand, setExpand] = useState(false);

  const loadUser = (pKey) =>
    configApi.findUserInfoByPetrobrasKey(pKey, setUser);

  useEffect(() => {
    loadUser(petrobrasKey);
  }, [petrobrasKey]);

  if (user) {
    const { caUserData } = user;
    return (
      <TableCell
        width="100%"
        height="65px"
      >
        <Container
          width="100%"
          height="100%"
          direction="horizontal"          
          bodyType="neutral"
          verticalAlign="center"
        >
          <Avatar src={caUserData.avatar} width="30px" height="30px" />
          <Spacer size="15px" />
          <Container
            flex="1"
            direction="vertical"
            verticalAlign="center"
            bodyType="neutral"
            onClick={() => setExpand(!expand)}
          >
            <Text bold>
              {caUserData.name}
            </Text>
            {expand && (
              <>
                <Text size="small">
                  Chave: {caUserData.petrobrasKey}
                </Text>
                <Text size="small">
                  {caUserData.departmentAcronym}
                </Text>
              </>
            )}
          </Container>
        </Container>
      </TableCell>
    );
  }
  return (
    <TableCell width="100%" height="65px">
      <Text size="small">
        Chave: {petrobrasKey}
      </Text>
    </TableCell>
  );
};

NewUserInfoCard.propTypes = {
  petrobrasKey: PropTypes.string,
};

export default NewUserInfoCard;
