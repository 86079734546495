import React, { useState } from "react";
import { Container, Alert, Text, Divider, Tooltip, Button } from "squid-ui-kit";
import SubwebCoreApi from "../../config/SubwebCoreApi";
import LocalApi from "../../config/LocalApi";
import alertImage from "../../assets/alert.svg";
import mailIcon from "../../assets/mail.svg";
import { translateMessageFromEnglish } from "../../utils/translation";


const UnauthorizedAlert = ({
  title,
  applicationId,
  onCancel,
  authorizationList = [],
  applicationName,
}) => {
  const [showRequestAccess, setShowRequestAccess] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(undefined);

  const successCb = (data) => {
    setShowRequestAccess(false);
    setMessage(translateMessageFromEnglish(data?.message));
    setLoading(false);
  };

  const onRequestAccess = () => {
    if (applicationId && applicationId !== "") {
      const petrobrasKey = LocalApi.getPetrobrasKeyFromToken();
      setShowRequestAccess(false);
      setLoading(true);
      SubwebCoreApi.requestApplicationAccess(
        applicationId,
        petrobrasKey,
        successCb
      );
    }
  };

  let action;
  if (showRequestAccess === true) {
    action = (
      <Button type="link" label="Solicitar acesso" onClick={onRequestAccess} />
    );
  }
  if (message) {
    action = <Text>{message}</Text>;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "15000",
        cursor: "auto",
      }}
    >
      <Alert
        bodyType="primary"
        padding="10px 20px"
        width="455px"
        confirm={false}
        cancelLabel="Cancelar"
        confirmLabel="Confirmar"
        onClose={() => onCancel()}
        title={title}
        message={
          <Container
            direction="vertical"
            verticalAlign="center"
            horizontalAlign="center"
            bodyType="neutral"
          >
            <Container
              direction="horizontal"
              verticalAlign="center"
              horizontalAlign="center"
              bodyType="neutral"
              padding="0px 0px 10px 0px"
            >
              <img src={alertImage} alt="alert" width="24px" height="24px" />
              <Divider direction="vertical" margin="10px" />
              <Container
                flex="1"
                height="100%"
                direction="vertical"
                verticalAlign="top"
                horizontalAlign="left"
                bodyType="neutral"
              >
                <Text width="100%" textAlign="center">
                  Você não está habilitado para acessar essa aplicação!
                </Text>
                <Text width="100%" textAlign="center">
                  Entre em contato com um dos Gestores para solicitar o acesso.
                </Text>
              </Container>
            </Container>

            {authorizationList.length > 0 && (
              <Container
                direction="horizontal"
                bodyType="neutral"
                verticalAlign="center"
                horizontalAlign="center"
                padding="4px 0px 4px 0px"
              >
                <Container
                  width="120px"
                  height="2px"
                  direction="horizontal"
                  bodyType="active"
                />
                <Text
                  width="100%"
                  textAlign="center"
                  size="small"
                  padding="0px 8px 0px 8px"
                >
                  GESTORES DA APLICAÇÃO
                </Text>
                <Container
                  width="120px"
                  height="2px"
                  direction="horizontal"
                  bodyType="active"
                />
              </Container>
            )}

            <Container
              direction="vertical"
              width="100%"
              bodyType="neutral"
              scroll="vertical"
            >
              {authorizationList.length > 0 &&
                authorizationList.map((authorization) => {
                  const hrefMounted = `mailto:${authorization.email}
                            ?subject=Solicitação de acesso ao aplicativo - '${applicationName}'
                            &body=Solicito o acesso à aplicação '${applicationName}' no portal SUBWEB para o usuário << Coloque aqui seu usuário >>. `;
                  return (
                    <Container
                      width="100%"
                      direction="horizontal"
                      verticalAlign="center"
                      horizontalAlign="center"
                      bodyType="neutral"
                      space="2px"
                      key={authorization.email}
                    >
                      <Text
                        width="100%"
                        textAlign="center"
                        padding="0px 0px 0px 0px"
                      >
                        {authorization.name}
                      </Text>
                      <Tooltip
                        placement="bottom"
                        title="Enviar e-mail de solicitação de acesso"
                      >
                        <a href={hrefMounted}>
                          <img
                            src={mailIcon}
                            alt="alert"
                            width="20px"
                            height="20px"
                          />
                        </a>
                      </Tooltip>
                    </Container>
                  );
                })}
              <>
                <Container
                  direction="horizontal"
                  bodyType="neutral"
                  verticalAlign="center"
                  horizontalAlign="center"
                  padding="4px 0px 4px 0px"
                >
                  <Container
                    width="410px"
                    height="2px"
                    direction="horizontal"
                    bodyType="active"
                  />
                </Container>
                <Container
                  direction="vertical"
                  width="100%"
                  height="30px"
                  bodyType="neutral"
                  scroll="vertical"
                  horizontalAlign="center"
                  progress={(loading)? -1 : undefined }
                  padding="4px 0px 4px 0px"
                >
                  {action}
                </Container>
              </>
            </Container>
          </Container>
        }
      />
    </div>
  );
};

export default UnauthorizedAlert;
