import React, { useEffect, useState } from 'react';

import {
  Container,
  Text,
  Tooltip
} from "squid-ui-kit";

import LocalApi from '../../../../config/LocalApi'

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const ExpirationCounter = () => {
  const [minutesRemaining, setMinutesRemaining] = useState();
  const [tokenHasExpirated, setTokenHasExpirated] = useState(false)

  const token = LocalApi.getToken()

  let remainingTimeInMinutes = 0;
  if(token){
    const decodedToken = parseJwt(token)  
    const tokenExpirationTime = decodedToken?.exp  
    remainingTimeInMinutes = Number((tokenExpirationTime * 1000 - Math.floor(new Date().getTime()))/60000)
  }


  useEffect(() => {
    let allMinutesRemaining = setInterval(() => {
      setMinutesRemaining(remainingTimeInMinutes);

      if (minutesRemaining <= 0) {
        clearInterval(allMinutesRemaining);
        setTokenHasExpirated(true);
        LocalApi.setPortalSessionExpired();
        LocalApi.clear();
        window.location.reload();
      }
    }, 1000);

    return () => {
      clearInterval(allMinutesRemaining);
    };
  });

  if(!minutesRemaining && !tokenHasExpirated) {
    return null
  }

  const returnHourString = () => {
    if(tokenHasExpirated) return 'Sessão Expirada'
    // Remaining time in minutes and hours
    const hour = Math.floor(minutesRemaining / 60)
    const minutes = Math.floor(minutesRemaining % 60)
    // Session about to expire
    if(minutesRemaining<=10 && hour<=0) return 'Sessão expirando'
    // Valid session
    if(minutesRemaining < 60) {
      return `${minutes}min`
    } else if(minutesRemaining >= 60 && minutes !== 0) {
      return `${hour}h ${minutes}min`
    } else {
      return `${hour}h`
    }
  } 

  return (
    <Container
      direction="horizontal"
      bodyType={minutesRemaining < 60 ? 'danger' : 'neutral'}
      verticalAlign  ="center"
      horizontalAlign="center"
      padding='5px 10px'
    >
      <Tooltip
        placement="bottom"
        title='Tempo para expiração da sessão'
      >
        <Text 
          width ="100%"
          textAlign='center'
          size='small'
        >
          {returnHourString()}
        </Text>
      </Tooltip>
      
    </ Container>
  )
}

export default ExpirationCounter;
