import React from "react";
import {
  Container,
  Icon,
  Text,
  tokens,
  Table,
  TableCell,
  IconButton,
  Tooltip,
} from "squid-ui-kit";
import { parseDateToHuman, localeString } from "../../../../utils/date";

const statusMap = new Map();
statusMap.set("PENDING", "Pendente");
statusMap.set("REVOKED", "Negada");

const getStatus = (status) => {
  if (statusMap.has(status)) {
    return statusMap.get(status);
  }
  return "-";
};

const cellRendererStatus = (item) => (
  <TableCell
    invertedColors
    verticalAlign="center"
    horizontalAlign="center"
    fontBodySize="small"
  >
    {getStatus(item)}
  </TableCell>
);

const cellRendererDate = (item) => (
  <Tooltip title={localeString(item)}>
    <TableCell
      invertedColors
      verticalAlign="center"
      horizontalAlign="center"
      fontBodySize="small"
    >
      {parseDateToHuman(item)}
    </TableCell>
  </Tooltip>
);

const cellRendererTooltip = (item) => (
  <Tooltip title={item}>
    <TableCell
      invertedColors
      verticalAlign="center"
      horizontalAlign="center"
      fontBodySize="small"
    >
      {item}
    </TableCell>
  </Tooltip>
);

const cellRendererActions = (applicationId, record, onGrant, onRevoke, onRemove) => (
  <TableCell invertedColors verticalAlign="center" horizontalAlign="center">
    <Tooltip placement="left" title="Conceder acesso">
      <IconButton
        size="small"
        invertedColors
        icon="checkmark"
        onClick={() => onGrant(applicationId, record.petrobrasKey)}
      />
    </Tooltip>
    <Tooltip placement="left" title="Negar acesso">
      <IconButton
        size="small"
        invertedColors
        icon="delete"
        onClick={() => onRevoke(applicationId, record.petrobrasKey)}
      />
    </Tooltip>
    <Tooltip placement="left" title="Remover">
      <IconButton
        size="small"
        invertedColors
        icon="trash"
        onClick={() => onRemove(record.accessRequestId)}
      />
    </Tooltip>
  </TableCell>
);

const AccessRequests = ({
  loading,
  applicationId,
  accessRequests,
  onGrant,
  onRevoke,
  onRemove,
  errorMessage,
}) => {
  if (loading) {
    return (
      <Container
        width="100%"
        height="100%"
        bodyType="neutral"
        space="1px"
        scroll="vertical"
        verticalAlign="center"
        horizontalAlign="center"
        direction="horizontal"
        progress={-1}
        progressLabel="Atualizando solicitações"
      ></Container>
    );
  }

  if (accessRequests.length === 0) {
    return (
      <Container
        width="100%"
        height="100%"
        bodyType="neutral"
        space="1px"
        scroll="vertical"
        verticalAlign="center"
        horizontalAlign="center"
        direction="horizontal"
      >
        <Icon icon="info" color={tokens.ColorPrimary} />
        <Text invertedColors>Nenhuma solicitação.</Text>
      </Container>
    );
  }
  const tableColumns = [
    {
      label: "REQUERENTE",
      dataIndex: "requesterName",
      flex: "2",
      cellRenderer: cellRendererTooltip,
    },
    {
      label: "MOTIVO",
      dataIndex: "reason",
      flex: "2",
      cellRenderer: cellRendererTooltip,
    },
    {
      label: "QUANDO",
      dataIndex: "createdAt",
      flex: "1",
      cellRenderer: cellRendererDate,
    },
    {
      label: "STATUS",
      dataIndex: "status",
      flex: "1",
      cellRenderer: cellRendererStatus,
    },
    {
      label: "AÇÕES",
      dataIndex: "accessRequestId",
      width: "100px",
      cellRenderer: (_item, record) => {
        return cellRendererActions(applicationId, record, onGrant, onRevoke, onRemove);
      },
    },
  ];

  let errMsg = undefined
  if(errorMessage){
    errMsg = (
    <Container width="100%" padding="5px" bodyType="danger" horizontalAlign="center">
      <Text>{errorMessage}</Text>
    </Container>)
  }

  return (
    <Container width="100%" height="100%" bodyType="primary">
      {errMsg}
      <Table
        invertedColors
        width="100%"
        height="100%"
        size="small"
        cellShowBorderBottom
        columns={tableColumns}
        dataSource={accessRequests}
      />
    </Container>
  );
};

export default AccessRequests;
