import React from "react";

import {
  Container,
  Alert,
  Text,
  Icon,
  tokens,
  Tabs,
  TabContainer,
} from "squid-ui-kit";

import {
  CreateRoleModal,
  RolesTable,
  RolesUsersTable,
  SelectedApplication,
  PermittedApplicationsTable,
  AttachApplicationModal,
  TokenDetails,
  ApplicationVersions,
  AccessRequests,
} from "./components";

import { ApplicationList } from "../../components";

const ApplicationRoles = ({
  loadingList,
  loadingApplicationInformation,
  loadingRoleTable,
  loadingUsersByRoleTable,
  loadingAuthorizedAppsList,

  updateRolesList,
  updateUserByRolesList,
  updateAuthorizedAppsList,

  applicationsList,
  rolesList,
  authorizedAppsList,
  applicationInformation,
  usersByRoleList,

  selectedApplication,
  setSelectedApplication,
  selectedApplicationChangelog,

  onUpdateAppQuery,
  onUpdateRolesQuery,

  showAddRolesModal,
  onShowAddRolesModal,
  showAttachApplicationModal,
  onShowAttachApplicationModal,

  onCreateRole,

  setSelectedRoleRow,
  selectedRoleRow,

  warningMessage,
  onAcceptWarning,

  isAdmin,

  onSelectCategory,
  selectedCategory,
}) => {  
  return (
    <>
      <Container
        width="100%"
        height="100%"
        bodyType="neutral"
        direction="horizontal"
        gap="10px"
      >
        <ApplicationList
          applicationsList={applicationsList}
          onUpdateQuery={onUpdateAppQuery}
          onSelectApplication={(value) => {
            setSelectedRoleRow(undefined);
            setSelectedApplication(value);
          }}
          loading={loadingList}
          loadingApplicationInformation={loadingApplicationInformation}
          selectedApplication={selectedApplication}
          panelLabel="APLICAÇÕES QUE GERENCIO"
        />

        <Container
          padding="10px"
          bodyType="content"
          direction="vertical"
          height="100%"
          flex="1"
          verticalAlign="top"
          space="10px"
        >
          {loadingApplicationInformation && (
            <Container
              width="100%"
              height="100%"
              bodyType="primary"
              progressLabel="Carregando..."
              progress={-1}
            />
          )}

          {selectedApplication !== undefined && (
            <Tabs
              width="100%"
              height="auto"
              selectedKey="general-data"
              bodyType="content"
              menuBodyType="primary"
              onChange={(tab) => {
                setSelectedRoleRow(undefined);
                onSelectCategory(tab.selectedKey);
              }}
            >
              <TabContainer
                key="general-data"
                tab="Dados gerais"
                bodyType="neutral"
                invertedColors
              >
                <div style={{ display: "none" }} />
              </TabContainer>

              <TabContainer
                key="permitted-applications"
                tab="Aplicações Permitidas"
                bodyType="neutral"
                invertedColors
              >
                <div style={{ display: "none" }} />
              </TabContainer>

              <TabContainer
                key="token"
                tab="Token"
                bodyType="neutral"
                invertedColors
              >
                <div style={{ display: "none" }} />
              </TabContainer>
              <TabContainer
                key="access-requests"
                tab="Solicitações de acesso"
                bodyType="neutral"
                invertedColors
              >
                <div style={{ display: "none" }} />
              </TabContainer>
              <TabContainer
                key="versions"
                tab="Versões"
                bodyType="neutral"
                invertedColors
              >
                <div style={{ display: "none" }} />
              </TabContainer>
            </Tabs>
          )}

          {selectedApplication !== undefined &&
            selectedCategory === "general-data" && (
              <>
                <SelectedApplication
                  applicationInformation={applicationInformation}
                  selectedApplication={selectedApplication}
                  onShowAddModal={onShowAddRolesModal}
                  loadingApplicationInformation={loadingApplicationInformation}
                  isAdmin={isAdmin}
                  label={`PAPÉIS DA APLICAÇÃO`}
                >
                  <RolesTable
                    rolesList={rolesList}
                    loading={loadingRoleTable}
                    onSelectRow={setSelectedRoleRow}
                    selectedRoleRow={selectedRoleRow}
                    applicationID={selectedApplication.id}
                    updateRolesList={updateRolesList}
                    isAdmin={isAdmin}
                  />
                </SelectedApplication>

                {selectedRoleRow && (
                  <RolesUsersTable
                    usersByRoleList={usersByRoleList}
                    loading={loadingUsersByRoleTable}
                    applicationID={selectedApplication.id}
                    onSelectRow={setSelectedRoleRow}
                    isAdmin={isAdmin}
                    selectedRoleRow={selectedRoleRow}
                    onUpdateQuery={onUpdateRolesQuery}
                    updateUserByRolesList={updateUserByRolesList}
                  />
                )}
              </>
            )}

          {selectedApplication !== undefined &&
            selectedCategory === "permitted-applications" && (
              <SelectedApplication
                applicationInformation={applicationInformation}
                selectedApplication={selectedApplication}
                onShowAddModal={onShowAttachApplicationModal}
                loadingApplicationInformation={loadingApplicationInformation}
                isAdmin={isAdmin}
                label={`APLICAÇÕES COM CONSUMO PERMITIDO`}
                enableFullHeight={true}
              >
                <PermittedApplicationsTable
                  authorizedAppsList={authorizedAppsList}
                  loading={loadingAuthorizedAppsList}
                  applicationID={selectedApplication.id}
                  updateAuthorizedAppsList={updateAuthorizedAppsList}
                  isAdmin={isAdmin}
                />
              </SelectedApplication>
            )}

          {selectedApplication !== undefined && selectedCategory === "token" && (
            <SelectedApplication
              applicationInformation={applicationInformation}
              selectedApplication={selectedApplication}
              onShowAddModal={onShowAttachApplicationModal}
              loadingApplicationInformation={loadingApplicationInformation}
              isAdmin={isAdmin}
              disableAddButton
              label={`TOKEN DA APLICAÇÃO`}
            >
              <TokenDetails
                applicationInformation={applicationInformation}
                applicationID={selectedApplication.id}
              />
            </SelectedApplication>
          )}

          {selectedApplication !== undefined &&
            selectedCategory === "versions" && (
              <SelectedApplication
                applicationInformation={applicationInformation}
                selectedApplication={selectedApplication}
                onShowAddModal={onShowAttachApplicationModal}
                loadingApplicationInformation={loadingApplicationInformation}
                isAdmin={isAdmin}
                disableAddButton
                enableFullHeight
                label={`VERSÕES DA APLICAÇÃO`}
              >
                <ApplicationVersions changelog={selectedApplicationChangelog} />
              </SelectedApplication>
            )}

          {selectedApplication !== undefined &&
            selectedCategory === "access-requests" && (
              <SelectedApplication
                applicationInformation={applicationInformation}
                selectedApplication={selectedApplication}
                onShowAddModal={onShowAttachApplicationModal}
                loadingApplicationInformation={loadingApplicationInformation}
                isAdmin={isAdmin}
                disableAddButton
                enableFullHeight
                label={`SOLICITAÇÕES DE ACESSO`}
              >
                <AccessRequests                   
                  applicationId={selectedApplication?.id}
                />
              </SelectedApplication>
            )}

          {!selectedApplication && (
            <Container
              verticalAlign="center"
              horizontalAlign="center"
              width="100%"
              height="100%"
              bodyType="neutral"
              direction="horizontal"
            >
              <Icon icon="info" color={tokens.ColorPrimary} />
              <Text invertedColors>
                Selecione a aplicação para definir seus papéis ...
              </Text>
            </Container>
          )}
        </Container>
      </Container>

      {showAddRolesModal && (
        <CreateRoleModal onClose={onShowAddRolesModal} onSave={onCreateRole} />
      )}

      {showAttachApplicationModal && (
        <AttachApplicationModal
          applicationInformation={applicationInformation}
          onClose={onShowAttachApplicationModal}
          updateAuthorizedAppsList={updateAuthorizedAppsList}
          authorizedAppsList={authorizedAppsList}
        />
      )}

      {warningMessage && (
        <Alert
          bodyType="content"
          padding="10px"
          message={
            <Container
              width="100%"
              height="100%"
              direction="vertical"
              verticalAlign="top"
              horizontalAlign="left"
              bodyType="neutral"
            >
              {warningMessage}
            </Container>
          }
          cancelLabel="Cancelar"
          confirmLabel="Sim"
          onClose={() => onAcceptWarning(undefined)}
          title="ERROR"
        />
      )}
    </>
  );
};

export default ApplicationRoles;
