import React, { useState, useEffect, useCallback } from "react";

import AccessRequests from "./AccessRequests.component";
import subwebCoreApi from "../../../../config/SubwebCoreApi";

function AccessRequestsContainer({onUpdate}) {
  const [accessRequestsList, setAccessRequestsList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const processAuthorizationError = (err) => {
    let msg = "Erro ao processar a solicitação de autorização de acesso";
    if (err?.response?.data?.detail) {
      msg = err.response.data.detail;
    }
    setErrorMessage(msg);
    setLoading(false);
  };

  const updateList = useCallback(() => {
    setLoading(true);
    subwebCoreApi.listPortalAccessRequests(
      (requests) => {
        setAccessRequestsList(requests);
        setLoading(false);
        onUpdate();
      },
      processAuthorizationError
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const processAuthorization = (petrobrasKey, grant) => {
    setErrorMessage(undefined);
    setLoading(true);
    subwebCoreApi.authorizePortalAccess(
      petrobrasKey,
      grant,
      updateList,
      processAuthorizationError
    );
  };

  const onGrant = (petrobrasKey) => {
    processAuthorization(petrobrasKey, true);
  };

  const onRevoke = (petrobrasKey) => {
    processAuthorization(petrobrasKey, false);
  };

  const onRemove = (accessRequestId) => {
    if (accessRequestId) {
      setErrorMessage(undefined);
      setLoading(true);
      subwebCoreApi.deleteAccessRequest(
        accessRequestId,
        updateList,
        processAuthorizationError
      );
    }
  };

  // Filter pending and revoked requests
  let filteredAccessRequests = accessRequestsList.filter(
    (req) => req.status === "PENDING" || req.status === "REVOKED"
  );
  // Include requester name
  filteredAccessRequests = filteredAccessRequests.map((req) => {
    let requesterName = req.petrobrasKey;
    if (req?.requester) {
      requesterName = req.requester.name;
    }
    return { ...req, requesterName };
  });

  useEffect(() => {
    updateList();
  }, [updateList]);

  return (
    <AccessRequests
      key="portal-access-request-component"
      loading={loading}
      accessRequests={filteredAccessRequests}
      onGrant={onGrant}
      onRevoke={onRevoke}
      onRemove={onRemove}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />
  );
}

export default AccessRequestsContainer;
