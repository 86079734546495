import React from "react";
import PropTypes from "prop-types";

import Postmate from "postmate";

// import LocalApi from "../../config/LocalApi";
import CoreBackendApi from "../../config/CoreBackendApi";

class AppFramePresentation extends React.Component {
  constructor(props) {
    super(props);

    this.container = React.createRef();

    this.state = {};
  }

  componentDidMount() {
    if (this.container.current) {
      const handshake = new Postmate({
        container: this.container.current, // Element to inject frame into
        url: this.props.src, // Page to load, must have postmate.js. This will also be the origin used for communication.
        name: "ui-iframe", // Set Iframe name attribute. Useful to get `window.name` in the child.
        classListArray: ["ui-iframe"], //Classes to add to the iframe via classList, useful for styling.
        model: {
          tokens: JSON.parse(JSON.stringify(this.props.tokens)),
          token: this.props.token,
          srcParams: this.props.srcParams,
          urlPortal: window.location.origin,
          urlSquid: window.SQUID_URL,
        },
      });
      // When parent <-> child handshake is complete, data may be requested from the child
      handshake.then((child) => {
        // Listen to a particular event from the child
        child.on("validate-token", (data) => {
          CoreBackendApi.validateToken(data.token)
            .then((valid) => {
              console.log(
                "+++++++++++ CORE BACKEND TOKEN VALID +++++++++++",
                valid
              );
              if (!valid) {
                this.props.onExpiration();
                return;
              }
            })
            .catch((error) => {
              console.log("+++++++++++ ERROR CALLBACK +++++++++++", error);
              this.props.onExpiration();
            });
        });
        child.on("open-tab", (openTabData) => {
          this.props.openTab(openTabData);
        });
      });
    }
  }

  render() {
    return (
      <div
        ref={this.container}
        id="ui-map-container"
        style={{
          // position: 'absolute',
          // top: '0px',
          // right: '0px',
          // bottom: '0px',
          // left: '0px',
          width: "100%",
          height: "100%",
          // display: props.active === true ? "block" : "none",
        }}
      ></div>
    );
  }
}

AppFramePresentation.propTypes = {
  src: PropTypes.string,
  active: PropTypes.bool,
  onExpiration: PropTypes.func,
  openTab: PropTypes.func,
};

AppFramePresentation.defaultProps = {
  src: "",
  active: false,
};

export default AppFramePresentation;
