import React from "react";
import PropTypes from "prop-types";
import Color from "color";

import { Panel } from "squid-ui-kit";

class HelpWindow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          backgroundColor: Color(this.props.tokens.ColorBlack).alpha(0.3),
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          zIndex: 20,
        }}
      >
        <div
          style={{
            width: "500px",
            height: "100%",
            backgroundColor: Color(this.props.tokens.ColorWhite).alpha(0.9),
          }}
        >
          <Panel
            label="Suporte ao sistema"
            direction="vertical"
            verticalAlign="top"
            horizontalAlign="left"
            bodyType="content"
            width="500px"
            height="100%"
            closeButton={true}
            onClose={this.props.onClose}
            padding="10px"
          >
            <div
              style={{
                fontFamily: this.props.tokens.FontControlDefaultFamily,
                fontWeight: this.props.tokens.FontBodySecondaryWeight,
                fontSize: this.props.tokens.FontControlDefaultSize,
                lineHeight: this.props.tokens.LineHeightBodyAction,
              }}
            >
              <p>Olá, tudo bem?</p>
              <br />
              <p>
                Esse é o seu canal de suporte. Para dúvidas, problemas ou
                pedidos de acesso, pedimos que seja enviado um email para o
                nosso canal no endereço:
              </p>
              <p style={{ fontWeight: 900 }}>
                cc-suporte-navalsubweb@petrobras.com.br
              </p>
              <br />
              <p>Por lá, vamos agilizar o atendimento de sua solicitação.</p>
              <br />
              <p>Obrigado.</p>
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}

HelpWindow.propTypes = {
  onClose: PropTypes.func,
};

HelpWindow.defaultProps = {};

export default HelpWindow;
