import React, { useState, useEffect } from "react";
import subwebCoreApi from "../../config/SubwebCoreApi";
import { squidUrl, subBaseUrl } from "../../config/Environment";
import ApiDocumentationCentralPresentation from "./ApiDocumentationCentral.presentation";

const ApiDocumentationCentralContainer = () => {
  const [apps, setApps] = useState([]);
  const [appsSearched, setAppsSearched] = useState([]);
  const [selectedAppDoc, setSelectedAppDoc] = useState(null);
  const [loadingApps, setLoadingApps] = useState(false);
  const [warningMessage, setWarningMessage] = useState(undefined);

  const onSearch = (data) => {
    const query = data.value;
    if (query === "") {
      setAppsSearched([]);
    } else {
      const filteredApps = apps.filter((app) =>
        app.name.toLowerCase().includes(query.toLowerCase())
      );
      setAppsSearched(filteredApps);
    }
  };

  const onSelectAppDoc = (app) => {
    setSelectedAppDoc(app);
  };

  const getAllApps = async () => {
    setLoadingApps(true);
    const rawApps = [];

    try {
      await subwebCoreApi.listAllApplications((data) => rawApps.push(...data));
    } catch (error) {
      setWarningMessage("Erro ao carregar as aplicações.");
    } finally {
      setLoadingApps(false);
    }

    const appDocs = rawApps
      .filter((rawApp) => rawApp.documentations.length > 0)
      .map((rawApp) => {
        const doc = rawApp.documentations[0];

        return {
          id: doc?.documentationId,
          key: rawApp.slug,
          name: rawApp.name,
          type: doc?.documentationType,
          url: `${subBaseUrl}${doc?.url}`,
        };
      })
      .sort((a, b) => {
        return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
      });

    // Add squid navalsubweb
    appDocs.push({
      id: "squid-navalsubweb",
      key: "squid-navalsubweb",
      name: "Squid NavalSubweb",
      type: "URL",
      url: `${squidUrl}/api/docs/`,
    });

    setApps(appDocs);
  };

  useEffect(() => {
    if (apps.length === 0 && !loadingApps) {
      getAllApps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAppsToShow = () => {
    return appsSearched.length > 0 ? appsSearched : apps;
  };

  return (
    <ApiDocumentationCentralPresentation
      apps={getAppsToShow()}
      onSearch={onSearch}
      onSelectAppDoc={onSelectAppDoc}
      loadingApps={loadingApps}
      selectedAppDoc={selectedAppDoc}
      warningMessage={warningMessage}
      onAcceptWarning={setWarningMessage}
    />
  );
};

export default ApiDocumentationCentralContainer;
