import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/AuthConfig";
import { Container } from "squid-ui-kit";
// https://learn.microsoft.com/en-us/entra/identity-platform/howto-add-branding-in-apps
import loginIcon from "../../assets/ms-symbollockup_signin_light_short.svg";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package 
 */

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };
  return (
    <Container onClick={() => handleLogin("redirect")}>
      <img height="32px" src={loginIcon} alt="Sign in with Microsoft" />
    </Container>
  );
};
