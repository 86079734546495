export default function ClosedMailIcon({
  color = "white",
  width = "14px",
  height = "14px",
  opacity = "1",
}) {
  return (
    <div style={{ width, height, color, opacity }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polyline
          fill="none"
          points="4 8.2 12 14.1 20 8.2"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <rect
          fill="none"
          height="14"
          rx="2"
          ry="2"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          width="18"
          x="3"
          y="6.5"
        />
      </svg>
    </div>
  );
}
