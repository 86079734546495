import React, { Suspense } from "react";
import SwaggerUI from "swagger-ui-react";
import { Container, Text } from "squid-ui-kit";
import "swagger-ui-react/swagger-ui.css";
import { ApplicationList, ExclusionAlert, URLRenderer } from "../../components";

const ApiDocumentationCentralPresentation = ({
  apps,
  onSearch,
  onSelectAppDoc,
  loadingApps,
  selectedAppDoc,
  warningMessage,
  onAcceptWarning,
}) => {
  const getAppDoc = () => {
    if (selectedAppDoc) {
      switch (selectedAppDoc.type) {
        case "URL":
          return <URLRenderer url={selectedAppDoc.url} />;
        case "SWAGGER":
          return <SwaggerUI url={selectedAppDoc.url} />;
        default:
          return (
            <Container
              flex="1"
              width="100%"
              verticalAlign="center"
              horizontalAlign="center"
            >
              <Text size="large" invertedColors bold>
                Não foi possível carregar a documentação! Tipo de documentação
                não suportado.
              </Text>
            </Container>
          );
      }
    } else {
      return (
        <Container
          flex="1"
          width="100%"
          verticalAlign="center"
          horizontalAlign="center"
        >
          <Text size="large" invertedColors bold>
            Selecione uma aplicação para visualizar sua documentação.
          </Text>
        </Container>
      );
    }
  };

  return (
    <Container
      width="100%"
      height="100%"
      bodyType="content"
      direction="horizontal"
    >
      <Container height="100%" bodyType="content">
        <ApplicationList
          applicationsList={apps}
          onUpdateQuery={onSearch}
          onSelectApplication={onSelectAppDoc}
          loading={loadingApps}
          selectedApplication={selectedAppDoc}
          panelLabel="APLICAÇÕES DO PORTAL COM DOCUMENTAÇÃO"
        />
      </Container>

      <Container
        bodyType="content"
        horizontalAlign="center"
        height="100%"
        flex="1"
        scroll="vertical"
      >
        <Suspense>{getAppDoc()}</Suspense>
      </Container>
      {warningMessage && (
        <ExclusionAlert
          exclusionText={warningMessage}
          title="Alerta"
          onCancel={() => onAcceptWarning(undefined)}
          onlyConfirmation
        />
      )}
    </Container>
  );
};

export default ApiDocumentationCentralPresentation;
