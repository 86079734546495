import React from "react";
import UpdatesTabPresentation from "./UpdatesTab.presentation";

class UpdatesTabContainer extends React.Component {
  constructor(props) {
    super(props);

    // parse date to human format
    this.parseDate = (date) => {
      if (!date) return null;

      const lastEditedDate = new Date(date);
      const now = new Date();
      const timeDifference = now - lastEditedDate;
      const userTimezoneOffset = now.getTimezoneOffset();
      const timeDifferenceInUserTimezone =
        timeDifference + userTimezoneOffset * 60 * 1000;

      const daysDifference = Math.floor(
        timeDifferenceInUserTimezone / (1000 * 60 * 60 * 24)
      );

      if (daysDifference > 7) {
        const formattedDate = `${lastEditedDate.getDate()}/${
          lastEditedDate.getMonth() + 1
        }/${lastEditedDate.getFullYear()}`;
        return formattedDate;
      }

      const hoursDifference = Math.floor(
        (timeDifferenceInUserTimezone / (1000 * 60 * 60)) % 24
      );
      const minutesDifference = Math.floor(
        (timeDifferenceInUserTimezone / (1000 * 60)) % 60
      );

      if (daysDifference <= 0) {
        if (hoursDifference <= 0) {
          return minutesDifference <= 0
            ? "agora mesmo"
            : `${minutesDifference} ${
                minutesDifference > 1 ? "minutos" : "minuto"
              } atrás`;
        }
        return `${hoursDifference} ${
          hoursDifference > 1 ? "horas" : "hora"
        } atrás`;
      }

      return `${daysDifference} ${daysDifference > 1 ? "dias" : "dia"} atrás`;
    };

    this.initialPageSize = 3;

    this.portalData = {
      portalName: "Portal SUBWEB",
      portalSlug: "subweb",
      portalUiKitUrl: "https://dev.subweb.com.br/uikit/",
    };

    this.linkComponent = function (url, text) {
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {text || url}
        </a>
      );
    };

    // write hardcoded updates messages here. Even if using html message, also add Plaintext data, since it is necessary for the search function to work.
    this.updatesMessages = [
      {
        appOwner: this.portalData.portalName,
        plaintext:
          "Neste portal você encontrará uma lista de aplicações/módulos disponíveis em ambiente Petrobras com propósitos específicos de engenharia. No painel ao lado encontram-se todas aplicações e módulos disponíveis.",
        createdAt: null,
        subject: `Seja bem-vindo ao ${this.portalData.portalName}`,
        notificationId: "1",
      },
      {
        appOwner: this.portalData.portalName,
        plaintext:
          "Acesse o nosso sharepoint para visualizar um resumo das aplicações disponibilizadas no Portal.",
        html: (
          <>
            Acesse o{" "}
            {this.linkComponent(
              "https://petrobrasbr.sharepoint.com.mcas.ms/teams/PortalSUBWEB/SitePages/Portal-SUBWEB.aspx",
              "nosso sharepoint"
            )}{" "}
            para visualizar um resumo das aplicações disponibilizadas no Portal.
          </>
        ),
        createdAt: null,
        subject: "Aplicações",
        notificationId: "2",
      },
      {
        appOwner: this.portalData.portalName,
        plaintext:
          "Acesse o site do projeto SUBWEB para obter mais informações sobre os portais e a Plataforma SQUID. O site contém uma área focada em desenvolvedores que desejam adicionar novos módulos no portal. Links de acesso: Home page, Descrição das aplicações e Espaço do Desenvolvedor.",
        html: (
          <>
            Acesse o site do projeto SUBWEB para obter mais informações sobre os
            portais e a Plataforma SQUID. O site contém uma área focada em
            desenvolvedores que desejam adicionar novos módulos no portal.
            <br />
            <br />
            Links de acesso:{" "}
            {this.linkComponent(
              "https://petrobrasbr.sharepoint.com/teams/NavalWeb/hotsite",
              "Home page"
            )}
            ,{" "}
            {this.linkComponent(
              "https://petrobrasbr.sharepoint.com.mcas.ms/teams/PortalSUBWEB/SitePages/Portal-SUBWEB.aspx",
              "Descrição das aplicações"
            )}{" "}
            e{" "}
            {this.linkComponent(
              "https://petrobrasbr.sharepoint.com/teams/NavalWeb/hotsite/SitePages/Espa%C3%A7odoDesenvolvedor.aspx",
              "Espaço do Desenvolvedor"
            )}
            .
          </>
        ),
        createdAt: null,
        subject: "Site do Projeto SUBWEB",
        notificationId: "3",
      },
      {
        appOwner: this.portalData.portalName,
        plaintext:
          "Este portal foi desenvolvido usando o UI-Kit desenvolvido e mantido pela Plataforma SQUID. Caso queira saber mais informações, acesse a página de documentação em https://dev.subweb.com.br/uikit/.",
        html: (
          <>
            Este portal foi desenvolvido usando o UI-Kit desenvolvido e mantido
            pela Plataforma SQUID. Caso queira saber mais informações, acesse a
            página de documentação em{" "}
            {this.linkComponent(this.portalData.portalUiKitUrl)}.
          </>
        ),
        createdAt: null,
        subject: "UI-Kit",
        notificationId: "4",
      },
      {
        appOwner: this.portalData.portalName,
        plaintext:
          "A Plataforma SQUID dispõe de uma API disponível para desenvolvedores de novos módulos do portal. Para acessar a documentação, acesse https://squid-dev.navalsubweb.com.br/api/docs/.",
        html: (
          <>
            A Plataforma SQUID dispõe de uma API disponível para desenvolvedores
            de novos módulos do portal. Para acessar a documentação, acesse{" "}
            {this.linkComponent(
              "https://squid-dev.navalsubweb.com.br/api/docs/"
            )}
            .
          </>
        ),
        createdAt: null,
        subject: "APIs da Plataforma SQUID",
        notificationId: "5",
      },
    ];

    this.state = {
      pageSizeBarValue: this.initialPageSize.toString(),
      searchBarValue: "",
      content: [],
      numberOfItems: 0,
      numberOfPages:
        Math.ceil(this.updatesMessages.length / this.initialPageSize) || 1,
      filterProps: {
        pageSize: this.initialPageSize,
        page: 1,
        searchText: null,
        caseSensitive: false,
        searchMode: "SUBSTRING",
        appOwner: null,
        order: "CHRONOLOGICAL",
      },
    };

    this.filterRenderedContent = (filterProps) => {
      const globalContent = this.updatesMessages;

      return globalContent.filter((notification) => {
        const searchText = filterProps.searchText?.toLowerCase() || "";
        const message = notification.plaintext?.toLowerCase();

        return message.includes(searchText);
      });
    };

    this.updateFilterProps = (filterProps) => {
      const filteredContent = this.filterRenderedContent(filterProps);
      const numberOfItems = filteredContent?.length || 0;
      const numberOfPages =
        Math.ceil(numberOfItems / filterProps.pageSize) || 1;
      const startIndex = (filterProps.page - 1) * filterProps.pageSize;
      const endIndex = startIndex + filterProps.pageSize;
      const pageContent = filteredContent.slice(startIndex, endIndex);
      this.setState({
        filterProps,
        content: pageContent,
        numberOfItems,
        numberOfPages,
      });
    };

    this.handleTypeSearch = (e) => {
      this.setState({ searchBarValue: e.value });
    };

    this.handleSearch = () => {
      const searchText = this.state.searchBarValue;
      const filterProps = { ...this.state.filterProps, searchText, page: 1 };
      this.updateFilterProps(filterProps);
    };

    this.handleRefresh = () => {
      const filterProps = {
        ...this.state.filterProps,
        page: 1,
        searchText: null,
      };
      this.setState({ searchBarValue: "" });
      this.updateFilterProps(filterProps);
    };

    this.handleNextPage = () => {
      if (this.state.filterProps.page === this.state.numberOfPages) return;
      const filterProps = {
        ...this.state.filterProps,
        page: this.state.filterProps.page + 1,
      };
      this.updateFilterProps(filterProps);
    };

    this.handlePreviousPage = () => {
      if (this.state.filterProps.page === 1) return;
      const filterProps = {
        ...this.state.filterProps,
        page: this.state.filterProps.page - 1,
      };
      this.updateFilterProps(filterProps);
    };

    this.handleFirstPage = () => {
      const filterProps = {
        ...this.state.filterProps,
        page: 1,
      };
      this.updateFilterProps(filterProps);
    };

    this.handleLastPage = () => {
      const filterProps = {
        ...this.state.filterProps,
        page: this.state.numberOfPages,
      };
      this.updateFilterProps(filterProps);
    };

    this.handleTypePageSize = (e) => {
      this.setState({ pageSizeBarValue: e.value });
    };

    this.handleChangePageSize = () => {
      const pageSize =
        parseInt(this.state.pageSizeBarValue) || this.filterProps.pageSize;
      const filterProps = {
        ...this.state.filterProps,
        pageSize,
        page: 1,
      };
      this.updateFilterProps(filterProps);
    };
  }

  componentDidMount() {
    this.updateFilterProps(this.state.filterProps);
  }

  render() {
    return (
      <UpdatesTabPresentation
        content={this.state.content}
        parseDate={this.parseDate}
        searchBarValue={this.state.searchBarValue}
        handleSearch={this.handleSearch}
        handleTypeSearch={this.handleTypeSearch}
        handleRefresh={this.handleRefresh}
        handleNextPage={this.handleNextPage}
        handlePreviousPage={this.handlePreviousPage}
        numberOfPages={this.state.numberOfPages}
        filterProps={this.state.filterProps}
        handleFirstPage={this.handleFirstPage}
        handleLastPage={this.handleLastPage}
        handleChangePageSize={this.handleChangePageSize}
        pageSizeBarValue={this.state.pageSizeBarValue}
        handleTypePageSize={this.handleTypePageSize}
      />
    );
  }
}

UpdatesTabContainer.defaultProps = {};

export default UpdatesTabContainer;
