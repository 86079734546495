import React from 'react';
import {
  Container,
  InfoItem,
  Text
} from "squid-ui-kit";

import defaultImage from "../../../../assets/question.png"

const applicationTypeNameFn = (type) =>{
  if(type?.name){
    const {name} = type;
    if(name === 'Basic Data' ){
      return 'Dados básicos'
    }
    if(name === 'Application' ){
      return 'Aplicação'
    }
  }
  return 'Sem tipo especificado'
}

const ApplicationDetails = ({ applicationInformation }) => {

  return (
    <Container
        width="100%"
        bodyType="neutral"
        padding="0px 0px 10px 0px"
      >
        <Container
          width="100%"
          minWidth="100px"
          bodyType="neutral"
          direction="horizontal"
        >
          <Container 
            direction='vertical'
            width="100px"
            height="100%"
            bodyType="neutral"
          > 
            <img
            src={applicationInformation.icon || defaultImage}
            alt='Application Default'
            width="100px"
            height="100px"
            />
          </Container>

          <Container 
            direction='vertical'
            bodyType="neutral"
            flex='1'
            height="100%"
            margin='0px 10px'
            space='5px'
          > 
            <Container 
              direction='horizontal'
              bodyType="neutral"
              width="100%"
              height="auto"
            >
              <InfoItem label="NOME" flex="1" value={applicationInformation.name || 'Sem nome'} invertedColors size="small" />
              <InfoItem 
                label="TIPO" 
                flex="1" 
                value={ applicationTypeNameFn(applicationInformation.type) } 
                size="small"
                invertedColors />
              <InfoItem 
                label="URL"
                flex="1" 
                wrap='wrap'
                size="small"
                value={
                  applicationInformation.url ? 
                  (
                    <Text underline invertedColors bold> 
                      {
                        applicationInformation.url
                      }
                    </Text>
                  )
                  : 'Sem URL'
                } 
                invertedColors 
              />
            </Container>

            <Container 
              direction='horizontal'
              bodyType="neutral"
              width="100%"
              height="100%"
            >
              <InfoItem label="DESCRIÇÃO" flex="2" 
                value={applicationInformation.description ||'Sem descrição'} 
                invertedColors 
                size="small"
              />
              <InfoItem label="CHAVE/SLUG" flex="1" 
                value={applicationInformation.slug ||'-'} 
                invertedColors 
                size="small"
              />
            </Container>
          </Container>

          
        </Container>
      </Container>
    )     
  };

export default ApplicationDetails;
