import React from "react";
import PropTypes from "prop-types";
import Color from "color";

import { Container, IconButton, Spacer } from "squid-ui-kit";

class TabPresentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onClick = (e) => {
      if (this.props.onClick) {
        this.props.onClick(this.props.index);
      }
    };

    this.onClose = (e) => {
      if (this.props.onClose) {
        this.props.onClose(this.props.index);
      }
    };
  }

  render() {
    let bodyType = "neutral";
    let activeBg = "transparent";
    if (this.props.active === true) {
      bodyType = "default";
      activeBg = "white";
    }

    let label = (
      <Container
        flex="1"
        height="100%"
        direction="horizontal"
        verticalAlign="center"
        bodyType="neutral"
        margin="0px"
        padding="0px"
      >
        <Spacer size="10px" />
        <Container
          flex="1"
          height="100%"
          direction="horizontal"
          verticalAlign="center"
          bodyType="neutral"
          margin="0px"
          padding="0px"
        >
          <span
            style={{
              fontFamily: this.props.tokens.FontBodyPrimaryFamily,
              fontSize: this.props.tokens.FontBodyPrimarySize,
              fontWeight: this.props.tokens.FontBodyPrimaryWeight,
              color: Color(this.props.tokens.ColorWhite).alpha(0.8),
            }}
          >
            {this.props.label}
          </span>
        </Container>
      </Container>
    );

    let icon = <IconButton icon="home" size="large" onClick={this.onClick} />;

    let width = "195px";
    if (this.props.compact === true) {
      label = null;
      width = "50px";
    } else {
      if (this.props.icon) {
        icon = (
          <Container
            width="20px"
            height="20px"
            bodyType="neutral"
            horizontalAlign="center"
            verticalAlign="center"
            margin="0px"
            padding="0px"
          >
            {this.props.icon}
          </Container>
        );
      }
    }

    let close;
    if (this.props.compact === false) {
      close = <IconButton icon="close" onClick={this.onClose} />;
    }

    return (
      <Container
        width={width}
        height="100%"
        bodyType={bodyType}
        margin="0px"
        padding="0px"
      >
        <Container
          width="100%"
          height="38px"
          direction="horizontal"
          horizontalAlign={this.props.compact === true ? "center" : "left"}
          verticalAlign="center"
          bodyType="neutral"
          margin="0px"
          padding={this.props.compact === true ? "10px" : "10px 0px 10px 10px"}
        >
          <Container
            flex="1"
            // width="100%"
            height="38px"
            direction="horizontal"
            horizontalAlign={this.props.compact === true ? "center" : "left"}
            verticalAlign="center"
            bodyType="neutral"
            margin="0px"
            padding="0px"
            onClick={this.onClick}
          >
            {icon}
            {label}
          </Container>
          {close}
        </Container>
        <div
          style={{
            width: "100%",
            height: "2px",
            backgroundColor: activeBg,
          }}
          onClick={this.onClick}
        ></div>
      </Container>
    );
  }
}

TabPresentation.propTypes = {
  index: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.any,
  compact: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

TabPresentation.defaultProps = {
  index: "",
  label: "Tab",
  icon: null,
  compact: false,
  active: false,
};

export default TabPresentation;
