import React from 'react';
import {
  Container,
  Alert,
  Text,
  Divider
} from "squid-ui-kit";

import alertImage from "../../assets/alert.svg"

const ExclusionAlert        = (
  { 
    exclusionText, title, onCancel, onConfirm, onlyConfirmation 
  }) => {

  return (
    <Alert
      bodyType           ="primary"
      padding            ='10px 20px'
      width              ="300px"
      confirm            ={!onlyConfirmation}
      cancelLabel        ="Cancelar"
      confirmLabel       ="Confirmar"
      onCancel           ={() => onCancel()}
      onClose            ={() => onCancel()}
      onConfirm          ={() => {
        onConfirm()
        onCancel()
      }}
      title              ={title}
      message            ={
        <Container
          direction      ="horizontal"
          verticalAlign  ="center"
          horizontalAlign="center"
          bodyType       ="neutral"
        >
          <img
            src={alertImage}
            alt='alert'
            width="24px"
            height="24px"
          />
          <Divider direction='vertical' margin='10px'/>
          <Container
            flex           = '1'
            height         ="100%"
            direction      ="vertical"
            verticalAlign  ="top"
            horizontalAlign="left"
            bodyType       ="neutral"
          >
            <Text 
              width ="100%"
              textAlign='center'
            >
              {exclusionText}
            </Text>
          </Container>
        </Container>
      }
    />
  )
};

export default ExclusionAlert;
