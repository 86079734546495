import React from 'react';
import {
  Container,
  Text,
  TextField,
  Button,
  Icon,
  IconButton,
} from "squid-ui-kit";

const TokenDetails = ({ 
    applicationInformation, 
    token, 
    confirmRenewToken, 
    copyTokenToClipboard, 
    loading,

    showSucessModal,
    closeSucessModal,

    showCopyMessage,
  }) => {

  return (
    <Container
        width="100%"
        bodyType="neutral"
        padding="0px 0px 10px 0px"
      >
        <Container
          width="100%"
          minWidth="100px"
          bodyType="neutral"
          direction="horizontal"
        >
          <Container 
            direction='vertical'
            bodyType="neutral"
            flex='1'
            height="100%"
            margin='0px 10px'
            space='5px'
          > 
            <Container 
              direction='vertical'
              bodyType="neutral"
              width="100%"
              height="auto"
              padding='10px 0px'
            >
             {/* <Text size='default' invertedColors bold>INSTRUÇÕES DE USO</Text>
              <Text size='large' invertedColors >
                <p>Lorem ipsum dolor sit amet consectetur. Turpis convallis amet in ullamcorper viverra nunc molestie nunc lorem. Arcu dolor pharetra sociis tincidunt. Pharetra amet auctor commodo tincidunt vitae. Ut euismod id fringilla nunc etiam aliquet sodales rhoncus. Eleifend sit enim eu sed aliquam at. Faucibus diam vel leo aliquet suspendisse velit nunc donec.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Turpis convallis amet in ullamcorper viverra nunc molestie nunc lorem. Arcu dolor pharetra sociis tincidunt. Pharetra amet auctor commodo tincidunt vitae. Ut euismod id fringilla nunc etiam aliquet sodales rhoncus. Eleifend sit enim eu sed aliquam at. Faucibus diam vel leo aliquet suspendisse velit nunc donec.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Turpis convallis amet in ullamcorper viverra nunc molestie nunc lorem. Arcu dolor pharetra sociis tincidunt. Pharetra amet auctor commodo tincidunt vitae. Ut euismod id fringilla nunc etiam aliquet sodales rhoncus. Eleifend sit enim eu sed aliquam at. Faucibus diam vel leo aliquet suspendisse velit nunc donec.</p>
              </Text>*/}

              
            </Container>

            <Container 
              direction='horizontal'
              bodyType="neutral"
              width="100%"
              height="auto"
              space='10px'
              verticalAlign='bottom'
            >
              <TextField 
                invertedColors
                label='token'

                placeholder="*****************************************************************"
                width="410px"
                size='small'
                disabled
                value={token ? '##########################################' : ''}
              />

              <Container 
                direction='horizontal'
                bodyType="neutral"
                width="auto"
                height="100%"
                verticalAlign='bottom'
              >
                {token ? 
                (
                  <Button
                    name="portal-copy-token"
                    label='COPIAR TOKEN'
                    size='small'
                    onClick={() => copyTokenToClipboard()}
                    type="primary"
                    disabled={loading}
                  /> 
                ) : (
                  <Button
                    name="portal-renew-token"
                    label='RENOVAR TOKEN'
                    size='small'
                    onClick={() => confirmRenewToken()}
                    type="secondary"
                    disabled={loading}
                  />  
                  )
                }
              </Container>

              
            </Container>
            {showSucessModal && (
                <Container 
                  bodyType={"success"} 
                  width='554.85px' 
                  direction="horizontal" 
                  padding='5px' 
                  horizontalAlign="center" 
                  verticalAlign="center"
                  borderType='success'
                >
                    <Container 
                      horizontalAlign="center" 
                      verticalAlign="center"
                      width='50px'
                      direction="vertical"
                      bodyType="neutral"
                    > 
                      <Icon icon={!showCopyMessage ? "checkmark" : "info" } size="large" color='black'/>
                    </Container>
                    <Container 
                      horizontalAlign="center" 
                      verticalAlign="center"
                      direction="vertical"
                      flex='1'
                      bodyType="neutral"
                    > 
                      <Text invertedColors>
                        {showCopyMessage ?
                          'Token copiado com sucesso para área de transferência!'
                          : 'Token gerado com sucesso!'}
                      </Text>
                    </Container>
                    <Container 
                      horizontalAlign="center" 
                      verticalAlign="center"
                      direction="vertical"
                      width='50px'
                      bodyType="neutral"
                    > 
                      <IconButton icon="close" size="large" invertedColors onClick={ () => 
                        closeSucessModal(false)
                      } 
                    />
                  </Container>
                </Container>
              )}
          </Container>

          
        </Container>
      </Container>
    )     
  };

export default TokenDetails;
