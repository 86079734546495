import React from "react";
import Color from "color";

import {
  Panel,
  TextField,
  Container,
  Button,
  tokens,
  ListItem,
  Title,
  Icon,
  IconButton,
  Text
} from "squid-ui-kit";

const AttachApplicationModal = ({ 
    applicationsList, 

    onUpdateQuery, 
    onSelectApplication, 

    loading,
    loadingSubmit,

    selectedApplication,

    confirmAttach,

    error,
    handleError,
    onClose,

    showSucessModal,
    closeSucessModal
  }) => {

  let footer = (
      <Container
        width="auto"
        bodyType="neutral"
        padding="0px"
        direction="horizontal"
        horizontalAlign="right"
        verticalAlign="center"
      >
        <Button
          label={"ADICIONAR"}
          size='small'
          type="cta"
          onClick={() => {
            confirmAttach()
          }}
        />
      </Container>
    );

  return (
    <>
      <div
          style={{
            display: "flex",
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            backgroundColor: Color(tokens.ColorBlack).alpha(0.3),
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            zIndex: 20,
          }}
      >
        <Panel
            label={"ADICIONAR APLICAÇÃO"}
            direction="vertical"
            verticalAlign="top"
            horizontalAlign="left"
            bodyType="primary"
            width="500px"
            height="100%"
            footer={footer}
            closeButton={true}
            padding="10px"
            space="10px"
            onClose={onClose}
        >
          <Container width="100%" bodyType="default" verticalAlign="center" padding="5px">
            <TextField
              name="portal-settings-app-list-attach"
              width="100%"
              placeholder="Busque aqui..."
              searchButton
              size="small"
              onChange={onUpdateQuery}
            />
          </Container>

          {(showSucessModal || error) && (
            <Container 
              bodyType={!error ? "success" : "danger"} 
              width='100%' 
              direction="horizontal" 
              padding='5px' 
              space='10px' 
              horizontalAlign="center" 
              verticalAlign="center"
            >
                <Container 
                  horizontalAlign="center" 
                  verticalAlign="center"
                  width='50px'
                  direction="vertical"
                  bodyType="neutral"
                > 
                  <Icon icon={!error ? "checkmark" : "alert" } size="large" />
                </Container>
                <Container 
                  horizontalAlign="center" 
                  verticalAlign="center"
                  direction="vertical"
                  flex='1'
                  bodyType="neutral"
                > 
                  <Text>
                    {error ?
                      (error?.response?.data?.message 
                        || error?.message 
                        || 'Um erro inesperado ocorreu, entre em contato com o administrador')
                      : 'Autorização concedida com sucesso!'}
                  </Text>
                </Container>
                <Container 
                  horizontalAlign="center" 
                  verticalAlign="center"
                  direction="vertical"
                  width='50px'
                  bodyType="neutral"
                > 
                  <IconButton icon="close" size="large" onClick={ () => 
                    !error ? closeSucessModal(false) : handleError()
                  } 
                  />
                </Container>
            </Container>
          )}

            <Container width="100%" height="100%" bodyType="default" verticalAlign="top" scroll="vertical">
            {applicationsList.length === 0 ? (
              <Container width="100%" height='24px' bodyType="default" verticalAlign="center" margin="5px">
                {(loading || loadingSubmit) ? <ListItem size="small" title='Carregando...' disabled/> :
                  (<Title
                    level={5}
                    >
                    Não há aplicações
                  </Title>)
                }
              </Container>
            ) : (
              <Container width="100%" bodyType="primary" height="100%" verticalAlign="top" padding="5px" scroll="vertical">
                {
                  applicationsList.map((application) =>
                    <ListItem
                      key={application.id}
                      title={application.name}
                      icon={'chevron-right'}
                      onClick={() => onSelectApplication(application)}
                      selected={selectedApplication?.id === application.id}
                      size="small"
                    />)
                  }
              </Container>
              )}
            </Container>
          
        </Panel>
      </div>
    </>
  )
};


export default AttachApplicationModal;
