import React from "react";
import PropTypes from "prop-types";
import Color from "color";

import {
  Spacer,
  Panel,
  TextField,
  TextArea,
  Container,
  Button,
} from "squid-ui-kit";

class WindowFormLocalApplication extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      params: props.params,
      name: props.name,
      errorName: undefined,
      url: props.url,
      errorUrl: undefined,
    };

    this.onParamsChange = (e) => {
      this.setState({
        params: e.value,
      });
    };
    this.onNameChange = (e) => {
      this.setState({
        name: e.value,
      });
    };
    this.onUrlChange = (e) => {
      this.setState({
        url: e.value,
      });
    };

    this.onRemove = (e) => {
      this.props.onRemove(this.props.name);
    };

    this.onSave = (e) => {
      if (
        this.props.onSave &&
        this.state.name &&
        this.state.name !== "" &&
        this.state.url &&
        this.state.url !== ""
      ) {
        this.props.onSave({
          name: this.state.name,
          url: this.state.url,
          params: this.state.params,
        });
      } else {
        let errorName;
        let errorUrl;
        if (!this.state.name || this.state.name === "") {
          errorName = "É necessário informar a instância local";
        }
        if (!this.state.url || this.state.url === "") {
          errorUrl = "É necessário informar a URL local";
        }
        this.setState({
          errorName,
          errorUrl,
        });
      }
    };
  }

  render() {
    let footer = (
      <Container
        width="auto"
        bodyType="neutral"
        padding="0px"
        direction="horizontal"
        horizontalAlign="right"
      >
        <Button label="REMOVER" type="tertiary" onClick={this.onRemove} />
        <Spacer flex="1" />
        <Button
          label={this.props.mode === "create" ? "CRIAR" : "EDITAR"}
          type="cta"
          onClick={this.onSave}
        />
      </Container>
    );

    return (
      <div
        style={{
          display: "flex",
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          backgroundColor: Color(this.props.tokens.ColorBlack).alpha(0.3),
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          zIndex: 20,
        }}
      >
        <Panel
          label={`${
            this.props.mode === "create" ? "Adicionar nova" : "Editar"
          } aplicação local`}
          direction="vertical"
          verticalAlign="top"
          horizontalAlign="left"
          bodyType="primary"
          width="500px"
          height="100%"
          footer={footer}
          closeButton={true}
          padding="10px"
          space="10px"
          onClose={this.props.onClose}
        >
          <TextField
            width="100%"
            label="Nome da aplicação"
            placeholder="Metocean"
            value={this.state.name}
            error={this.state.errorName}
            onChange={this.onNameChange}
          />
          <TextField
            width="100%"
            label="URL local da aplicação"
            placeholder="http://localhost:3000"
            value={this.state.url}
            error={this.state.errorUrl}
            onChange={this.onUrlChange}
          />
          <TextArea
            width="100%"
            label="Parâmetros (opcional)"
            placeholder="{}"
            value={this.state.params}
            onChange={this.onParamsChange}
          />
        </Panel>
      </div>
    );
  }
}

WindowFormLocalApplication.propTypes = {
  mode: PropTypes.oneOf(["create", "edit"]),
  name: PropTypes.string,
  url: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
};

WindowFormLocalApplication.defaultProps = {
  mode: "create",
  name: "",
  url: "",
};

export default WindowFormLocalApplication;
