import React from "react";
import PropTypes from "prop-types";

import { Container, Spacer, Tabs, TabContainer, Icon  } from "squid-ui-kit";

import defaultImage from "../../assets/question.png"

class ConfigPresentation extends React.Component {
  render() {
    return (
      <Container
        width="100%"
        height="100%"
        direction="vertical"
        bodyType="primary"
      >
        <Container
          width="100%"
          height="100%"
          bodyType="neutral"
          direction="horizontal"
        >
          <Container 
          height="100%"
          bodyType="neutral"
          >
              <Tabs
                direction="vertical"
                selectedKey={this.props.selectedCategory.key}
                onChange={(tab) => this.props.onChangeCategory(tab)}
                menuSize='50px'
              >
                {this.props.categories.map((category) => (
                    <TabContainer
                      key={category.key}
                      tab={
                        <Container 
                          direction="horizontal" 
                          bodyType="neutral" 
                          verticalAlign="center"
                          padding='0px 0px 0px 5px'
                          >
                            {typeof(category.icon) == 'string' && (
                              <Icon 
                                icon={category.icon}
                                size="large"
                              />
                            )}

                            {typeof(category.icon) == 'object' && (
                              category.icon
                            )}

                            {!category.icon && (
                              <img
                                src={defaultImage}
                                alt={category.name}
                                width="20px"
                                height="20px"
                                style={{ WebkitFilter: 'invert(100%)', filter: 'invert(100%)' }}
                              />
                            )}

                            {category.name && (
                              <>
                                <Spacer size="5px" />
                                <span>
                                  {category.name}
                                </span>
                              </>
                            )}
                        </Container>
                      }
                    >
                      <div style={{ display: 'none' }} />
                    </ TabContainer>
                  ))}
              </Tabs>
          </Container>
          <Container flex="2" height="100%" bodyType="neutral">
          {this.props.selectedCategory.component}
          </Container>
        </Container>
      </Container>
    );
  }
}

ConfigPresentation.propTypes = {
  isAdmin: PropTypes.bool,
  categories: PropTypes.array,
  selectedCategory: PropTypes.object,
  
  onChangeCategory: PropTypes.func,
};

ConfigPresentation.defaultProps = {
  isAdmin: false,
  categories: [],
  selectedCategory: null,
};

export default ConfigPresentation;
