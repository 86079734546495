import React from "react";
import PropTypes from "prop-types";

import { Container, Divider, IconButton, Spacer } from "squid-ui-kit";
import "./styles.css";

import { ExpirationCounter } from './components'

import TabBar from "../TabBar";
import { Avatar } from "../../components";

class TopBarPresentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onHelp = (e) => {
      if (this.props.onHelp) {
        this.props.onHelp();
      }
    };
    this.onLogout = (e) => {
      if (this.props.onLogout) {
        this.props.onLogout();
      }
    };
    this.onConfig = (e) => {
      if(this.props.onConfig) {
        this.props.onConfig();
      }
    };
  }

  render() {
    return (
      <Container
        width="100%"
        height="42px"
        bodyType="neutral"
        margin="0px"
        padding="0px"
      >
        <Container
          width="100%"
          height="40px"
          direction="horizontal"
          bodyType="neutral"
          margin="0px"
          padding="0px"
        >
          <Container
            width="120px"
            height="100%"
            direction="horizontal"
            horizontalAlign="center"
            verticalAlign="center"
            bodyType="default"
            margin="0px"
            padding="0px"
          >
            <Container
              flex="1"
              height="100%"
              direction="horizontal"
              horizontalAlign="center"
              verticalAlign="center"
              bodyType="default"
              margin="0px"
              padding="0px"
            >
              <img
                style={{
                  width: "auto",
                  height: "22px",
                }}
                src={`${window.SQUID_PORTAL_ID}-top-bar.svg`}
                alt=""
              />
            </Container>
            <Divider direction="vertical" size="100%" margin="0px" />
          </Container>
          <TabBar
            tokens={this.props.tokens}
            tabs={this.props.tabs}
            tab={this.props.tab}
            onChange={this.props.onTabChange}
            onTabClose={this.props.onTabClose}
          />
          <Divider direction="vertical" size="100%" margin="0px" />
          <Container
            width="auto"
            height="100%"
            direction="horizontal"
            horizontalAlign="right"
            verticalAlign="center"
            bodyType="default"
            margin="0px"
            padding="0px"
          >
            <Container
              flex="1"
              height="100%"
              direction="horizontal"
              horizontalAlign="right"
              verticalAlign="center"
              bodyType="default"
              margin="0px"
              padding="0px 18px"
            >              
              <IconButton icon="help" size="large" onClick={this.onHelp} />
              <Spacer size="10px" />
              <IconButton icon="setup" size="large" onClick={this.onConfig} />
              <Spacer size="10px" />
              <IconButton icon="power" size="large" onClick={this.onLogout} />
              <Spacer size="10px" />
              <ExpirationCounter />
            </Container>
            <Avatar
              width="40px"
              height="40px"
              src={this.props.avatar}
            />
          </Container>
        </Container>
        <Divider direction="horizontal" size="100%" margin="0px" />
      </Container>
    );
  }
}

TopBarPresentation.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  tab: PropTypes.string,
  avatar: PropTypes.string,
  onTabChange: PropTypes.func,
  onTabClose: PropTypes.func,
  onHelp: PropTypes.func,
  onConfig: PropTypes.func,
  onLogout: PropTypes.func,
};

TopBarPresentation.defaultProps = {
  tabs: [],
  tab: "",
  avatar: "",
};

export default TopBarPresentation;
