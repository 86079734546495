import React from "react";
import { Container, InfoItem, Panel, Text } from "squid-ui-kit";

import defaultImage from "../../../../assets/question.png";

const applicationTypeNameFn = (type) => {
  if (type?.name) {
    const { name } = type;
    if (name === "Basic Data") {
      return "Dados básicos";
    }
    if (name === "Application") {
      return "Aplicação";
    }
  }
  return "Sem tipo especificado";
};

const ApplicationDetails = ({ applicationInformation }) => {
  return (
    <Panel
      width="100%"
      label={applicationInformation?.name || "APLICAÇÃO SELECIONADA"}
      bodyType="content"
    >
      <Container
        width="100%"
        minWidth="100px"
        bodyType="neutral"
        direction="horizontal"
        padding="5px 0px 0px 0px"
      >
        <Container
          direction="vertical"
          width="100px"
          height="100%"
          bodyType="neutral"
          verticalAlign="center"
        >
          <img
            src={applicationInformation.icon || defaultImage}
            alt="Application Default"
            width="100px"
            height="100px"
          />
        </Container>

        <Container
          direction="vertical"
          bodyType="neutral"
          flex="1"
          height="fit-content"
          padding="0px 10px"
          space="5px"
        >
          <Container
            direction="horizontal"
            bodyType="neutral"
            width="100%"
            height="auto"
          >
            <InfoItem
              label="NOME"
              flex="1"
              value={applicationInformation.name || "Sem nome"}
              invertedColors
              size="small"
            />
            <InfoItem
              label="TIPO"
              flex="1"
              value={applicationTypeNameFn(applicationInformation.type)}
              size="small"
              invertedColors
            />
            <InfoItem
              label="URL"
              flex="1"
              wrap="wrap"
              size="small"
              value={
                applicationInformation.url ? (
                  <Text underline invertedColors bold>
                    {applicationInformation.url}
                  </Text>
                ) : (
                  "Sem URL"
                )
              }
              invertedColors
            />
          </Container>

          <Container
            direction="horizontal"
            bodyType="neutral"
            width="100%"
            height="100%"
          >
            <InfoItem
              label="DESCRIÇÃO"
              flex="2"
              height="100%"
              value={applicationInformation.description || "Sem descrição"}
              invertedColors
            />
            <InfoItem
              label="CHAVE/SLUG"
              flex="1"
              value={applicationInformation.slug || "-"}
              size="small"
              invertedColors
            />
          </Container>
        </Container>
      </Container>
    </Panel>
  );
};

export default ApplicationDetails;
