import React from 'react';
import {
  Container,
  IconButton,
  Table,
  TableCell,
  Icon,
  Text,
  tokens,
} from "squid-ui-kit";

import { ExclusionAlert } from '../../../../components'

const cellRenderer = (item) => (
  <TableCell invertedColors verticalAlign="center" horizontalAlign="left" fontBodySize="small">
    {item}
  </TableCell>
);

const PermittedApplicationsTable = (
  { 
    authorizedAppsList, 
    loading, 
    deleteCallback, 
    isAdmin, 
    rowToDelete, 
    confirmDeletion,
    error,
    handleError
  }) => {

  const tableColumns = [
    {
      label: 'APLICAÇÃO',
      dataIndex: 'name',
      flex: '1',
      selected: false,
      cellRenderer,
    },
    {
      label: 'AÇÕES',
      dataIndex: 'id',
      selected: false,
      width: '100px',
      cellRenderer: (id) => {
        return (<TableCell invertedColors verticalAlign="center" horizontalAlign="center">
          <IconButton
            size="small"
            invertedColors
            icon="delete"
            onClick={() => deleteCallback(id)}
          />
        </TableCell>
      )}
    }
  ]

  if (loading) {
    return (
      <Container
        width="100%"
        height="100%"
        bodyType="primary"
        progressLabel="Carregando..."
        progress={-1}
      />
    )
  }

  if (!authorizedAppsList.length) {
    return (
      <Container verticalAlign="center" horizontalAlign="center" width="100%" height="100%" bodyType="content" direction="horizontal">
        <Icon icon="info" color={tokens.ColorPrimary}/>
        <Text invertedColors>Nenhuma aplicação com consumo permitido.</Text>
      </Container>
    )
  }

  return (
    <Container width="100%" height='100%' bodyType="primary">
      <Table
        invertedColors
        width="100%"
        height="100%"
        size="small"
        cellShowBorderBottom
        columns={isAdmin ? tableColumns : tableColumns.slice(0, tableColumns.length - 1)}
        dataSource={authorizedAppsList}
      />

      {rowToDelete && (
        <ExclusionAlert 
          exclusionText=
            {`Deseja remover a autorização da aplicação ${authorizedAppsList.find(app => app.id === rowToDelete).name}?`}
          title='Alerta'
          onCancel={() => deleteCallback(undefined)}
          onConfirm={() => confirmDeletion()}
        />
      )}

      {error && (
        <ExclusionAlert 
          exclusionText=
            {error?.response?.data?.message || error?.message || 'Um erro inesperado ocorreu, entre em contato com o administrador'}
          title='Alerta'
          onCancel={() => handleError()}
          onConfirm={() => handleError()}
        />
      )}
    </Container>
  )
};

export default PermittedApplicationsTable;
