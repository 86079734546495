/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import PortalUsersPresentation from "./PortalUsers.presentation";
import CoreBackendApi from "../../config/CoreBackendApi";

const PortalUsersContainer = ({ isAdmin, tokens }) => {
  const [showWindowFormUser, setShowWindowFormUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const listUsers = () => {
    CoreBackendApi.listUsers()
      .then((usersSquid) => {
        usersSquid.sort((a, b) => {
          return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
        });
        setUsers(usersSquid);
      })
      .catch((error) => console.error(error));
  };

  const onAddUser = () => {
    setShowWindowFormUser(true);
  };

  const onDeleteUser = (selectedUser) => {
    CoreBackendApi.deleteUser(selectedUser.id)
      .then((data) => {
        listUsers();
      })
      .catch((error) => console.error(error));
  };

  const onCloseWindowFormUser = () => {
    setShowWindowFormUser(false);
  };

  const onUsersListUpdated = () => {
    listUsers();
  };

  const onProfileChange = (e) => {
    let selectedUser;
    Object.keys(users).forEach((index) => {
      if (users[index].id === e.name) {
        selectedUser = users[index];
      }
    });
    if (selectedUser) {
      if (selectedUser.groups && selectedUser.groups.length > 0) {
        CoreBackendApi.leaveGroupUser(selectedUser.id, selectedUser.groups[0].id)
          .then(() => CoreBackendApi.joinGroupUser(selectedUser.id, e.value))
          .catch((error) => console.error(error));
      } else {
        CoreBackendApi.joinGroupUser(selectedUser.id, e.value)
          .catch((error) => console.error(error));
      }
    }
  };
  
  useEffect(() => {
    const updateGroupsAndUsers = () => {      
      CoreBackendApi.listGroups()
        .then((squidGroups) => {
          setGroups(squidGroups);
        })
        .then(() => listUsers())
        .catch((error) => console.error(error));
    };
    updateGroupsAndUsers();
  }, []);

  return (
    <PortalUsersPresentation
      isAdmin={isAdmin}
      tokens={tokens}
      showWindowFormUser={showWindowFormUser}
      groups={groups}
      users={users}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      onAddUser={onAddUser}
      onDeleteUser={onDeleteUser}
      onProfileChange={onProfileChange}
      onCloseWindowFormUser={onCloseWindowFormUser}
      onUsersListUpdated={onUsersListUpdated}
    />
  );
};

PortalUsersContainer.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  tokens: PropTypes.object.isRequired,
};

PortalUsersContainer.defaultProps = {};

export default PortalUsersContainer;
