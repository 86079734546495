import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  Dropdown,
  ListItem,
  IconButton,
} from "squid-ui-kit";
import LocalApi from "../../config/LocalApi";
import UsersPresentation from "./Users.presentation";

const UsersContainer = ({
  tokens,
  groups,
  users,
  setSearchTerm,
  onAdd,
  onDelete,
  onProfileChange,
})=>{

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [warning, setWarning] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);
  const data = users;

  let groupsComponents = groups.map((group) => {
    return <ListItem key={group.id} title={group.name} subtitle="" />;
  });

  const search = (value) =>{
    setSearchValue(value);
    setSearchTerm(value);
  }

  // Search related
  const onSearchCb = (e) => search(e.value);
  const onSearchClear = (e) => search("");
  const onSearchChange = (e) => search(e.value);
  const getCurrentPage = (page) => setCurrentPage(page);
  const setItemsPerPageCb = (e) => setItemsPerPage(parseInt(e.value));

  // Check logged user
  const itsMe = (user) => {
    const me = LocalApi.getUser();
    return me.id === user.id
  }

  // Render methods
  const renderUser = (text, record, index) => {
    return <TableCell>{text}</TableCell>;
  }
  const renderEmail = (text, record, index) => {
    return <TableCell>{text}</TableCell>;
  }
  const renderKey = (text, record, index) => {
    return <TableCell>{text}</TableCell>;
  }
  const renderProfile = (text, record, index) => {
    let profile;
    if (record.groups && record.groups.length > 0) {
      profile = record.groups[0].id;
    }
    return (
      <TableCell
        horizontalAlign="center"
        overflow="unset"
      >
        <Dropdown
          disabled={itsMe(record)}
          placeholder="Perfil"
          name={record.id}
          width="100%"
          size="small"
          selectedKey={profile}
          onChange={onProfileChange}
        >
          {groupsComponents}
        </Dropdown>
      </TableCell>
    );
  }
  const renderActions = (text, record, index) =>{
    return (
      <TableCell horizontalAlign="center">
        <IconButton
          disabled={itsMe(record)}
          icon="delete"
          onClick={() => onDeleteRow(index)}
        />
      </TableCell>
    );
  }

  // Delete methods
  const onDeleteRow = (row) => {
    if (onDelete) {
      // Compute the index for filteredUsers
      const filterIndex = itemsPerPage * currentPage + parseInt(row);

      // Get the user from filteredUsers
      const user = filteredUsers[filterIndex];

      // Find the corresponding user index in data
      const dataIndex = data.findIndex((u) => u.petrobrasKey === user.petrobrasKey);

      // Update the state
      setWarning(`Deseja realmente remover o usuário ${user.name} (${user.petrobrasKey}) ?`);
      setRowToDelete(dataIndex);
    }
  }
  const onConfirmDelete = () => {
    if (onDelete) {
      onDelete(users[rowToDelete]);
      setWarning("");
      setRowToDelete(null);
    }
  }
  const onCancelDelete = () => {
    setWarning("");
    setRowToDelete(null);
  }

  const columns = [
    {
      dataIndex: "name",
      label: "NOME",
      flex: "1",
      searchable: false,
      cellRenderer: renderUser,
      selected: false,
    },
    {
      dataIndex: "email",
      label: "EMAIL",
      flex: "2",
      searchable: false,
      cellRenderer: renderEmail,
      selected: false,
    },
    {
      dataIndex: "petrobrasKey",
      label: "CHAVE",
      width: "150px",
      searchable: false,
      cellRenderer: renderKey,
      selected: false,
    },
    {
      dataIndex: "profile",
      label: "PERFIL",
      width: "150px",
      searchable: false,
      cellRenderer: renderProfile,
      selected: false,
    },
    {
      dataIndex: "actions",
      label: "AÇÕES",
      width: "80px",
      searchable: false,
      cellRenderer: renderActions,
      selected: false,
    },
  ];

  // Filtered users
  useEffect(()=>{
    const filtered = users.filter((user) => {
      if (
           user.name.toUpperCase().includes(searchValue.toUpperCase())
        || user.petrobrasKey.toUpperCase().includes(searchValue.toUpperCase())
      ) {
        return true;
      }
      if (user.email) {
        if (user.email.toUpperCase().includes(searchValue.toUpperCase())) {
          return true;
        }
      }
      return false;
    });
    setFilteredUsers(filtered);
  }, [users, searchValue])

  return (
    <UsersPresentation
      warning={warning}
      tokens={tokens}
      users={filteredUsers}
      columns={columns}
      onConfirmDelete={onConfirmDelete}
      onCancelDelete={onCancelDelete}
      onAdd={onAdd}
      onSearch={onSearchCb}
      onSearchClear={onSearchClear}
      onSearchChange={onSearchChange}
      searchValue={searchValue}
      getCurrentPage={getCurrentPage}
      itemsPerPage={itemsPerPage}
      setItemsPerPage={setItemsPerPageCb}
    />
  );
}

UsersContainer.propTypes = {
  tokens: PropTypes.object.isRequired,
  groups: PropTypes.array,
  users: PropTypes.array,
  setSearchTerm: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onProfileChange: PropTypes.func,
};

UsersContainer.defaultProps = {
  groups: [],
  users: [],
};

export default UsersContainer;
