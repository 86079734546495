import React from "react";
import NotificationsPresentation from "./Notifications.presentation";
import PropTypes from "prop-types";
import subwebCoreApi from "../../../config/SubwebCoreApi";
class NotificationsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.tabs = {
      NOTIFICATIONS: "notifications",
      UPDATES: "updates",
    };

    this.state = {
      loadingCount: false,
      selectedTab: this.tabs.UPDATES,
      unreadCount: null,
    };

    this.handleClickTab = ({ tab }) => {
      this.setState({ selectedTab: tab });
    };

    this.appSlugToName = this.props.apps?.reduce((acc, app) => {
      if (app.slug) acc[app.slug] = app.label;
      return acc;
    }, {});

    // hardcoded app slug to name mapping
    this.appSlugToName["sbwb-core"] = "Portal SUBWEB";

    this.getNotificationsCounts = () => {
      const setLoading = (loading) => {
        this.setState({ loadingCount: loading });
      };

      const onSuccess = (data) => {
        this.setState({ unreadCount: data.unread });
      };
      subwebCoreApi.getNotificationsCounts(onSuccess, setLoading);
    };
  }

  componentDidMount() {
    this.getNotificationsCounts();
  }

  render() {
    return (
      <NotificationsPresentation
        selectedTab={this.state.selectedTab}
        tabs={this.tabs}
        handleClickTab={this.handleClickTab}
        appSlugToName={this.appSlugToName}
        unreadCount={this.state.unreadCount}
        getNotificationsCounts={this.getNotificationsCounts}
        loadingCount={this.state.loadingCount}
      />
    );
  }
}

NotificationsContainer.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.object),
};

export default NotificationsContainer;
