/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Spacer,
  Alert,
  tokens,
} from 'squid-ui-kit';

import Applications from '../Applications';
import WindowFormLocalApplication from '../Applications/components/WindowFormLocalApplication';
import WindowFormApplication from '../Applications/WindowFormApplication';

class PortalAppsPresentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmRemove: null,
      appsReloadCounter: 0,
    };

    this.updateApplicationList = () => this.props.onUpdateApps();

    this.onRemove = (name) => {
      if (this.props.onRemoveLocalApplication) {
        const confirmRemove = (
          <Alert
            hasBorder
            bodyType="content"
            padding={tokens.PrimaryPadding}
            message={
              (
                <Container
                  width="100%"
                  height="100%"
                  direction="vertical"
                  verticalAlign="top"
                  horizontalAlign="left"
                  bodyType="neutral"
                >
                  Deseja realmente remover a aplicação local?
                </Container>
              )
            }
            confirm
            cancelLabel="Cancelar"
            confirmLabel="Sim"
            onCancel={() => {
              this.setState({
                confirmRemove: null,
              });
            }}
            onClose={() => {
              this.setState({
                confirmRemove: null,
              });
            }}
            onConfirm={() => {
              this.props.onRemoveLocalApplication(name);
              this.setState({
                confirmRemove: null,
              });
            }}
            title="Remover Aplicação Local"
          />
        );
        this.setState({ confirmRemove });
      }
    };
  }

  render() {
    const applicationsComponent = (
      <Applications
        key={`apps-component-reload-${this.state.appsReloadCounter}`}
        isAdmin={this.props.isAdmin}
        onAdd={this.props.onAddApplication}
        localApps={this.props.localApps}
        onAddLocal={this.props.onAddLocalApplication}
        onEditLocal={this.props.onEditLocalApplication}
        // App management
        onSelectApplication={this.props.onSelectApplication}
      />
    );

    let windowFormApplication;
    if (this.props.showWindowFormApplication === true) {
      windowFormApplication = (
        <WindowFormApplication
          key="window-form-application-key"
          tokens={this.props.tokens}
          application={this.props.selectedApplication}
          onApplicationUpdated={this.props.onApplicationUpdated}
          onClose={this.props.onCloseWindowFormApplication}
        />
      );
    }

    let windowFormLocalApplication;
    if (this.props.showWindowFormLocalApplication === true) {
      windowFormLocalApplication = (
        <WindowFormLocalApplication
          tokens={this.props.tokens}
          mode={this.props.localApp ? 'edit' : 'create'}
          name={this.props.localApp ? this.props.localApp.name : ''}
          url={this.props.localApp ? this.props.localApp.url : ''}
          params={this.props.localApp ? this.props.localApp.params : ''}
          onClose={this.props.onCloseWindowFormLocalApplication}
          onSave={this.props.onSaveLocalApplication}
          onRemove={this.onRemove}
        />
      );
    }

    return (
      <Container
        width="100%"
        height="100%"
        direction="vertical"
        verticalAlign="top"
        bodyType="primary"
        margin="0px"
        padding="10px"
      >
        {applicationsComponent}
        <Spacer direction="vertical" size="18px" />
        {windowFormApplication}
        {windowFormLocalApplication}
        {this.state.confirmRemove}
      </Container>
    );
  }
}

PortalAppsPresentation.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  tokens: PropTypes.object.isRequired,
  // Apps management
  selectedApplication: PropTypes.object,
  onUpdateApps: PropTypes.func.isRequired,
  onAddApplication: PropTypes.func.isRequired,
  onSelectApplication: PropTypes.func.isRequired,
  onApplicationUpdated: PropTypes.func.isRequired,
  // Local apps
  localApp: PropTypes.object,
  localApps: PropTypes.array,
  onAddLocalApplication: PropTypes.func.isRequired,
  onSaveLocalApplication: PropTypes.func.isRequired,
  onEditLocalApplication: PropTypes.func.isRequired,
  onRemoveLocalApplication: PropTypes.func.isRequired,
  // Modal
  showWindowFormApplication: PropTypes.bool,
  onCloseWindowFormApplication: PropTypes.func.isRequired,
  showWindowFormLocalApplication: PropTypes.bool,
  onCloseWindowFormLocalApplication: PropTypes.func.isRequired,
};

PortalAppsPresentation.defaultProps = {
  selectedApplication: null,
  localApp: null,
  localApps: [],
  // Modals
  showWindowFormLocalApplication: false,
  showWindowFormApplication: false,
};

export default PortalAppsPresentation;
