/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import ls from 'local-storage';

import PortalAppsPresentation from './PortalApps.presentation';

class PortalAppsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showWindowFormLocalApplication: false,
      localApp: null,
      localApps: [],
      // App management
      showWindowFormApplication: false,
      selectedApplication: null,
    };

    this.onAddApplication = () => {
      this.setState({
        showWindowFormApplication: true,
        selectedApplication: null,
      });
    };
    this.onAddLocalApplication = () => {
      this.setState({
        showWindowFormLocalApplication: true,
      });
    };
    this.onEditLocalApplication = (localApp) => {
      this.setState({
        showWindowFormLocalApplication: true,
        localApp,
      });
    };
    this.onRemoveLocalApplication = (name) => {
      let localApps;
      if (this.state.localApps && this.state.localApps.length > 0) {
        Object.keys(this.state.localApps).forEach((index) => {
          if (this.state.localApps[index].name !== name) {
            if (!localApps) {
              localApps = [];
            }
            localApps.push(this.state.localApps[index]);
          }
        });
      }
      if (localApps) {
        ls.set("local-apps", localApps);
      } else {
        ls.remove("local-apps");
      }
      this.setState(
        {
          showWindowFormLocalApplication: false,
          localApps: localApps,
          localApp: null,
        },
        () => {
          this.props.onUpdateApps();
        }
      );
    };
    this.onCloseWindowFormLocalApplication = () => {
      this.setState({
        showWindowFormLocalApplication: false,
        localApp: null,
      });
    };
    this.onCloseWindowFormApplication = () => {
      this.setState({
        showWindowFormApplication: false,
        selectedApplication: undefined,
      });
    };
    this.onSelectApplication = (app) => {
      this.setState({
        showWindowFormApplication: true,
        selectedApplication: app,
      });
    };
    this.onSaveLocalApplication = (localApp) => {
      let localApps = ls.get('local-apps');
      if (localApps && localApps.length > 0) {
        let novo = true;
        Object.keys(localApps).forEach((index) => {
          if (localApps[index].name === localApp.name) {
            localApps[index] = localApp;
            novo = false;
          }
        });
        if (novo === true) {
          localApps.push(localApp);
        }
      } else {
        localApps = [localApp];
      }
      ls.set('local-apps', localApps);
      this.setState(
        {
          showWindowFormLocalApplication: false,
          localApps,
          localApp: null,
        },
        () => this.props.onUpdateApps(),
      );
    };
    this.onApplicationUpdated = () =>{
      this.setState({
        showWindowFormApplication: false,
        selectedApplication: null,
      });
      this.props.onUpdateApps();
    }
  }

  componentDidMount() {
    const localApps = ls.get('local-apps');
    if (localApps) {
      // NOTE trabalhar o local-apps como um array
      this.setState({
        localApps,
      });
    }
  }

  render() {
    return (
      <PortalAppsPresentation
        isAdmin={this.props.isAdmin}
        tokens={this.props.tokens}
        // Apps management
        selectedApplication={this.state.selectedApplication}
        onUpdateApps={this.props.onUpdateApps}
        onAddApplication={this.onAddApplication}
        onSelectApplication={this.onSelectApplication}
        onApplicationUpdated={this.onApplicationUpdated}
        // Local apps
        localApp={this.state.localApp}
        localApps={this.state.localApps}
        onAddLocalApplication={this.onAddLocalApplication}
        onSaveLocalApplication={this.onSaveLocalApplication}
        onEditLocalApplication={this.onEditLocalApplication}
        onRemoveLocalApplication={this.onRemoveLocalApplication}
        // Modal
        showWindowFormApplication={this.state.showWindowFormApplication}
        onCloseWindowFormApplication={this.onCloseWindowFormApplication}
        showWindowFormLocalApplication={this.state.showWindowFormLocalApplication}
        onCloseWindowFormLocalApplication={this.onCloseWindowFormLocalApplication}
      />
    );
  }
}

PortalAppsContainer.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  tokens: PropTypes.object.isRequired,
  onUpdateApps: PropTypes.func.isRequired,
};

PortalAppsContainer.defaultProps = {
};

export default PortalAppsContainer;
