import React from "react";

import { Container, Text, Icon, tokens } from "squid-ui-kit";

import {
  SelectedApplication,
  AccessInformationCard,
  AuthorizationTable,
} from "./components";

import {
  ApplicationList,
  AttachUserModal,
  ExclusionAlert,
} from "../../components";

const ApplicationManagement = ({
  loadingList,
  loadingApplicationInformation,

  applicationsList,
  managersList,
  applicationInformation,

  selectedApplication,
  setSelectedApplication,

  detachManager,
  onUpdateQuery,

  showAddManagerModal,
  onShowAddManagerModal,
  onAttachManager,

  onSelectedManager,
  selectedManager,
  warningMessage,
  onAcceptWarning,

  appAuthorizations,
  onChangeAuthorizationAccess,
  showAddUserAuthorizationModal,
  onShowAddUserAuthorizationModal,

  authorizedUsers,
  commonUserAuthorizationRole,
  onUpdateAuthorizedUsersListQuery,
  updateAuthorizedUsersList,

  isAdmin,
}) => {
  let mainContent;
  if (selectedApplication) {
    if (loadingApplicationInformation) {
      mainContent = (
        <Container
          width="100%"
          height="100%"
          bodyType="primary"
          progressLabel="Carregando..."
          progress={-1}
        />
      );
    } else {
      mainContent = (
        <Container
          width="100%"
          flex={appAuthorizations?.appProtected === true ? "4" : "1"}
          bodyType="primary"
        >
          <SelectedApplication
            applicationInformation={applicationInformation}
            selectedApplication={selectedApplication}
            onShowAddManagerModal={onShowAddManagerModal}
            managersList={managersList}
            loadingApplicationInformation={loadingApplicationInformation}
            onSelectedManager={onSelectedManager}
            isAdmin={isAdmin}
          />

          {appAuthorizations?.appProtected === true && isAdmin && (
            <Container
              width="100%"
              flex="2"
              bodyType="content"
              padding="5px 0px"
            >
              <AuthorizationTable
                authorizedUsers={authorizedUsers}
                loading={loadingApplicationInformation}
                applicationID={selectedApplication.id}
                isAdmin={isAdmin}
                commonUserAuthorizationRole={commonUserAuthorizationRole}
                onUpdateQuery={onUpdateAuthorizedUsersListQuery}
                updateAuthorizedUsersList={updateAuthorizedUsersList}
              />
            </Container>
          )}
        </Container>
      );
    }
  } else {
    mainContent = (
      <Container
        verticalAlign="center"
        horizontalAlign="center"
        width="100%"
        height="100%"
        bodyType="neutral"
        direction="horizontal"
      >
        <Icon icon="info" color={tokens.ColorPrimary} />
        <Text invertedColors>
          Selecione a aplicação para definir seus gestores ...
        </Text>
      </Container>
    );
  }

  return (
    <>
      <Container
        width="100%"
        height="100%"
        bodyType="neutral"
        direction="horizontal"
        gap="10px"
      >
        <ApplicationList
          applicationsList={applicationsList}
          onUpdateQuery={onUpdateQuery}
          onSelectApplication={setSelectedApplication}
          loading={loadingList}
          loadingApplicationInformation={loadingApplicationInformation}
          selectedApplication={selectedApplication}
          panelLabel="APLICAÇÕES DO PORTAL"
        />

        <Container
          padding="10px"
          bodyType="content"
          direction="vertical"
          height="100%"
          flex="1"
        >
          {mainContent}
          <Container
            height="fit-content"
            width="100%"
            bodyType="neutral"
            verticalAlign="bottom"
          >
            {appAuthorizations?.appProtected !== undefined && (
              <AccessInformationCard
                isApplicationLocked={appAuthorizations?.appProtected}
                isAdmin={isAdmin}
                appAuthorizations={appAuthorizations}
                onChangeAuthorizationAccess={onChangeAuthorizationAccess}
              />
            )}
          </Container>
        </Container>
      </Container>

      {(showAddManagerModal || showAddUserAuthorizationModal) && (
        <AttachUserModal
          onClose={
            showAddUserAuthorizationModal
              ? onShowAddUserAuthorizationModal
              : onShowAddManagerModal
          }
          onSave={onAttachManager}
          title={
            showAddUserAuthorizationModal
              ? "Adicionar Usuário"
              : "Adicionar novo Gestor"
          }
        />
      )}

      {selectedManager && (
        <ExclusionAlert
          exclusionText={`Deseja realmente remover o usuário ${selectedManager} da aplicação ${applicationInformation.name}?`}
          title="Remover Gestor"
          onCancel={() => onSelectedManager(undefined)}
          onConfirm={() => {
            detachManager(selectedManager);
            onSelectedManager(undefined);
          }}
        />
      )}

      {warningMessage && (
        <ExclusionAlert
          exclusionText={warningMessage}
          title="Alerta"
          onCancel={() => onAcceptWarning(undefined)}
          onlyConfirmation
        />
      )}
    </>
  );
};

export default ApplicationManagement;
