import React from "react";
import PropTypes from "prop-types";
import Color from "color";

import LogoLccv from "../../assets/logo-lccv.svg";
import LogoPetrobras from "../../assets/logo-petrobras.svg";

import { Container, Panel, Spacer, tokens } from "squid-ui-kit";

import AppButton from "../AppButton";
import Notifications from "./Notifications/Notifications.container";
import { Avatar } from "../../components";

const filterUserApplications = (appList) => {
  let appListFiltered = appList.filter((app) => {
    const visible = app?.visible === false ? false : true;

    if (
      app.index !== "home" &&
      app.index !== "setup" &&
      app.index !== "help" &&
      app.index !== "config" &&
      visible
    ) {
      return true;
    } else {
      return false;
    }
  });

  return appListFiltered;
};

class HomePresentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onClickApp(index) {
    if (this.props.onClickApp) {
      this.props.onClickApp(index);
    }
  }

  onClickBasicData(index) {
    if (this.props.onClickBasicData) {
      this.props.onClickBasicData(index);
    }
  }

  render() {
    let apps;
    if (this.props.apps) {
      apps = filterUserApplications(this.props.apps);

      apps = apps.map((app) => {
        return (
          <AppButton
            key={`${app.index}${app.name}${app.label}`}
            tokens={this.props.tokens}
            index={app.index}
            label={app.label}
            icon={app.icon}
            onClick={this.onClickApp.bind(this, app.index)}
            handleAuthorizationModal={this.props.handleAuthorizationModal}
          />
        );
      });
    }

    let basicDataAppsContainer;
    if (this.props.basicDataApps) {
      let basicDataApps = filterUserApplications(this.props.basicDataApps);

      basicDataApps = basicDataApps.map((app) => {
        return (
          <AppButton
            key={`${app.index}${app.name}${app.label}`}
            tokens={this.props.tokens}
            index={app.index}
            label={app.label}
            icon={app.icon}
            onClick={this.onClickBasicData.bind(this, app.index)}
            handleAuthorizationModal={this.props.handleAuthorizationModal}
          />
        );
      });

      basicDataAppsContainer = (
        <>
          <Spacer direction="vertical" size={tokens.PrimaryPadding} />
          <Panel
            label="Dados Básicos"
            flex="1"
            width="100%"
            // height="100%"
            direction="horizontal"
            verticalAlign="top"
            bodyType="default"
            wrap="wrap"
            scroll="vertical"
            // padding={`${parseFloat(tokens.PrimaryPadding) * 2}rem`}
            padding={tokens.PrimaryPadding}
            space={`${parseFloat(tokens.PrimaryPadding) * 2}rem`}
          >
            {basicDataApps}
          </Panel>
        </>
      );
    }

    return (
      <Container
        width="100%"
        height="100%"
        direction="vertical"
        verticalAlign="top"
        bodyType="neutral"
        margin="0px"
        padding={tokens.PrimaryPadding}
      >
        <Container
          width="100%"
          height="100%"
          direction="horizontal"
          verticalAlign="top"
          bodyType="neutral"
          margin="0px"
          padding="0px"
          // scroll="nenhum"
        >
          <Container
            width="320px"
            height="100%"
            direction="vertical"
            verticalAlign="top"
            bodyType="neutral"
            margin="0px"
            padding="0px"
          >
            <Container
              width="100%"
              height="85px"
              direction="horizontal"
              verticalAlign="top"
              margin="0px"
              padding="0px"
            >
              <Avatar width="85px" height="85px" src={this.props.avatar} />
              <Container
                flex="1"
                height="100%"
                direction="vertical"
                verticalAlign="top"
                bodyType="neutral"
                margin="0px"
                padding="0px"
              >
                <div
                  style={{
                    width: "100%",
                    height: "24px",
                    backgroundColor: Color(this.props.tokens.ColorWhite).alpha(
                      0.05
                    ),
                    fontFamily: this.props.tokens.FontControlDefaultFamily,
                    fontWeight: this.props.tokens.FontControlDefaultWeight,
                    fontSize: this.props.tokens.FontControlDefaultSize,
                    color: Color(this.props.tokens.ColorWhite).alpha(0.8),
                    padding: "10px 0px 0px 15px",
                  }}
                >
                  {this.props.name}
                </div>
                <Container
                  width="200px"
                  height="auto"
                  direction="horizontal"
                  horizontalAlign="left"
                  verticalAlign="center"
                  bodyType="neutral"
                  margin="0px"
                  padding="10px 0px 0px 15px"
                >
                  <span
                    style={{
                      fontFamily: this.props.tokens.FontBodyActionPrimaryFamily,
                      fontWeight: this.props.tokens.FontBodyActionPrimaryWeight,
                      fontSize: this.props.tokens.FontBodyActionPrimarySize,
                      color: Color(this.props.tokens.ColorWhite).alpha(0.8),
                    }}
                  >
                    Caso esse não seja o seu usuário{" "}
                    <span
                      style={{
                        fontFamily:
                          this.props.tokens.FontBodyActionPrimaryFamily,
                        fontWeight:
                          this.props.tokens.FontBodyActionPrimaryWeight,
                        fontSize: this.props.tokens.FontBodyActionPrimarySize,
                        color: Color(this.props.tokens.ColorWhite).alpha(0.6),
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={this.props.onLogout}
                    >
                      clique aqui.
                    </span>
                  </span>
                </Container>
              </Container>
            </Container>
            <Spacer direction="vertical" size={tokens.PrimaryPadding} />
            <Notifications
              apps={[...this.props.apps, ...this.props.basicDataApps]}
            />
          </Container>
          <Spacer size={tokens.PrimaryPadding} />
          <Container
            flex="1"
            height="100%"
            direction="vertical"
            verticalAlign="top"
            bodyType="neutral"
            margin="0px"
            padding="0px"
          >
            <Panel
              label="Aplicações"
              flex="1"
              width="100%"
              // height="100%"
              direction="horizontal"
              verticalAlign="top"
              bodyType="default"
              wrap="wrap"
              scroll="vertical"
              // padding={`${parseFloat(tokens.PrimaryPadding) * 2}rem`}
              padding={tokens.PrimaryPadding}
              space={`${parseFloat(tokens.PrimaryPadding) * 2}rem`}
            >
              {apps}
            </Panel>
            {basicDataAppsContainer}
          </Container>
        </Container>
        <Spacer direction="vertical" size={tokens.PrimaryPadding} />
        <Container
          width="100%"
          height="auto"
          direction="horizontal"
          verticalAlign="center"
          horizontalAlign="center"
          bodyType="neutral"
          margin="0px"
          padding="0px"
        >
          <img
            alt=""
            style={{
              filter: `drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3))`,
            }}
            src={LogoLccv}
          />
          <Spacer size="20px" />
          <img
            alt=""
            style={{
              filter: `drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3))`,
            }}
            src={LogoPetrobras}
          />
        </Container>
      </Container>
    );
  }
}

HomePresentation.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.object),
  basicDataApps: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  avatar: PropTypes.string,
  onClickApp: PropTypes.func,
  onLogout: PropTypes.func,
  handleAuthorizationModal: PropTypes.func,
};

HomePresentation.defaultProps = {
  apps: [],
  name: "",
  avatar: "",
};

export default HomePresentation;
