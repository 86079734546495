import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CoreBackendApi from '../../config/CoreBackendApi'
import LocalApi from '../../config/LocalApi'

import LoginPresentation from "./Login.presentation";


const LoginContainer = (props) => {

    const [error, setError] = useState();

    const onLogin = (user) => {
        if (
            user.user &&
            user.user !== '' &&
            user.password &&
            user.password !== ''
        ) {
            CoreBackendApi.authenticate(user.user, user.password)
                .then((token) => {
                    if (props.onLogged) {
                        CoreBackendApi.fetchMe(token)
                            .then((me) => {
                                LocalApi.setUser(JSON.stringify({
                                    avatar: me.avatar,
                                    email: me.email,
                                    groups: me.groups,
                                    id: me.id,
                                    name: me.name,
                                }))
                                props.onLogged(token)
                            })
                            .catch((err) => {
                                console.log('+++++++++++ ERROR CALLBACK +++++++++++', err);
                            })
                    }
                })
                .catch((err) => {
                    console.log('+++++++++++ ERROR CALLBACK +++++++++++', err);
                    setError('Não foi possível realizar a autenticação. Verifique suas credencias.');
                })
        }
    }

    const onClear = () => setError('');

    return (
        <LoginPresentation
            error={error}
            tokens={props.tokens}
            onClear={onClear}
            onLogin={onLogin}
            showExpirationAlert={props.showExpirationAlert}
            onCloseExpirationAlert={props.onCloseExpirationAlert}
        />
    );
}

LoginContainer.propTypes = {
    tokens: PropTypes.object,
    onLogged: PropTypes.func,
    showExpirationAlert: PropTypes.bool,
    onCloseExpirationAlert: PropTypes.func,
}

LoginContainer.defaultProps = {
    showExpirationAlert: false,
}

export default LoginContainer;
