import React from "react";
import {
  Container,
  Text,
  Table,
  Panel,
  TableCell,
  Icon,
  IconButton,
  Tooltip,
} from "squid-ui-kit";
import NewUserInfoCard from "../../../Users/components/NewUserInfoCard";

const cellRendererUser = (record) => {
  let content = <></>;
  if (record.status === "REVOKED") {
    content = (
      <TableCell
        verticalAlign="center"
        horizontalAlign="center"
        fontBodySize="small"
        height="65px"
        bodyType="neutral"
      >
        <s>{record.requesterName}</s>
      </TableCell>
    );
  } else {
    content = <NewUserInfoCard petrobrasKey={record.petrobrasKey} />;
  }
  return content;
};

const cellRendererActions = (record, onGrant, onRevoke, onRemove) => (
  <TableCell verticalAlign="center" horizontalAlign="center" height="65px">
    <Tooltip placement="left" title="Conceder acesso">
      <IconButton
        size="small"
        icon="checkmark"
        onClick={() => onGrant(record.petrobrasKey)}
      />
    </Tooltip>
    <Tooltip placement="left" title="Negar acesso">
      <IconButton
        size="small"
        icon="delete"
        onClick={() => onRevoke(record.petrobrasKey)}
      />
    </Tooltip>
    <Tooltip placement="left" title="Remover">
      <IconButton
        size="small"
        icon="trash"
        onClick={() => onRemove(record.accessRequestId)}
      />
    </Tooltip>
  </TableCell>
);

const AccessRequests = ({
  loading,
  accessRequests,
  onGrant,
  onRevoke,
  onRemove,
  errorMessage,
  setErrorMessage,
}) => {
  if (loading) {
    return (
      <Container
        width="350px"
        height="100%"
        bodyType="neutral"
        space="1px"
        verticalAlign="center"
        horizontalAlign="center"
        direction="horizontal"
        progress={-1}
        progressLabel="Atualizando solicitações de acesso"
      ></Container>
    );
  }

  if (accessRequests.length === 0) {
    return <></>;
  }

  const tableColumns = [
    {
      label: "REQUERENTE",
      dataIndex: "requesterName",
      flex: "2",
      cellRenderer: (_item, record) => cellRendererUser(record),
    },
    {
      label: "AÇÕES",
      dataIndex: "accessRequestId",
      width: "100px",
      cellRenderer: (_item, record) =>
        cellRendererActions(record, onGrant, onRevoke, onRemove),
    },
  ];

  let errMsg = undefined;
  if (errorMessage) {
    errMsg = (
      <Container
        width="100%"
        padding="5px"
        bodyType="danger"
        horizontalAlign="center"
        verticalAlign="center"
        direction="horizontal"
        onClick={() => setErrorMessage(undefined)}
      >
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "280px",
          }}
        >
          <Text>{errorMessage}</Text>
        </div>
        <Icon icon="close" />
      </Container>
    );
  }

  return (
    <Panel
      width="350px"
      height="100%"
      label="Solicitações de acesso"
      bodyType="neutral"
    >
      {errMsg}
      <Table
        width="100%"
        height="100%"
        size="small"
        cellShowBorderBottom
        columns={tableColumns}
        dataSource={accessRequests}
      />
    </Panel>
  );
};

export default AccessRequests;
