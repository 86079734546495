import React, { useState } from "react";

import RolesTable from "./permittedApplicationsTable.component";
import ConfigApi from "../../../../config/SquidAuth";

function PermittedApplicationsTableContainer({
  authorizedAppsList,

  loading,

  applicationID,

  updateAuthorizedAppsList,

  isAdmin,
}) {
  const [loadingList, setLoadingList] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [rowToDelete, setRowToDelete] = useState(undefined);

  const deleteRow = (id) => {
    if (rowToDelete) {
      setRowToDelete(undefined);
    } else {
      setRowToDelete(id);
    }
  };

  const confirmDeletion = async () => {
    try {
      setLoadingList(true);

      await ConfigApi.detachAuthorizedApp(applicationID, rowToDelete, setError);

      await updateAuthorizedAppsList();
    } catch (err) {
      setLoadingList(false);
    } finally {
      setRowToDelete(undefined);
      setLoadingList(false);
    }
  };

  const handleError = () => {
    setError(undefined);
  };

  return (
    <RolesTable
      authorizedAppsList={authorizedAppsList}
      loading={loading || loadingList}
      rowToDelete={rowToDelete}
      deleteCallback={deleteRow}
      confirmDeletion={confirmDeletion}
      error={error}
      handleError={handleError}
      isAdmin={isAdmin}
    />
  );
}

export default PermittedApplicationsTableContainer;
