import React, { useState, useEffect } from "react";

import ApplicationManagement from "./ApplicationManagement.presentation";
import ConfigApi from "../../config/SquidAuth";

function ApplicationContainer() {
  const [loadingApplicationList, setLoadingApplicationList] = useState(false);
  const [loadingApplicationInformation, setLoadingApplicationInformation] =
    useState(false);
  const [warningMessage, setWarningMessage] = useState(undefined);

  const [selectedApplication, setSelectedApplication] = useState(undefined);
  const [selectedManager, setSelectedManager] = useState(undefined);

  const [applicationsList, setApplicationsList] = useState([]);
  const [protectedApplications, setProtectedApplications] = useState([]);
  const [applicationInformation, setApplicationInformation] = useState([]);

  const [managersList, setManagersList] = useState([]);
  const [
    applicationRolesAndUserInformation,
    setApplicationRolesAndUserInformation,
  ] = useState({});
  const [appsListQueriedResult, setAppsListQueriedResult] = useState([]);

  const [query, setQuery] = useState("");
  const [showAddManagerModal, setShowAddManagerModal] = useState(false);
  const [isPortalAdmin, setIsPortalAdmin] = useState(false);
  const [isApplicationAdmin, setIsApplicationAdmin] = useState(false);

  const [appAuthorizations, setAppAuthorizations] = useState(undefined);
  const [showAddUserAuthorizationModal, setShowAddUserAuthorizationModal] =
    useState(false);

  const [rolesList, setRolesList] = useState([]);
  const [authorizedUsers, setAuthorizedUsers] = useState([]);
  const [commonUserAuthorizationRole, setCommonUserAuthorizationRole] =
    useState(undefined);
  const [authorizedUsersListQuery, setAuthorizedUsersListQuery] = useState("");
  const [authorizedUsersQueriedResult, setAuthorizedUsersQueriedResult] =
    useState([]);

  const onUpdateQuery = (v) => {
    setQuery(v.value);
  };

  const onShowAddManagerModal = () => {
    if (selectedApplication) {
      setShowAddManagerModal(!showAddManagerModal);
    }
  };

  const onShowAddUserAuthorizationModal = () => {
    if (selectedApplication) {
      setShowAddUserAuthorizationModal(!showAddUserAuthorizationModal);
    }
  };

  const onAttachManager = async (userKey, sucess, fail) => {
    try {
      await ConfigApi.addManager(selectedApplication.id, userKey, sucess, fail);
      await ConfigApi.getAppManagers(selectedApplication.id, setManagersList);
    } catch (error) {
      fail(error);
    }
  };

  const onChangeApplication = (application) => {
    setAppAuthorizations(undefined);
    setSelectedApplication(application);
  };

  const onChangeAuthorizationAccess = async (isEnabled) => {
    if (selectedApplication.id) {
      try {
        await ConfigApi.changeAuthorizationMode(
          selectedApplication.id,
          isEnabled
        );
        await ConfigApi.getAppAuthorizations(
          selectedApplication.id,
          setAppAuthorizations
        );
      } catch (error) {
        console.log("Error when changing Authorization access");
      }
    }
  };

  const updateAuthorizedUsersList = async () => {
    try {
      setLoadingApplicationList(true);

      if (commonUserAuthorizationRole) {
        await ConfigApi.listUsersByRole(
          commonUserAuthorizationRole.id,
          setAuthorizedUsers
        );
      }
    } catch (error) {
      setWarningMessage("Erro ao obter dados do servidor ...");
    } finally {
      setLoadingApplicationList(false);
    }
  };

  const onUpdateAuthorizedUsersListQuery = (v) => {
    setAuthorizedUsersListQuery(v.value);
  };

  useEffect(() => {
    if (query === "") {
      setAppsListQueriedResult(
        applicationsList.sort(function (a, b) {
          return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
        })
      );
    } else {
      const appsListQueriedResult = applicationsList.filter((app) =>
        app.name.toLowerCase().includes(query.toLowerCase())
      );
      setAppsListQueriedResult(appsListQueriedResult);
    }
  }, [query, applicationsList]);

  useEffect(() => {
    const fetchApplicationList = async () => {
      try {
        setLoadingApplicationList(true);
        await ConfigApi.listApplications(setApplicationsList);
        ConfigApi.listApplicationsProtected(setProtectedApplications);
      } catch {
        setWarningMessage("Erro ao obter dados do servidor ...");
      } finally {
        setLoadingApplicationList(false);
      }
    };

    fetchApplicationList();
  }, []);

  useEffect(() => {
    const verifyUserHasPermissions = async () => {
      if (selectedApplication) {
        try {
          setLoadingApplicationList(true);
          // Portal admin
          if (applicationRolesAndUserInformation.userIsPortalAdmin)
            setIsPortalAdmin(true);
          // Application manager
          const managerRoleId = "ns1/" + selectedApplication.id + "/manager";
          const managerRolesOfApplication =
            applicationRolesAndUserInformation.appRoles.find(
              (role) => role.id === managerRoleId
            );
          if (managerRolesOfApplication) {
            const roleFound =
              applicationRolesAndUserInformation.userRoles.filter(
                (role) => role.id === managerRolesOfApplication.id
              );
            setIsApplicationAdmin(roleFound.length === 1);
          }
        } catch {
          setWarningMessage("Erro ao obter dados do servidor ...");
          setLoadingApplicationList(false);
        } finally {
          setLoadingApplicationList(false);
        }
      }
    };

    verifyUserHasPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationRolesAndUserInformation]);

  useEffect(() => {
    const fetchApplicationInformation = async () => {
      try {
        setLoadingApplicationInformation(true);

        if (selectedApplication) {
          setIsApplicationAdmin(undefined);
          setIsPortalAdmin(undefined);

          await ConfigApi.getAppInformation(
            selectedApplication.id,
            setApplicationInformation
          );

          await ConfigApi.getAppManagers(
            selectedApplication.id,
            setManagersList
          );

          await ConfigApi.getAppAndUserInformationRoles(
            selectedApplication.id,
            setApplicationRolesAndUserInformation
          );

          await ConfigApi.getAppAuthorizations(
            selectedApplication.id,
            setAppAuthorizations
          );

          await ConfigApi.getAppRoles(selectedApplication.id, setRolesList);
        }
      } catch {
        setWarningMessage("Erro ao obter dados do servidor ...");
      } finally {
        setLoadingApplicationInformation(false);
      }
    };

    fetchApplicationInformation();
  }, [selectedApplication]);

  useEffect(() => {
    const fetchAuthorizedUsers = async () => {
      try {
        setLoadingApplicationInformation(true);

        if (rolesList) {
          const authorizedUserRole = await rolesList.find(
            (role) => role.name === "Authorized user"
          );

          setCommonUserAuthorizationRole(authorizedUserRole);

          if (authorizedUserRole) {
            await ConfigApi.listUsersByRole(
              authorizedUserRole.id,
              setAuthorizedUsers
            );
          }
        }
      } catch {
        setWarningMessage("Erro ao obter dados do servidor ...");
      } finally {
        setLoadingApplicationInformation(false);
      }
    };

    fetchAuthorizedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesList]);

  useEffect(() => {
    if (authorizedUsersListQuery === "") {
      setAuthorizedUsersQueriedResult(
        authorizedUsers.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      );
    } else {
      const authorizedUsersResult = authorizedUsersQueriedResult.filter(
        (user) =>
          user.name
            .toLowerCase()
            .includes(authorizedUsersListQuery.toLowerCase()) ||
          user.petrobrasKey
            .toLowerCase()
            .includes(authorizedUsersListQuery.toLowerCase())
      );
      setAuthorizedUsersQueriedResult(authorizedUsersResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizedUsersListQuery, authorizedUsers]);

  const detachManager = async (userKey) => {
    try {
      setLoadingApplicationInformation(true);

      await ConfigApi.removeManager(selectedApplication.id, userKey);

      await ConfigApi.getAppManagers(selectedApplication.id, setManagersList);
    } catch {
      setWarningMessage("Erro ao obter dados do servidor ...");
      setLoadingApplicationInformation(false);
    } finally {
      setLoadingApplicationInformation(false);
    }
  };

  let applicationsListDisplay = [];
  if (protectedApplications.length > 0) {
    const map = {};
    for (const pa of protectedApplications) {
      map[pa.id] = pa;
    }
    for (const app of appsListQueriedResult) {
      if (Object.keys(map).findIndex((key) => app.id.includes(key)) === -1) {
        applicationsListDisplay.push({ ...app, protected: false });
      } else {
        applicationsListDisplay.push({ ...app, protected: true });
      }
    }
  } else {
    applicationsListDisplay = appsListQueriedResult;
  }

  return (
    <ApplicationManagement
      loadingList={loadingApplicationList}
      loadingApplicationInformation={loadingApplicationInformation}
      applicationsList={applicationsListDisplay}
      managersList={managersList}
      applicationInformation={applicationInformation}
      selectedApplication={selectedApplication}
      setSelectedApplication={onChangeApplication}
      detachManager={detachManager}
      onUpdateQuery={onUpdateQuery}
      showAddManagerModal={showAddManagerModal}
      onShowAddManagerModal={onShowAddManagerModal}
      onAttachManager={onAttachManager}
      onSelectedManager={setSelectedManager}
      selectedManager={selectedManager}
      warningMessage={warningMessage}
      onAcceptWarning={setWarningMessage}
      appAuthorizations={appAuthorizations}
      onChangeAuthorizationAccess={onChangeAuthorizationAccess}
      showAddUserAuthorizationModal={showAddUserAuthorizationModal}
      onShowAddUserAuthorizationModal={onShowAddUserAuthorizationModal}
      authorizedUsers={authorizedUsersQueriedResult}
      commonUserAuthorizationRole={commonUserAuthorizationRole}
      onUpdateAuthorizedUsersListQuery={onUpdateAuthorizedUsersListQuery}
      updateAuthorizedUsersList={updateAuthorizedUsersList}
      isAdmin={isApplicationAdmin || isPortalAdmin}
    />
  );
}

export default ApplicationContainer;
