import React, { useState, useEffect } from "react";

import AttachApplicationModal from "./attachApplicationModal.component";
import ConfigApi from "../../../../config/SquidAuth";

function AttachApplicationModalContainer({
  applicationInformation,
  onClose,
  updateAuthorizedAppsList,
  authorizedAppsList,
}) {
  const [loadingApplicationList, setLoadingApplicationList] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [applicationsList, setApplicationsList] = useState([]);
  const [appsListQueriedResult, setAppsListQueriedResult] = useState([]);
  const [appListQuery, setAppListQuery] = useState("");

  const [showSucessModal, setShowSucessModal] = useState(false);

  const [selectedApplication, setSelectedApplication] = useState(undefined);

  const [error, setError] = useState(undefined);

  const onUpdateAppQuery = (v) => {
    setAppListQuery(v.value);
  };

  const handleError = () => {
    setError(undefined);
  };

  const confirmAttach = async () => {
    try {
      setLoadingSubmit(true);

      if (!selectedApplication?.id) {
        setError({
          message: "Primeiramente selecione uma aplicação para autorizar.",
        });
      } else {
        await ConfigApi.authorizeApp(
          applicationInformation.id,
          selectedApplication.id,
          setError
        );

        setShowSucessModal(true);

        updateAuthorizedAppsList();
      }
    } catch (err) {
      setLoadingSubmit(false);
    } finally {
      setLoadingSubmit(false);
      setSelectedApplication(undefined);
    }
  };

  useEffect(() => {
    const fetchApplicationList = async () => {
      try {
        setLoadingApplicationList(true);
        await ConfigApi.listApplications(setApplicationsList);
      } catch {
        setLoadingApplicationList(false);
      } finally {
        setLoadingApplicationList(false);
      }
    };

    fetchApplicationList();
  }, []);

  useEffect(() => {
    if (appListQuery === "") {
      setLoadingApplicationList(true);

      const listWithoutSelectedApplication = applicationsList.filter(
        (app) =>
          app.name.toLowerCase() !== applicationInformation.name.toLowerCase()
      );

      const listWithoutAuthorizedApplications =
        listWithoutSelectedApplication.filter(
          (app) =>
            !authorizedAppsList.some(
              (el) => el.name.toLowerCase() === app.name.toLowerCase()
            )
        );

      setAppsListQueriedResult(
        listWithoutAuthorizedApplications.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      );

      setLoadingApplicationList(false);
    } else {
      const appsListQueriedResult = applicationsList.filter((manager) =>
        manager.name.toLowerCase().includes(appListQuery.toLowerCase())
      );
      setAppsListQueriedResult(appsListQueriedResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appListQuery, applicationsList, authorizedAppsList]);

  return (
    <AttachApplicationModal
      applicationsList={appsListQueriedResult}
      onUpdateQuery={onUpdateAppQuery}
      onSelectApplication={setSelectedApplication}
      loading={loadingApplicationList}
      loadingSubmit={loadingSubmit}
      selectedApplication={selectedApplication}
      confirmAttach={confirmAttach}
      error={error}
      handleError={handleError}
      onClose={onClose}
      showSucessModal={showSucessModal}
      closeSucessModal={setShowSucessModal}
    />
  );
}

export default AttachApplicationModalContainer;
