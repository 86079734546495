import React from "react";
import PropTypes from "prop-types";

import {
  Table,
  Container,
  Spacer,
  Panel,
  Text,
  Icon,
} from "squid-ui-kit";

class ApplicationsPresentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onSelectApplication = (record, index) => {
      if (record && this.props.onSelectApplication) {
        this.props.onSelectApplication(record);
      }
    }

    this.onSelectLocalApp = (record, index) => {
      if (record && this.props.onEditLocal) {
        this.props.onEditLocal(record);
      }
    };
  }

  
  render() {
    let content;
    // Sort apps alphabetically by name
    this.props.applications.sort((a,b)=>{
      return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
    });

    let applications;
    if (this.props.columns && this.props.columns.length > 0) {
      applications = (
        <Container height="100%" width="100%" bodyType="neutral" >
            <Container height="25px" width="100%" bodyType="active" direction="horizontal" verticalAlign="center" padding="10px">
              <Text bold> APLICAÇÕES </Text>
              <Spacer/>
              {this.props.isAdmin && 
              <Container bodyType="neutral" direction="horizontal" verticalAlign="center" onClick={this.props.onAdd}>
                <Icon icon="add"/>
                <Text bold> NOVA </Text>
              </Container>
              }
            </Container>
            <Table
              width="100%"
              height="100%"
              dataSource={this.props.applications}
              columns={this.props.columns}
              // rowHeight="32px"
              onAdd={this.props.onAdd}
              onSelect={this.props.isAdmin ? this.onSelectApplication : undefined}
              hoverStyle="active"
            />
        </Container>
      );
    } else {
      applications = (
        <Panel
          label="Aplicações"
          width="100%"
          height={"100%"}
          direction="vertical"
          verticalAlign="top"
          bodyType="primary"
        ></Panel>
      );
    }

    if (window.NSW_ENV && window.NSW_ENV.indexOf("dev") > -1) {
      content = (
        <Container
          width="100%"
          height="100%"
          bodyType="neutral"
          direction="horizontal"
        >
          <Container flex="3" height="100%" bodyType="neutral">
            {applications}
          </Container>
          <Spacer size="18px"/>
          <Container flex="2" height="100%" bodyType="neutral">
            <Container height="25px" width="100%" bodyType="active" direction="horizontal" verticalAlign="center" padding="10px">
              <Text bold> APLICAÇÕES LOCAIS </Text>
              <Spacer/>
              <Container bodyType="neutral" direction="horizontal" verticalAlign="center" onClick={this.props.onAddLocal}>
                <Icon icon="add"/>
                <Text bold> NOVA </Text>
              </Container>
            </Container>
            <Table
              width="100%"
              height="100%"
              dataSource={this.props.localApps}
              columns={this.props.localColumns}
              // rowHeight="32px"
              onSelect={this.onSelectLocalApp}
              hoverStyle="active"
            />
          </Container>
        </Container>
      );
    } else {
      content = (
        <Container
          width="100%"
          height={"100%"}
          bodyType="neutral"
          direction="horizontal"
        >
          {applications}
        </Container>
      );
    }

    return content;
  }
}

ApplicationsPresentation.propTypes = {
  isAdmin: PropTypes.bool,
  applications: PropTypes.array,
  localApps: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  localColumns: PropTypes.arrayOf(PropTypes.object),
  onAdd: PropTypes.func,
  onAddLocal: PropTypes.func,
  onEditLocal: PropTypes.func,

  // Apps management
  onSelectApplication: PropTypes.func,
};

ApplicationsPresentation.defaultProps = {
  isAdmin: false,
  applications: [],
  columns: [],
};

export default ApplicationsPresentation;
