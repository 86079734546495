import React, { useState } from "react";
import { Container, Text, Provider, tokens as tks } from 'squid-ui-kit';

const NotFoundPresentation = () => {

    // Tokens
    const [tokens, setTokens] = useState(tks);
    let timeout = setInterval(() => {
        if (window.tokens) {
            clearInterval(timeout);
            setTokens(window.tokens);
        }
    }, 100);

    return (
        <Provider theme={tokens}>
            <Container width="100%" height="100vh" bodyType="neutral" verticalAlign="center" horizontalAlign="center">
                <Text invertedColor>Não encontrado!</Text>
            </Container>
        </Provider>
    );
}

export default NotFoundPresentation;
