import React, { useState, useEffect } from "react";

import RolesTable from "./rolesTable.component";
import ConfigApi from "../../../../config/SquidAuth";

function RolesTableContainer({
  rolesList,

  loading,

  onSelectRow,
  selectedRoleRow,

  applicationID,

  updateRolesList,

  isAdmin,
}) {
  const [loadingList, setLoadingList] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [rowToDelete, setRowToDelete] = useState(undefined);
  const [filteredRolesList, setFilteredRolesList] = useState([]);

  const selectRow = (item) => {
    if (selectedRow?.id === item.id) {
      onSelectRow(undefined);
      setSelectedRow(undefined);
    } else {
      setSelectedRow(item);
    }
  };

  const deleteRow = (id) => {
    if (rowToDelete) {
      setRowToDelete(undefined);
    } else {
      setRowToDelete(id);
    }
  };

  const confirmDeletion = async () => {
    try {
      setLoadingList(true);

      await ConfigApi.removeRole(applicationID, rowToDelete, setError);

      await updateRolesList();
    } catch (err) {
      setLoadingList(false);
    } finally {
      setRowToDelete(undefined);
      onSelectRow(undefined);
      setLoadingList(false);
    }
  };

  const handleError = () => {
    setError(undefined);
  };

  useEffect(() => {
    if (!rowToDelete && selectedRow) {
      onSelectRow(selectedRow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  useEffect(() => {
    setFilteredRolesList(rolesList.filter((role) => role.name !== "Manager"));
  }, [rolesList]);

  return (
    <RolesTable
      rolesList={filteredRolesList}
      loading={loading || loadingList}
      rowToDelete={rowToDelete}
      deleteCallback={deleteRow}
      confirmDeletion={confirmDeletion}
      onSelectRow={selectRow}
      selectedRoleRow={selectedRoleRow}
      error={error}
      handleError={handleError}
      isAdmin={isAdmin}
    />
  );
}

export default RolesTableContainer;
