import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ConfigApi from "../../config/SquidAuth";
import AppButtonPresentation from "./AppButton.presentation";

const AppButtonContainer = (props) => {
  const [loading, setLoading] = useState(false);
  const [applicationId, setApplicationId] = useState("");
  const [appAuthorizations, setAppAuthorizations] = useState(undefined);
  const [appManagers, setAppManagers] = useState(undefined);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const handleErrorModal = () => {
    setIsErrorModalOpen(!isErrorModalOpen);
  };

  const onClick = async (e) => {
    if (props.onClick) {
      if (!loading && props.index.length === 36) {
        setApplicationId(props.index);
        try {
          setLoading(true);
          await ConfigApi.getAppAuthorizations(
            props.index,
            setAppAuthorizations
          );
        } catch {
          setIsErrorModalOpen(true);
        } finally {
          setLoading(false);
        }
      } else {
        props.onClick(props.index);
      }
    }
  };

  useEffect(() => {
    if (appAuthorizations) {
      if (!appAuthorizations.loggedUserAuthorized) {
        ConfigApi.getAppManagers(props.index, setAppManagers);
      } else {
        props.onClick(props.index);
      }
    }
  }, [appAuthorizations, props]);

  useEffect(() => {
    if (appAuthorizations && appManagers) {
      if (!appAuthorizations.loggedUserAuthorized) {
        props.handleAuthorizationModal(true, applicationId, appManagers, props.label);
      }
    }
  }, [appAuthorizations, appManagers, applicationId, props]);

  return (
    <AppButtonPresentation
      tokens={props.tokens}
      index={props.index}
      label={props.label}
      icon={props.icon}
      active={props.active}
      onClick={onClick}
      handleAuthorizationModal={props.handleAuthorizationModal}
      handleErrorModal={handleErrorModal}
      loading={loading}
      isErrorModalOpen={isErrorModalOpen}
    />
  );
};

AppButtonContainer.propTypes = {
  index: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.any,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  handleAuthorizationModal: PropTypes.func,
};

AppButtonContainer.defaultProps = {
  index: "",
  label: "AppButton",
  icon: null,
  active: false,
};

export default AppButtonContainer;
