import React, { useState, useEffect } from "react";

import ApplicationRoles from "./ApplicationRoles.presentation";
import ConfigApi from "../../config/SquidAuth";
import subwebCoreApi from "../../config/SubwebCoreApi";

function ApplicationRolesContainer({ isAdmin }) {
  const [loadingApplicationList, setLoadingApplicationList] = useState(false);
  const [loadingApplicationInformation, setLoadingApplicationInformation] =
    useState(false);
  const [loadingRoleTable, setLoadingRoleTable] = useState(false);
  const [loadingAuthorizedAppsList, setLoadingAuthorizedAppsList] =
    useState(false);
  const [loadingUsersByRoleTable, setLoadingUsersByRoleTable] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);

  const [warningMessage, setWarningMessage] = useState(undefined);

  const [selectedApplication, setSelectedApplication] = useState(undefined);
  const [selectedRoleRow, setSelectedRoleRow] = useState(undefined);
  const [
    applicationRolesAndUserInformation,
    setApplicationRolesAndUserInformation,
  ] = useState({});
  const [selectedApplicationChangelog, setSelectedApplicationChangelog] =
    useState(undefined);
  
  const [applicationInformation, setApplicationInformation] = useState({});
  const [applicationsList, setApplicationsList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [authorizedAppsList, setAuthorizedAppsList] = useState([]);
  const [usersByRolesList, setUsersByRolesList] = useState([]);
  const [managersList, setManagersList] = useState([]);

  const [appsListQueriedResult, setAppsListQueriedResult] = useState([]);
  const [rolesListQueriedResult, setRolesListQueriedResult] = useState([]);
  const [userRolesListQueriedResult, setUserRolesListQueriedResult] = useState(
    []
  );

  const [appListQuery, setAppListQuery] = useState("");
  const [rolesListQuery, setRolesListQuery] = useState("");

  const [showAddRolesModal, setShowAddRolesModal] = useState(false);
  const [showAttachApplicationModal, setShowAttachApplicationModal] =
    useState(false);

  const [isPortalAdmin, setIsPortalAdmin] = useState(false);
  const [isApplicationAdmin, setIsApplicationAdmin] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("general-data");

  const onUpdateAppQuery = (v) => {
    setAppListQuery(v.value);
  };

  const onUpdateRolesQuery = (v) => {
    setRolesListQuery(v.value);
  };

  const onShowAddRolesModal = () => {
    if (selectedApplication) {
      setShowAddRolesModal(!showAddRolesModal);
    }
  };

  const onShowAttachApplicationModal = () => {
    if (selectedApplication) {
      setShowAttachApplicationModal(!showAttachApplicationModal);
    }
  };

  const onCreateRole = async (name, description, sucess, fail) => {
    try {
      setLoadingRequest(true);

      const payload = {
        name: name,
        description: description,
      };

      await ConfigApi.createApplicationRole(
        selectedApplication.id,
        payload,
        sucess
      );

      await ConfigApi.getAppRoles(selectedApplication.id, setRolesList);
    } catch (error) {
      setLoadingRequest(false);
      fail(error);
    } finally {
      setLoadingRequest(false);
    }
  };

  const updateRolesList = async () => {
    try {
      setLoadingRequest(true);

      await ConfigApi.getAppRoles(selectedApplication.id, setRolesList);
    } catch (error) {
      setWarningMessage("Erro ao obter dados do servidor ...");
      setLoadingRequest(false);
      setLoadingUsersByRoleTable(false);
    } finally {
      setLoadingRequest(false);
    }
  };

  const updateAuthorizedAppsList = async () => {
    try {
      setLoadingRequest(true);

      await ConfigApi.getAuthorizedAppsList(
        selectedApplication.id,
        setAuthorizedAppsList
      );
    } catch (error) {
      setWarningMessage("Erro ao obter dados do servidor ...");
      setLoadingRequest(false);
    } finally {
      setLoadingRequest(false);
    }
  };

  const updateUserByRolesList = async () => {
    try {
      setLoadingRequest(true);

      if (selectedRoleRow) {
        if (!managersList.length) {
          await ConfigApi.getAppManagers(
            selectedApplication.id,
            setManagersList
          );
        }

        await ConfigApi.listUsersByRole(
          selectedRoleRow.id,
          setUsersByRolesList
        );
      }
    } catch (error) {
      setWarningMessage("Erro ao obter dados do servidor ...");
      setLoadingRequest(false);
    } finally {
      setLoadingRequest(false);
    }
  };

  const onSelectCategory = (cat) => {
    setSelectedCategory(cat);
  };

  useEffect(() => {
    if (appListQuery === "") {
      setAppsListQueriedResult(
        applicationsList.sort(function (a, b) {
          return a.name.localeCompare(b.name, "en", { sensitivity: "base" });
        })
      );
    } else {
      const appsListQueriedResult = applicationsList.filter((manager) =>
        manager.name.toLowerCase().includes(appListQuery.toLowerCase())
      );
      setAppsListQueriedResult(appsListQueriedResult);
    }
  }, [appListQuery, applicationsList]);

  useEffect(() => {
    const rolesFilteredResult = rolesList.filter(
      (role) => role.caName !== "Manager" && role.name !== "Authorized user"
    );

    setRolesListQueriedResult(
      rolesFilteredResult.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
    );
  }, [rolesList]);

  useEffect(() => {
    if (rolesListQuery === "") {
      setUserRolesListQueriedResult(
        usersByRolesList.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      );
    } else {
      const rolesFilteredResult = userRolesListQueriedResult.filter(
        (user) =>
          user.name.toLowerCase().includes(rolesListQuery.toLowerCase()) ||
          user.petrobrasKey.toLowerCase().includes(rolesListQuery.toLowerCase())
      );
      setUserRolesListQueriedResult(rolesFilteredResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesListQuery, usersByRolesList]);

  useEffect(() => {
    const fetchApplicationList = async () => {
      try {
        setLoadingApplicationList(true);
        await ConfigApi.listApplicationsManaged(setApplicationsList);
      } catch {
        setWarningMessage("Erro ao obter dados do servidor ...");
        setLoadingApplicationList(false);
      } finally {
        setLoadingApplicationList(false);
      }
    };

    fetchApplicationList();
  }, []);

  useEffect(() => {
    const fetchApplicationInformation = async () => {
      try {
        setLoadingApplicationInformation(true);
        setLoadingRoleTable(true);
        setLoadingAuthorizedAppsList(true);
        setApplicationRolesAndUserInformation(undefined);
        setSelectedApplicationChangelog(undefined);

        if (selectedApplication) {
          subwebCoreApi.getApplicationVersions(
            selectedApplication.id,
            setSelectedApplicationChangelog
          );
          await ConfigApi.getAppInformation(
            selectedApplication.id,
            setApplicationInformation
          );
          await ConfigApi.getAppRoles(selectedApplication.id, setRolesList);
          await ConfigApi.getAuthorizedAppsList(
            selectedApplication.id,
            setAuthorizedAppsList
          );
          await ConfigApi.getAppAndUserInformationRoles(
            selectedApplication.id,
            setApplicationRolesAndUserInformation
          );
        }
      } catch {
        setWarningMessage("Erro ao obter dados do servidor ...");
        setLoadingApplicationInformation(false);
        setLoadingRoleTable(false);
        setLoadingAuthorizedAppsList(false);
      } finally {
        setLoadingApplicationInformation(false);
        setLoadingRoleTable(false);
        setLoadingAuthorizedAppsList(false);
      }
    };

    fetchApplicationInformation();
  }, [selectedApplication]);

  useEffect(() => {
    const fetchUsersByRole = async () => {
      try {
        setLoadingApplicationInformation(true);
        setLoadingUsersByRoleTable(true);

        if (selectedRoleRow) {
          if (!managersList.length) {
            await ConfigApi.getAppManagers(
              selectedApplication.id,
              setManagersList
            );
          }
          await ConfigApi.listUsersByRole(
            selectedRoleRow.id,
            setUsersByRolesList
          );
        }
      } catch {
        setWarningMessage("Erro ao obter dados do servidor ...");
        setLoadingApplicationInformation(false);
        setLoadingUsersByRoleTable(false);
      } finally {
        setLoadingApplicationInformation(false);
        setLoadingUsersByRoleTable(false);
      }
    };

    fetchUsersByRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoleRow]);

  // User profile
  useEffect(() => {
    setIsPortalAdmin(false);
    setIsApplicationAdmin(false);
    if (selectedApplication && applicationRolesAndUserInformation) {
      // Portal admin
      const { userIsPortalAdmin } = applicationRolesAndUserInformation;
      setIsPortalAdmin(userIsPortalAdmin || false);
      // Application manager
      const managerRoleId = "ns1/" + selectedApplication.id + "/manager";
      const managerRolesOfApplication =
        applicationRolesAndUserInformation.appRoles.find(
          (role) => role.id === managerRoleId
        );
      if (managerRolesOfApplication) {
        const roleFound = applicationRolesAndUserInformation.userRoles.filter(
          (role) => role.id === managerRolesOfApplication.id
        );
        setIsApplicationAdmin(roleFound.length === 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationRolesAndUserInformation]);

  return (
    <ApplicationRoles
      loadingList={loadingApplicationList}
      loadingApplicationInformation={loadingApplicationInformation}
      loadingRoleTable={loadingRoleTable}
      loadingUsersByRoleTable={loadingUsersByRoleTable}
      loadingAuthorizedAppsList={loadingAuthorizedAppsList}
      loadingRequest={loadingRequest}
      updateRolesList={updateRolesList}
      updateAuthorizedAppsList={updateAuthorizedAppsList}
      updateUserByRolesList={updateUserByRolesList}
      applicationsList={appsListQueriedResult}
      rolesList={rolesListQueriedResult}
      authorizedAppsList={authorizedAppsList}
      usersByRoleList={userRolesListQueriedResult}
      applicationInformation={applicationInformation}
      selectedApplication={selectedApplication}
      setSelectedApplication={setSelectedApplication}
      selectedApplicationChangelog={selectedApplicationChangelog}
      onUpdateRolesQuery={onUpdateRolesQuery}
      onUpdateAppQuery={onUpdateAppQuery}
      showAddRolesModal={showAddRolesModal}
      onShowAddRolesModal={onShowAddRolesModal}
      showAttachApplicationModal={showAttachApplicationModal}
      onShowAttachApplicationModal={onShowAttachApplicationModal}
      onCreateRole={onCreateRole}
      selectedRoleRow={selectedRoleRow}
      setSelectedRoleRow={setSelectedRoleRow}
      warningMessage={warningMessage}
      onAcceptWarning={setWarningMessage}
      onSelectCategory={onSelectCategory}
      selectedCategory={selectedCategory}
      isAdmin={isApplicationAdmin || isPortalAdmin}
    />
  );
}

export default ApplicationRolesContainer;
