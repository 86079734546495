import React from "react";
import { Container, TextField, Panel, ListItem, Title } from "squid-ui-kit";

const ApplicationList = ({
  applicationsList,
  onUpdateQuery,
  onSelectApplication,
  loading,
  loadingApplicationInformation,
  selectedApplication,
  panelLabel,
}) => {
  const currentIcon = (applicationId, isLocked) => {
    if (selectedApplication?.id === applicationId) {
      return "chevron-right";
    } else if (isLocked) {
      return "locked";
    } else {
      return undefined;
    }
  };

  return (
    <Panel
      width="300px"
      height="100%"
      label={panelLabel}
      bodyType="primary"
      scroll="vertical"
    >
      <Container
        width="100%"
        bodyType="default"
        verticalAlign="center"
        padding="5px"
      >
        <TextField
          name="portal-settings-app-list"
          width="100%"
          placeholder="Busque aqui por aplicação..."
          invertedColors
          searchButton
          size="small"
          onChange={onUpdateQuery}
        />
      </Container>
      <Container
        width="100%"
        height="auto"
        bodyType="neutral"
        verticalAlign="top"
        padding="5px"
      >
        {applicationsList.length === 0 ? (
          <Container
            width="100%"
            height="24px"
            bodyType="content"
            verticalAlign="center"
            horizontalAlign="center"
          >
            {loading ? (
              <ListItem
                invertedColors
                size="small"
                title="Carregando..."
                disabled
              />
            ) : (
              <Title level={5} invertedColors>
                Não há aplicações
              </Title>
            )}
          </Container>
        ) : (
          <Container
            width="100%"
            bodyType="neutral"
            height="100%"
            verticalAlign="top"
            padding="0px"
            scroll="vertical"
          >
            {loading && (
              <ListItem
                invertedColors
                size="small"
                title="Carregando..."
                disabled
              />
            )}
            {!loading &&
              applicationsList.map((application) => (
                <ListItem
                  key={application.id}
                  title={application.name}
                  icon={currentIcon(
                    application.id,
                    application?.protected === true
                  )}
                  onClick={() => onSelectApplication(application)}
                  selected={selectedApplication?.id === application.id}
                  disabled={loadingApplicationInformation}
                  size="small"
                  invertedColors
                />
              ))}
          </Container>
        )}
      </Container>
    </Panel>
  );
};

export default ApplicationList;
