import React from "react";
import PropTypes from "prop-types";
import { Container } from "squid-ui-kit";
import Tab from "./components/Tab";

class Tabs extends React.Component {
  getTabContent = () => {
    if (!this.props.renderTabsSimultaneously) {
      return (
        this.props.tabs.find(
          (tab) => tab[this.props.keyIndex] === this.props.selectedTab
        )?.render ?? null
      );
    }

    return this.props.tabs.map((tab) => {
      return (
        <div
          key={`${tab[this.props.keyIndex]}-tab-render`}
          style={{
            display:
              tab[this.props.keyIndex] === this.props.selectedTab
                ? "flex"
                : "none",
            minWidth: "0",
            minHeight: "0",
            flexDirection: "column",
            flex: "1",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          {tab.render}
        </div>
      );
    });
  };

  render() {
    return (
      <div
        style={{
          width: this.props.width,
          height: this.props.height,
          flex: this.props.flex,
          display: "flex",
          flexDirection: "column",
          minHeight: "0",
          minWidth: "0",
          boxSizing: "border-box",
        }}
      >
        <Container width="100%" direction="horizontal" verticalAlign="center">
          {this.props.tabs?.map((tab) => {
            return (
              <Tab
                key={`${tab[this.props.keyIndex]}-tab`}
                selected={tab[this.props.keyIndex] === this.props.selectedTab}
                onClick={(e) =>
                  this.props.onSelectTab({
                    ...e,
                    tab: tab[this.props.keyIndex],
                  })
                }
                flex="1"
              >
                {tab?.label}
              </Tab>
            );
          })}
        </Container>
        <Container width="100%" flex="1" bodyType="neutral">
          {this.getTabContent()}
        </Container>
      </div>
    );
  }
}

Tabs.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  flex: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.object),
  renderTabsSimultaneously: PropTypes.bool,
  selectedTab: PropTypes.string,
  keyIndex: PropTypes.string,
  onSelectTab: PropTypes.func,
};

Tabs.defaultProps = {
  keyIndex: "key",
};

export default Tabs;
