import React from 'react';
import {
  Container,
  IconButton,
  Table,
  TableCell,
  Icon,
  Text,
  tokens,
} from "squid-ui-kit";

import { ExclusionAlert } from '../../../../components'

const getColor = (record, selectedItem) => {
    if (record.id === selectedItem?.id) {
      return '#ffffff';
    }
    return '#000000';
};

const getBackgroundColor = (record, selectedItem) => {
    if(record?.id === undefined) {
      if (record === selectedItem?.id) {
        return '#136673';
      }
      return '#ffffff';
    }

    if (record.id === selectedItem?.id) {
      return '#136673';
    }
    return '#ffffff';
};

const mountCellRenderer = (selectedItem) => {
  const cellRenderer = (content, record) => (
    <TableCell
      padding="0px"
      cellShowBorderBottom
      showBorderBottom
    >
      <div 
        style={{ 
          height: '100%',
          width: '100%',
          color: getColor(record, selectedItem),
          backgroundColor: getBackgroundColor(record, selectedItem),
          // filter: dropShadow(rgba(0, 0, 0, 0.2) 0rem 0.125rem 0.125rem)
          lineHeight: '19px',
          verticalAlign: 'middle',
          display: 'flex',
          padding: '0px 2px',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {content}
      </div>
    </TableCell>
  );
  return cellRenderer;
};

const RolesTable = (
  { 
    rolesList, 
    loading, 
    deleteCallback, 
    onSelectRow, 
    selectedRoleRow, 
    isAdmin, 
    rowToDelete, 
    confirmDeletion,
    error,
    handleError
  }) => {

  const tableColumns = [
    {
      label: 'PAPEL',
      dataIndex: 'name',
      selected: true,
      flex: '1',
      cellRenderer: mountCellRenderer(selectedRoleRow),
    },
    {
      label: 'DESCRIÇÃO',
      dataIndex: 'description',
      flex: '2',
      selected: true,
      cellRenderer: mountCellRenderer(selectedRoleRow),
    },
    {
      label: 'AÇÕES',
      dataIndex: 'id',
      selected: true,
      width: '100px',
      cellRenderer: (item) =>
        <TableCell 
          invertedColors 
          verticalAlign="center" 
          horizontalAlign="center" 
          cellShowBorderBottom
          showBorderBottom
          padding="0px"
          >
          <div 
            style={{ 
              height: '100%',
              width: '100%',
              color: getColor(item, selectedRoleRow),
              backgroundColor: getBackgroundColor(item, selectedRoleRow),
              lineHeight: '19px',
              verticalAlign: 'middle',
              display: 'flex',
              padding: '0px 2px',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
          {selectedRoleRow?.id === item ? 
            <IconButton
              size="small"
              icon="delete"
              onClick={() => deleteCallback(item)}
            /> :
            <IconButton
              size="small"
              invertedColors
              icon="delete"
              onClick={() => deleteCallback(item)}
            />
          }
          </div>
        </TableCell>
    }
  ]

  if (loading) {
    return (
      <Container
        width="100%"
        height="100%"
        bodyType="primary"
        progressLabel="Carregando..."
        progress={-1}
      />
    )
  }

  if (!rolesList.length) {
    return (
      <Container verticalAlign="center" horizontalAlign="center" width="100%" height="100%" bodyType="content" direction="horizontal">
        <Icon icon="info" color={tokens.ColorPrimary}/>
        <Text invertedColors>Nenhum papel criado.</Text>
      </Container>
    )
  }

  return (
    <Container width="100%" height='100%' bodyType="primary">
      <Table
        invertedColors
        width="100%"
        height="100%"
        size="small"
        cellShowBorderBottom
        columns={isAdmin ? tableColumns : tableColumns.slice(0, tableColumns.length - 1)}
        dataSource={rolesList}
        onSelect={(item) => onSelectRow(item)}
      />

      {rowToDelete && (
        <ExclusionAlert 
          exclusionText=
            {`Tem certeza que você deseja remover este Papel?`}
          title='Alerta'
          onCancel={() => deleteCallback(undefined)}
          onConfirm={() => confirmDeletion()}
        />
      )}

      {error && (
        <ExclusionAlert 
          exclusionText=
            {error?.response?.data?.message || error?.message || 'Um erro inesperado ocorreu, entre em contato com o administrador'}
          title='Alerta'
          onCancel={() => handleError()}
          onlyConfirmation
        />
      )}
    </Container>
  )
};

export default RolesTable;
