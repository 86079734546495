import React from 'react';
import {
  Container,
  IconButton,
  Table,
  TableCell
} from "squid-ui-kit";

const cellRenderer = (item) => (
  <TableCell invertedColors verticalAlign="center" horizontalAlign="center" fontBodySize="small">
    {item}
  </TableCell>
);

const ManagersTable = ({ managersList, loading, deleteCallback, isAdmin }) => {

  const tableColumns = [
    {
      label: 'USUÁRIO',
      dataIndex: 'name',
      selected: false,
      cellRenderer,
    },
    {
      label: 'EMAIL',
      dataIndex: 'email',
      selected: false,
      cellRenderer,
    },
    {
      label: 'CHAVE',
      dataIndex: 'petrobrasKey',
      selected: false,
      cellRenderer,
    },
    {
      label: 'AÇÕES',
      dataIndex: 'petrobrasKey',
      selected: false,
      width: '100px',
      cellRenderer: (item) =>
        <TableCell invertedColors verticalAlign="center" horizontalAlign="center">
          <IconButton
            size="small"
            invertedColors
            icon="delete"
            onClick={() => deleteCallback(item)}
          />
        </TableCell>
    }
  ]

  if (loading) {
    return (
      <Container
        width="100%"
        height="100%"
        bodyType="neutral"
        progressLabel="Carregando..."
        progress={-1}
      />
    )
  }

  return (
    <Table
      invertedColors
      width="100%"
      height="100%"
      size="small"
      cellShowBorderBottom
      columns={isAdmin ? tableColumns : tableColumns.slice(0, tableColumns.length - 1)}
      dataSource={managersList}
    />

  )
};

export default ManagersTable;
