import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Container,
  IconButton,
  Spacer,
  TableCell,
  Text,
  Tooltip,
} from "squid-ui-kit";

import configApi from "../../../config/SquidAuth";
import { Avatar } from "../../../components";

const UserInfoCard = ({ petrobrasKey, addFn }) => {
  const [user, setUser] = useState(undefined);
  const [expand, setExpand] = useState(false);

  const loadUser = (pKey) =>
    configApi.findUserInfoByPetrobrasKey(pKey, setUser);
  const successCb = () => loadUser(petrobrasKey);
  const onAdd = () => addFn(petrobrasKey, successCb);

  useEffect(() => {
    loadUser(petrobrasKey);
  }, [petrobrasKey]);

  if (user) {
    const { caUserData, portalUserData } = user;
    let actionIcon;
    if (portalUserData === null) {
      actionIcon = (
        <Tooltip title="Adicionar ao portal" placement="left">
          <IconButton icon="add" invertedColors size="small" onClick={onAdd} />
        </Tooltip>
      );
    } else {
      actionIcon = (
        <Tooltip title="Usuário já adicionado ao portal" placement="left">
          <IconButton icon="user" invertedColors size="small" />
        </Tooltip>
      );
    }
    return (
      <TableCell
        invertedColors
        width="100%"
        bodyType="content"
        showBorderBottom
      >
        <Container
          width="100%"
          height="45px"
          direction="horizontal"
          verticalAlign="center"
          bodyType="content"
        >
          <Avatar src={caUserData.avatar} width="30px" height="30px" />
          <Spacer size="15px" />
          <Container
            flex="1"
            direction="vertical"
            bodyType="neutral"
            verticalAlign="center"
            onClick={() => setExpand(!expand)}
          >
            <Text invertedColors bold>
              {caUserData.name}
            </Text>
            {expand && (
              <>
                <Text invertedColors size="small">
                  Chave: {caUserData.petrobrasKey}
                </Text>
                <Text invertedColors size="small">
                  {caUserData.departmentAcronym}
                </Text>
              </>
            )}
          </Container>
          <Spacer size="15px" />
          {actionIcon}
        </Container>
      </TableCell>
    );
  }
  return (
    <TableCell invertedColors width="100%">
      <Text invertedColors size="small">
        Chave: {petrobrasKey}
      </Text>
    </TableCell>
  );
};

UserInfoCard.propTypes = {
  petrobrasKey: PropTypes.string,
  addFn: PropTypes.func,
};

export default UserInfoCard;
