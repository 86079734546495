import React from "react";
import PropTypes from "prop-types";

import { Container } from "squid-ui-kit";

import Tab from "../Tab";

class TabBarPresentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let tabs;
    if (this.props.tabs && this.props.tabs.length > 0) {
      tabs = this.props.tabs.map((tab, i) => {
        let icon;
        if (tab.icon) {
          if (typeof tab.icon === "string") {
            icon = (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage:
                    "radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.8))",
                  borderRadius: this.props.tokens.RadiusPrimary,
                }}
              >
                <img
                  alt=""
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                  src={tab.icon}
                />
              </div>
            );
          } else if (typeof tab.icon === "object") {
            icon = tab.icon;
          }
        }
        let active = false;
        if (this.props.tab === tab.index) {
          active = true;
        }
        return (
          <Tab
            key={i}
            tokens={this.props.tokens}
            index={tab.index}
            label={tab.label}
            icon={icon}
            compact={tab.compact}
            active={active}
            onClick={this.props.onChange}
            onClose={this.props.onTabClose}
          />
        );
      });
    }

    return (
      <Container
        // width="100%"
        flex="1"
        height="100%"
        direction="horizontal"
        bodyType="neutral"
        margin="0px"
        padding="0px 0px 0px 20px"
      >
        {tabs}
      </Container>
    );
  }
}

TabBarPresentation.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  tab: PropTypes.string,
  onChange: PropTypes.func,
  onTabClose: PropTypes.func,
};

TabBarPresentation.defaultProps = {
  tabs: [],
  tab: "",
};

export default TabBarPresentation;
