import React, { useState, useEffect, useCallback } from "react";

import AccessRequests from "./AccessRequests.component";
import subwebCoreApi from "../../../../config/SubwebCoreApi";

function AccessRequestsContainer({ applicationId }) {
  const [accessRequestsList, setAccessRequestsList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const processAuthorizationError = (err) => {
    let msg = "Erro ao processar a solicitação de autorização de acesso";
    if (err?.response?.data?.message?.message) {
      msg = err.response.data.message.message;
    }
    setErrorMessage(msg);
    setLoading(false);
  };

  const updateList = useCallback(() => {
    if (applicationId) {
      setLoading(true);
      subwebCoreApi.listApplicationAccessRequests(
        applicationId,
        (requests) => {
          setAccessRequestsList(requests);
          setLoading(false);
        },
        processAuthorizationError
      );
    }
  }, [applicationId]);

  const processAuthorization = (applicationId, petrobrasKey, grant) => {
    if (applicationId) {
      setErrorMessage(undefined);
      setLoading(true);
      subwebCoreApi.authorizeApplicationAccess(
        applicationId,
        petrobrasKey,
        grant,
        updateList,
        processAuthorizationError
      );
    }
  };

  const onGrant = (applicationId, petrobrasKey) => {
    processAuthorization(applicationId, petrobrasKey, true);
  };

  const onRevoke = (applicationId, petrobrasKey) => {
    processAuthorization(applicationId, petrobrasKey, false);
  };

  const onRemove = (accessRequestId) => {
    if (accessRequestId) {
      setErrorMessage(undefined);
      setLoading(true);
      subwebCoreApi.deleteAccessRequest(
        accessRequestId,
        updateList,
        processAuthorizationError
      );
    }
  };

  // Filter pending and revoked requests
  let filteredAccessRequests = accessRequestsList.filter(
    (req) => req.status === "PENDING" || req.status === "REVOKED"
  );
  // Include requester name
  filteredAccessRequests = filteredAccessRequests.map((req) => {
    let requesterName = req.petrobrasKey;
    if (req?.requester) {
      requesterName = req.requester.name;
    }
    return { ...req, requesterName };
  });

  useEffect(() => {
    updateList();
  }, [applicationId, updateList]);

  return (
    <AccessRequests
      key="access-request-component"
      loading={loading}
      applicationId={applicationId}
      accessRequests={filteredAccessRequests}
      onGrant={onGrant}
      onRevoke={onRevoke}
      onRemove={onRemove}
      errorMessage={errorMessage}
    />
  );
}

export default AccessRequestsContainer;
