// SQUID BASE API
let url = `https://squid.${window.NSW_DOMAIN}`;
if (window.NSW_ENV === "local") {
  url = `https://squid-dev.${window.NSW_DOMAIN}`;
}
if (window.NSW_ENV === "dev") {
  url = `https://squid-dev.${window.NSW_DOMAIN}`;
}
if (window.NSW_ENV === "tst") {
  url = `https://squid-tst.${window.NSW_DOMAIN}`;
}
if (window.NSW_ENV === "hmg") {
  url = `https://squid-hmg.${window.NSW_DOMAIN}`;
}
if (window.NSW_ENV === "prd" || window.NSW_ENV === "prod") {
  url = `https://squid.${window.NSW_DOMAIN}`;
}
export const squidUrl = url;

// SUBWEB BASE API
let subUrl = 'https://dev.subweb.com.br';
if (window.NSW_ENV === "local") {
  //subUrl = `http://localhost:4010`;
  subUrl = 'https://dev.subweb.com.br';
}
if (window.NSW_ENV === "dev") {
  subUrl = `https://dev.subweb.com.br`;
}
if (window.NSW_ENV === "tst") {
  subUrl = `https://subweb-tst.petrobras.com.br`;
}
if (window.NSW_ENV === "hmg") {
  subUrl = `https://subweb-hmg.petrobras.com.br`;
}
if (window.NSW_ENV === "prd" || window.NSW_ENV === "prod") {
  subUrl = `https://subweb.${window.NSW_DOMAIN}`;
}
export const subBaseUrl = subUrl;
